import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAppUtils, {
  useAllUserProfileByIdGetAction,
  usePermission,
  useUserProductFetch,
  useUserSelectedList,
  useWarehouseList,
} from "../../utils/useAppUtils";
import { API_BASE_URL_USER, UPLOAD_IMAGES } from "../../utils/https";
import { Modal, Button as BootstrapButton, Table } from "react-bootstrap";
import { Pagination } from "../Global/Table2";
import userImage from "../../Assets/profile.png";
import { useSelector } from "react-redux";
import {
  ProfileImage,
  RoleSelector,
  TableCell,
  TableWrapper,
} from "../warehouse/Product";
import { TableHeader, TableRow } from "../Pages/UniteProfile";
import Card from "./Card";
import GlobalTable, { NoDataWrapper } from "../Global/GlobalTable";
import {
  AssignNowButton,
  BlackBorderButton,
  BlueBorderButton,
} from "../Global/GlobalButtons";
import { toast } from "react-toastify";
import { NoDataIcon } from "../group/GroupUserList";
import {
  assignProductAction,
  assignProductByIdAction,
  clearUserData,
} from "../../redux/users/action";
import GlobalSearch from "../Global/GlobalSearch";
import axios from "axios";
import { useLocation } from "react-router-dom";

const columns = [
  { header: "Select", accessor: "select" },
  { header: "SL No ", accessor: "slno" },
  { header: "Date & Time", accessor: "create_date" },
  { header: "Image", accessor: "image" },
  { header: "Equipment Name", accessor: "name" },
  { header: "Equipment Id", accessor: "product_id" },
  { header: "Assigned To", accessor: "username" },
  { header: "Category ", accessor: "category_name" },
  { header: "Equipment Reconciliation", accessor: "equipmentReconciliation" },
  { header: "Equipment Assign", accessor: "equipmentAssign" },
];
const AllUserProfile = () => {
  const { dispatch } = useAppUtils();
  const users = useSelector((state) => state?.users?.user);
  const warehouse = useWarehouseList();
  const [productAssignData, setProductAssignData] = useState([]);
  const userData = useAllUserProfileByIdGetAction();
  const [showRecon, setShowRecon] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const userProductFetch = useUserProductFetch();
  const [currentPageProduct, setCurrentPageProduct] = useState(1);
  const itemsPerPageProduct = 5;
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const usersSelect = useUserSelectedList();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const itemsPerPageUser = 5;
  const [showAssign, setShowAssign] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Soldier");
  const [showReconciliation, setShowReconciliation] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedAssignRole, setSelectedAssignRole] = useState([]);
  const { navigate } = useAppUtils();
  const location = useLocation();
  const user = location.state;
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(warehouse.length / ITEMS_PER_PAGE);

  // Get current page data
  const currentData = warehouse.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserData());
    };
  }, [dispatch]);

  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };
  const filteredPaginatedUsersSelect = getPaginatedData(
    usersSelect.filter((item) => item.role === selectedRole),
    currentPageUser,
    itemsPerPageUser
  );
  const paginatedProductFetch = getPaginatedData(
    userProductFetch,
    currentPageProduct,
    itemsPerPageProduct
  );
  const handleShowAssign = () => {
    if (selectedProductIds.length === 0) {
      toast.warn("Please select a product first.");
      return;
    }
    setShowAssign(true);
  };
  const handleClose = () => {
    setShowReconciliation(false);
    setShowAssign(false);
    setShowTransfer(false);
  };
  const handleSelectChange = (rowIndex, itemType) => {
    if (itemType === "product") {
      const productId = userProductFetch[rowIndex].product_id;
      setSelectedProductIds((prev) => {
        const newSelection = prev.includes(productId)
          ? prev.filter((id) => id !== productId)
          : [...prev, productId];
        return newSelection;
      });
    } else if (itemType === "user") {
      if (
        filteredPaginatedUsersSelect &&
        rowIndex < filteredPaginatedUsersSelect.length
      ) {
        const user = filteredPaginatedUsersSelect[rowIndex];
        setSelectedUsers([{ user_id: user.user_id, username: user.username }]);
        setSelectedAssignRole([user.role]);
      } else {
        console.error(
          "Invalid user index or paginated users data not available"
        );
      }
    }
  };
  const handleAssignClick = () => {
    const assignedRole = selectedAssignRole;
    const productIds = selectedProductIds;
    const assignedTo = selectedUsers.map((user) => user.user_id);
    dispatch(
      assignProductAction({ productIds, assignedTo, assignedRole }, () => {
        // Clear selected values after API hit
        setSelectedProductIds([]);
        setSelectedUsers([]);
        setSelectedAssignRole([]);
      })
    );
  };
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };
  const handleShowReconciliation = async () => {
    try {
      setShowReconciliation(true);
      const response = await axios.get(
        `${API_BASE_URL_USER}/productassign/${selectedProductIds}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = Array.isArray(response.data.data) ? response.data.data : [];
      setProductAssignData(data);

      dispatch(
        assignProductByIdAction(data, (callbackResponse) => {
          if (callbackResponse.status === 200) {
            console.log("Reconciliation data dispatched successfully");
          } else {
            console.error("Failed to dispatch reconciliation data");
          }
        })
      );

      console.log("API response:", data); // Check the API response in console
    } catch (error) {
      console.error("Error fetching reconciliation data", error);
    }
  };
  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };
  const handleRequestClick = async () => {
    const productId = selectedProductIds;
    const requestTo = selectedUserIds;
    try {
      const response = await axios.post(
        `${API_BASE_URL_USER}/reconcile-product`,
        { productId, requestTo },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response?.status === 200) {
        toast.success(response.data.message);
        setSelectedProductIds([]);
        setShowRecon(false);
      }
    } catch (error) {
      console.error("Error fetching reconciliation data", error);
    }
  };
  const { permission: permissionsArray } = usePermission();

  const permissionIds = permissionsArray.map(
    (permission) => permission.permission_id
  );

  const handleRequestNow = async (
    productId,
    assignedById,
    assignedByRole,
    createdBy
  ) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL_USER}/request-product`,
        {
          product_id: productId,
          assigned_by_id: assignedById,
          assigned_by_role: assignedByRole,
          created_by: createdBy,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Check if the request was successful
      if (response.status === 200) {
        toast.success(
          response.data.message || "Request submitted successfully!"
        );
        // Optionally, you can close the modal or reset any fields here.
      } else {
        toast.error(response.data.message || "Failed to submit request.");
      }
    } catch (error) {
      console.error("Error submitting request:", error);
      toast.error("An error occurred while submitting the request.");
    }
  };

  return (
    <Container>
      <Card userData={userData} user={users} />
      <ProfileSection>
        <Form>
          <InputWrapper>
            <label>Email</label>
            <input
              type="email"
              value={userData?.email || user?.email || ""}
              placeholder="charlene@gmail.com"
              readOnly
            />
          </InputWrapper>
          <InputWrapper>
            <label>Mobile Number</label>
            <input
              type="number"
              value={userData?.mobile_number || ""}
              placeholder="cXXXXXXXXXX"
              readOnly
            />
          </InputWrapper>
          <InputWrapper>
            <label>Unit</label>
            <input
              type="text"
              value={userData?.unit_name || "New User Request"}
              placeholder="New User Request"
              readOnly
            />
          </InputWrapper>
          <InputWrapper>
            <label>Role</label>
            <input
              type="text"
              value={userData?.role || "User"}
              placeholder="User"
              readOnly
            />
          </InputWrapper>
          <InputWrapper fullwidth={true}>
            <label>List of Permission</label>
            <div>
              {userData?.permissions?.length > 0
                ? userData.permissions
                    .map((perm) => perm.permission_name)
                    .join(" | ")
                : "No Permissions"}
            </div>
          </InputWrapper>
        </Form>
      </ProfileSection>
      <EquipmentSection>
        <EquipmentTitle>Equipment List Assigned</EquipmentTitle>
        <EquipmentList>
          {userData?.products?.map((equipment) => (
            <div>
              <EquipmentItem
                key={equipment.product_id}
                src={`${UPLOAD_IMAGES}/${equipment.product_image}`}
                alt={equipment.name}
              />
              <TimelineItem key={`timeline-${equipment.product_id}`}>
                <Details>
                  <p>
                    {equipment.name}
                    <br />
                    <span>{equipment.details}</span>
                  </p>
                  <Time>
                    {new Date(equipment.product_assigntime).toLocaleString()}
                  </Time>
                </Details>
              </TimelineItem>
            </div>
          ))}
        </EquipmentList>
        <ButtonGroup>
          <Button id="2" onClick={() => setShowRecon(true)}>
            Reconcile Equipment
          </Button>
          <Button
            id={
              permissionIds?.length
                ? permissionIds.map((i) => (i ? 6 : 38)).join(",") // Produces a string of IDs
                : 37
            }
            onClick={() => setShowRequest(true)}
          >
            Request Equipment
          </Button>
          <Button id="2" onClick={() => setShowTransfer(true)}>
            Transfer Equipment
          </Button>
          {userData?.role === "admin" ? (
            <Button
              id="2"
              onClick={() => {
                navigate("/user_permission", { state: userData });
              }}
            >
              Add Permission
            </Button>
          ) : (
            ""
          )}
        </ButtonGroup>
        <EquipmentHistory>
          <h3>Equipment History</h3>
          <Timeline>
            {userData?.userproducthistory?.map((history) => (
              <TimelineItem key={history.history_id}>
                <Circle />
                <Details>
                  <p>
                    {history.name || "N/A"}
                    <br />
                    <span>{history.details || "N/A"}</span>
                  </p>
                  <Time>
                    {new Date(history.product_assigntime).toLocaleString() ||
                      "N/A"}
                  </Time>
                </Details>
              </TimelineItem>
            ))}
          </Timeline>
        </EquipmentHistory>
      </EquipmentSection>

      {/* Recon Modal */}
      <Modal
        show={showRecon}
        onHide={() => setShowRecon(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Equipment Reconcile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dash_table">
            <GlobalTable
              columns={columns.filter(
                (col) => col.accessor !== "equipmentAssign"
              )}
              data={
                paginatedProductFetch
                  ? paginatedProductFetch?.map((row, index) => ({
                      ...row,
                      slno: index + 1, // Adding SL No
                      create_date: new Date(row.create_date).toLocaleString(), //Format the date here

                      equipmentReconciliation: (
                        <BlueBorderButton
                          variant="primary"
                          onClick={() => {
                            handleShowReconciliation();
                          }}
                          id="37 || 32"
                        >
                          Request Now{row.equipmentReconciliation}
                        </BlueBorderButton>
                      ),

                      select: (
                        <input
                          type="checkbox"
                          checked={selectedProductIds.includes(row.product_id)} // Check if product ID is in the selectedProductIds array
                          onChange={() => handleSelectChange(index, "product")} // Handle checkbox change
                        />
                      ),
                    }))
                  : []
              }
              onSelectChange={(rowIndex) =>
                handleSelectChange(rowIndex, "product")
              }
            />
            <Pagination
              currentPage={currentPageProduct}
              totalItems={userProductFetch.length}
              itemsPerPage={itemsPerPageProduct}
              onPageChange={(page) => setCurrentPageProduct(page)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={() => setShowRecon(false)}
          >
            Close
          </BootstrapButton>
        </Modal.Footer>
      </Modal>

      {/* Request Modal */}
      <Modal show={showRequest} onHide={() => setShowRequest(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Equipment Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <TableRow>
                <TableHeader>Product Name</TableHeader>
                <TableHeader>Assigned by</TableHeader>
                <TableHeader>Request Now</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>Granade</TableCell>
                <TableCell>Avi</TableCell>
                <TableCell>
                  <Button>request now</Button>
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
          {/* <PaginationComponent
            totalItems={requestItems.length}
            activePage={activePage}
            setActivePage={setActivePage}
            itemsPerPage={itemsPerPage}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={() => setShowRequest(false)}
          >
            Close
          </BootstrapButton>
        </Modal.Footer>
      </Modal>

      {/* Transfer Modal */}
      <Modal
        show={showTransfer}
        onHide={() => setShowTransfer(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Equipment Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <div className="dash_table">
              <GlobalTable
                columns={columns.filter(
                  (col) => col.accessor !== "equipmentReconciliation"
                )}
                data={
                  paginatedProductFetch
                    ? paginatedProductFetch?.map((row, index) => ({
                        ...row,
                        slno: index + 1,
                        create_date: new Date(row.create_date).toLocaleString(),
                        location: (
                          <button className="location_btn">Inside</button>
                        ),
                        equipmentAssign: (
                          <BlueBorderButton
                            variant="primary"
                            onClick={handleShowAssign}
                            id="2 || 15"
                          >
                            Transfer Now{row.equipmentAssign}
                          </BlueBorderButton>
                        ),
                        select: (
                          <input
                            type="checkbox"
                            checked={selectedProductIds.includes(
                              row.product_id
                            )} // Check if product ID is in the selectedProductIds array
                            onChange={() =>
                              handleSelectChange(index, "product")
                            } // Handle checkbox change
                          />
                        ),
                      }))
                    : []
                }
                onSelectChange={(rowIndex) =>
                  handleSelectChange(rowIndex, "product")
                }
              />
            </div>
          </Table>
          <Pagination
            currentPage={currentPageProduct}
            totalItems={userProductFetch.length}
            itemsPerPage={itemsPerPageProduct}
            onPageChange={(page) => setCurrentPageProduct(page)}
          />
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={() => setShowTransfer(false)}
          >
            Close
          </BootstrapButton>
        </Modal.Footer>
      </Modal>

      {selectedProductIds && (
        <Modal show={showAssign} onHide={handleClose} size="xl" centered>
          <Modal.Header closeButton>
            <div>
              <GlobalSearch search="Search by Name,Id,Group,Unit" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <RoleSelector>
                {" "}
                <b>Select</b>
                {["Soldier", "Manager", "Commander", "Chief", "user"].map(
                  (role) => (
                    <BlackBorderButton
                      key={role}
                      className={selectedRole === role ? "active" : ""}
                      onClick={() => handleRoleSelect(role)}
                    >
                      {role}
                    </BlackBorderButton>
                  )
                )}
              </RoleSelector>
              {filteredPaginatedUsersSelect.length > 0 ? (
                <TableWrapper>
                  <Table>
                    <thead>
                      <TableRow>
                        <TableHeader></TableHeader>
                        <TableHeader>Profile Image</TableHeader>
                        <TableHeader>Username</TableHeader>
                        <TableHeader>Profile</TableHeader>
                        <TableHeader>ID</TableHeader>
                        <TableHeader>Group</TableHeader>
                        <TableHeader>Unit</TableHeader>
                        <TableHeader>Action</TableHeader>
                      </TableRow>
                    </thead>
                    <tbody>
                      {filteredPaginatedUsersSelect.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ minWidth: "60px" }}>
                            <input
                              type="checkbox"
                              checked={selectedUsers.some(
                                (selectedUser) =>
                                  selectedUser.user_id === item.user_id
                              )}
                              onChange={(event) =>
                                handleSelectChange(
                                  index,
                                  "user",
                                  event.target.checked
                                )
                              }
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: "60px" }}>
                            <ProfileImage
                              src={`${UPLOAD_IMAGES}/${
                                item.image || userImage
                              }`}
                              alt={item.username}
                            />
                          </TableCell>
                          <TableCell>{item.username}</TableCell>
                          <TableCell>{item.profile || "N/A"}</TableCell>
                          <TableCell>{item.user_id}</TableCell>
                          <TableCell>{item.group_name}</TableCell>
                          <TableCell>{item.unit_name || "N/A"}</TableCell>
                          <TableCell>
                            <AssignNowButton
                              onClick={() => {
                                handleAssignClick();
                                handleClose();
                              }}
                            >
                              {" "}
                              Send now
                            </AssignNowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              ) : (
                <NoDataWrapper>
                  <NoDataIcon />
                  <h3>No Data Available</h3>
                  <p>Please add or fetch data to display here.</p>
                </NoDataWrapper>
              )}

              <Pagination
                currentPage={currentPageUser}
                totalItems={
                  usersSelect.filter((item) => item.role === selectedRole)
                    .length
                }
                itemsPerPage={itemsPerPageUser}
                onPageChange={(page) => setCurrentPageUser(page)}
              />
            </Container>
          </Modal.Body>
        </Modal>
      )}
      <Modal show={showReconciliation} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Warehouse List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "60%" }}>
            <GlobalSearch search="Search by Name,Id,Group,Unit" />
          </div>
          <Container>
            <TableWrapper>
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader></TableHeader>
                    <TableHeader> Image</TableHeader>
                    <TableHeader>Warehouse Name</TableHeader>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Group</TableHeader>
                    <TableHeader>Unit</TableHeader>
                    <TableHeader>Action</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {currentData?.length > 0 ? (
                    currentData.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ minWidth: "40px" }}>
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </TableCell>
                        <TableCell>
                          <ProfileImage
                            src={`${UPLOAD_IMAGES}/${item.image || userImage}`}
                            alt={item.username}
                          />
                        </TableCell>
                        <TableCell> {item.username}</TableCell>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.groupid}</TableCell>
                        <TableCell> {item.unitid}</TableCell>
                        <TableCell>
                          {" "}
                          <AssignNowButton
                            onClick={() => {
                              handleRequestClick();
                              handleClose();
                            }}
                          >
                            Request now
                          </AssignNowButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No data available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TableWrapper>
            <div className="pagination">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  className={currentPage === index + 1 ? "active" : ""}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal show={showRequest} onHide={() => setShowRequest(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Equipment Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userData?.requestequipment?.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <TableRow>
                  <TableHeader>Product Name</TableHeader>
                  <TableHeader>Assign Date</TableHeader>
                  <TableHeader>Action</TableHeader>
                  <TableHeader>userid</TableHeader>
                  <TableCell>roleassinger</TableCell>
                </TableRow>
              </thead>
              <tbody>
                {userData.requestequipment.map((equipment, index) => (
                  <TableRow key={index}>
                    <TableCell>{equipment.name}</TableCell>
                    <TableCell>
                      {equipment.request_product_assigntime}
                    </TableCell>
                    <TableCell>{userData.user_id}</TableCell>
                    <TableCell>{userData.role}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleRequestNow(
                            equipment.product_id,
                            userData.user_id,
                            userData.role,
                            userData.created_by
                          )
                        }
                      >
                        Request Now
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No equipment available for request.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton
            variant="secondary"
            onClick={() => setShowRequest(false)}
          >
            Close
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AllUserProfile;
const PaginationComponent = ({
  totalItems,
  activePage,
  setActivePage,
  itemsPerPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => setActivePage(page);

  return (
    <Pagination>
      {[...Array(totalPages)].map((_, idx) => (
        <Pagination.Item
          key={idx}
          active={idx + 1 === activePage}
          onClick={() => handlePageChange(idx + 1)}
        >
          {idx + 1}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
  @media (max-width: 768px) {
    margin: 10px;
    gap: 10px;
  }
  .disabled-button {
    cursor: not-allowed !important;
    opacity: 0.6; /* Optional for a grayed-out effect */
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
  }

  .pagination button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 4px;
  }

  .pagination button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }

  .pagination button:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;

const InputWrapper = styled.div`
  flex: ${(props) => (props.fullwidth ? "1 1 100%" : "1 1 48%")};
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
  }
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  input::placeholder {
    font-size: 14px;
    color: #000;
  }
  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const EquipmentSection = styled.div`
  margin-top: 20px;
`;

const EquipmentTitle = styled.h4`
  margin: 10px 0;
  font-size: 14px;
  color: #718ebf;
`;

const EquipmentList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

const EquipmentItem = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const Button = styled.button`
  padding: 6px 12px;
  font-size: 14px;
  background: blue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const EquipmentHistory = styled.div`
  margin-top: 20px;
  h3 {
    margin-bottom: 10px;
  }
`;

const Timeline = styled.div`
  border-left: 2px solid #ccc;
  margin-left: 10px;
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  gap: 10px;
  margin: 10px 0;
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 0 2px black;
`;

const Details = styled.div`
  p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    span {
      font-size: 12px;
      color: gray;
    }
  }
`;

const Time = styled.div`
  font-size: 12px;
  color: gray;
`;
