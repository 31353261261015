import React from "react";
import styled from "styled-components";
import wallpaper from "../../Assets/Gemini_background.jpeg";
import profile from "../../Assets/ProfileIcon.png";
import { FaRegCopy } from "react-icons/fa";

const CardContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url(${wallpaper});
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  /* margin: auto; */
  height: 30vh;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const CardImage = styled.div`
  img {
    width: 100px;
    height: 100px;
    border-radius: 5%;
    object-fit: cover;
  }
`;

const CardDetails = styled.div`
  flex: 1;
  padding: 20px;
  margin-left: 1rem;
  backdrop-filter:blur(5px);
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

const CardName = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-shadow: 2px 2px #000;

`;

const CardUID = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  width: 50%;
  text-shadow: 1px 1px #000;

`;

const CardIcon = styled.div`
  margin-left: 1rem;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  transition: color 0.3s;
  text-shadow: 2px 5px #000;

  &:hover {
    color: #0056b3;
  }
`;

const Card = ({userData,user}) => {
    const handleCopy = () => {
        navigator.clipboard.writeText("6778596295566688257");
        alert("UID copied to clipboard!");
    };

    return (
        <CardContainer>
            <CardImage>
                <img
                    src={profile} // Replace with actual profile image
                    alt="User"
                />
            </CardImage>
            <CardDetails>
                <CardName>{userData?.unit_name || user?.username || "New User Request"}</CardName>
                <CardUID>Name : {userData?.username || userData?.username}</CardUID>
                <CardUID>Group : {userData?.group_name || ""}</CardUID>
                <CardUID>SKU: 6778596295566688257 <CardIcon>
                    <CopyButton onClick={handleCopy}>
                        <i className="fas fa-copy"><FaRegCopy /></i>
                    </CopyButton>
                </CardIcon></CardUID>
            </CardDetails>

        </CardContainer>
    );
};

export default Card;
