import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { API_BASE_URL_USER } from '../utils/https';
import axios from 'axios';
import { gsap } from 'gsap';
import { FaBellSlash, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { toast } from 'react-toastify';


const NotificationContainer = styled.div`
  width: 400px;
  position: fixed;
  top: 20px;
  right: -500px;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
`;

const UserName = styled.span`
  font-weight: bold;
`;
const Btn = styled.button`
  border: transparent;
  background-color: transparent;
  font-weight: 500;
  color: #2d60ff;
  cursor: pointer;
  &:hover{
    color: green;
  }
`;

const NotificationList = styled.ul`
  list-style: none;
  padding: 0;
`;
const MuteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #2d60ff;
  &:hover {
    color: green;
  }
`;
const NotificationItem = styled.li`
display: flex;
justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  background-color: #e0e0e0;
`;

const Notification = ({ handleNotificationLeave, name, notifications, setNotifications }) => {
    const notificationRef = useRef(null);
    const [isMuted, setIsMuted] = useState(false);

    useEffect(() => {
        if (!isMuted) {
            console.log('Slide-in animation starting...');
            gsap.to(notificationRef.current, {
                x: -500, // Move in from the right
                duration: 0.5,
                ease: 'power2.out',
            });
        }
    }, [isMuted]);

    const handleAction = async (notificationz, action) => {
        if (action === "hide") {
            setNotifications((prevNotifications) => {
                if (!Array.isArray(prevNotifications)) {
                    console.error("prevNotifications is not an array");
                    return [];
                }
                return prevNotifications.filter((n) => n.productId !== notificationz.productId);
            });
            return;
        }

        const { productId, senderId, senderRole, status } = notificationz || {};
        const finalStatus = status || (action === "is_deny" ? "denied" : "default_status");
        const isDeny = action === "is_deny" ? 1 : 0;
        try {
            const response = await axios.put(
                `${API_BASE_URL_USER}/notification-approved`,
                {
                    productId,
                    senderId,
                    senderRole,
                    status: finalStatus,
                    is_deny: isDeny,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (response.status === 200) {
                const notify = response?.data?.data?.status;
                toast.info(`${notify.status}`)
                setNotifications((prevNotifications) => {
                    if (!Array.isArray(prevNotifications)) {
                        return [];
                    }
                    return prevNotifications.filter((n) => n.notification_id !== notificationz.notification_id);
                });
            }
        } catch (error) {
            toast.error("Error confirming notification", error);
        }
    };

    const handleMouseLeave = () => {
        gsap.to(notificationRef.current, {
            x: 500,
            duration: 0.5,
            ease: 'power2.in',
            onComplete: handleNotificationLeave,
        });
    };

    const toggleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };
    return (
        <>
            <NotificationContainer ref={notificationRef} onMouseLeave={handleMouseLeave}>
                <NotificationHeader>
                    <UserName>Hello, {name} {notifications.totalcount}</UserName>
                    <MuteButton onClick={toggleMute}>
                        {isMuted ? <FaVolumeUp /> : <FaVolumeMute />}
                    </MuteButton>
                </NotificationHeader>
                {!isMuted && (
                    <NotificationList>
                        {notifications?.data && notifications.data.length > 0 ? (
                            notifications?.data?.map((notification) => (
                                <NotificationItem key={notification.notification_id}>
                                    <>
                                        {notification.name} {notification.status} by {notification.senderUsername}
                                    </>
                                    <div>
                                        {notification.status === "Approve Transfer Request" ? (
                                            <Btn onClick={() => handleAction(notification, "hide")}>
                                                ✖
                                            </Btn>
                                        ) : (
                                            <>
                                                <Btn onClick={() => handleAction(notification, "confirm")}>Confirm</Btn> /
                                                <Btn onClick={() => handleAction(notification, "is_deny")}>Deny</Btn>
                                            </>
                                        )}
                                    </div>
                                </NotificationItem>
                            ))
                        ) : (
                            <NotificationItem>
                                <div style={{ textAlign: 'center', width: '100%', color: '#666' }}>
                                    <FaBellSlash size={30} style={{ marginBottom: '10px' }} />
                                    <p>No notifications available</p>
                                </div>
                            </NotificationItem>
                        )}
                    </NotificationList>
                )}
                {isMuted && (
                    <NotificationList>
                        <div style={{ textAlign: 'center', padding: '10px', color: '#666' }}>
                            <span>Mute is ON - Click the button to show notifications</span>
                        </div>
                    </NotificationList>
                )}
            </NotificationContainer>
        </>
    );
};

export default Notification;