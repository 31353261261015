import React, { useState } from "react";
import styled from "styled-components";
import { AssignNowButton, BlueBackgroundButton } from "../Global/GlobalButtons";
import GlobalSearch from "../Global/GlobalSearch";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Modal } from "react-bootstrap";
import { BorderSelect, CheckboxLabel } from "../Global/GlobalPlaceholder";
import profile from "../../Assets/profile.png";
import { GroupHead, GroupSubHead } from "../Global/GlobalText";
import useAppUtils, { useUnitList } from "../../utils/useAppUtils";
import { DeleteUnitAction, setSelectedUnitId } from "../../redux/users/action";
import { toast } from "react-toastify";
import { IoSearchSharp } from "react-icons/io5";
import { NoDataWrapper } from "../Global/GlobalTable";
import { NoDataIcon } from "../group/GroupUserList";
import { Pagination } from "../Global/Table2";


const UniteProfile = () => {
  const [showAssignProduct, setShowAssignProduct] = useState(false);
  const units = useUnitList();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("unit_name"); // Default to 'unit_name'
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const handleClose = () => {
    setShowAssignProduct(false);
  };
  const handleShowAssignProduct = () => setShowAssignProduct(true);
  const groups = [
    { id: 1, groupName: "Group A", groupSub: "ABC", unitName: "XYZ" },
    { id: 2, groupName: "Group B", groupSub: "DEF", unitName: "LMN" },
    { id: 3, groupName: "Group C", groupSub: "GHI", unitName: "OPQ" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const { dispatch, navigate } = useAppUtils();

  const handleDeleteUnit = (unitId) => {
    dispatch(
      DeleteUnitAction({ unitId }, (response) => {
        if (response?.status === 200) {
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  const handleNavigateToUnit = (unitId) => {
    dispatch(setSelectedUnitId(unitId));
    navigate(`/unit_userlist/${unitId}`);
  };
 
  const filteredUnits = units.filter((unit) => {
    const fieldToSearch = unit[searchCriteria]?.toLowerCase() || "";
    return fieldToSearch.includes(searchTerm.toLowerCase());
  });
  const totalItems = filteredUnits.length;
  const paginatedUnits = filteredUnits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <Root>
      <div className="save_btn">
        <BlueBackgroundButton
          onClick={() => {
            navigate("/group_create");
          }}
        >
          Create Group
        </BlueBackgroundButton>
        <BlueBackgroundButton
          onClick={() => {
            navigate("/group_create", { state: { selectedTab: "tab4" } });
          }}
        >
          Create Unit
        </BlueBackgroundButton>
      </div>
      <div className="search-bar-container">
        <div className="search-wrapper">
          <div className="icon_div">
            <IoSearchSharp />
          </div>
          <div className="icon_content">
            <input
              type="text"
              placeholder={`Search by ${
                searchCriteria === "unit_name" ? "Unit Name" : "Creator"
              }`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
        </div>

        <div className="search-criteria-selector">
          <BorderSelect
            value={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value)}
            className="search-criteria-dropdown"
          >
            <option value="unit_name">Unit Name</option>
            <option value="role">Creator</option>
          </BorderSelect>
        </div>

        <div className="location-selector">
          <BlueBackgroundButton onClick={handleShowAssignProduct}>
            Change Group Location
          </BlueBackgroundButton>
        </div>
      </div>
      {paginatedUnits.length > 0 ? (
        <TableWrapper>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Select</TableHeader>
                <TableHeader>SL No</TableHeader>
                <TableHeader>Create Date & Time</TableHeader>
                <TableHeader>Created By</TableHeader>
                <TableHeader>Unit List</TableHeader>
                <TableHeader>Member List</TableHeader>
                <TableHeader>Equipment List</TableHeader>
                <TableHeader>Remove</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {paginatedUnits.map((unit, index) => (
                <TableRow key={unit.unit_id}>
                  <TableCell>
                    <input type="checkbox" />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {unit.create_date
                      ? formatDate(unit.create_date)
                      : "No Data"}
                  </TableCell>
                  <TableCell>{unit.role || "No Role"}</TableCell>
                  <TableCell>{unit.unit_name || "No Unit"}</TableCell>
                  <TableCell
                    onClick={() => handleNavigateToUnit(unit.unit_id)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {unit.user_count || "No Member"}
                  </TableCell>
                  <TableCell>
                    {unit.equipment_permission_count || "No Equipment"}
                  </TableCell>
                  <TableCell>
                    <RiDeleteBin5Fill
                      className="delete_button"
                      onClick={() => handleDeleteUnit(unit.unit_id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          {/* Pagination Component */}
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </TableWrapper>
      ) : (
        <NoDataWrapper>
          <NoDataIcon />
          <h3>No Data Available</h3>
          <p>Please add or fetch data to display here.</p>
        </NoDataWrapper>
      )}

      <StyledModal
        show={showAssignProduct}
        onHide={handleClose}
        className="group_location_modal"
        centered
      >
        <Modal.Header closeButton>
          <div className="select_search">
            <div className="check_select">
              <input type="checkbox" />
              <CheckboxLabel htmlFor="Select All">Select All</CheckboxLabel>
            </div>

            <div className="search_div">
              <GlobalSearch search="Group, Unit" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="check_main_div">
            <div className="checkout_div">
              {groups.map((group, index) => (
                <div className="group_div" key={group.id}>
                  <input type="checkbox" />
                  <div className="group_cont">
                    <img src={profile} alt="img" />
                    <div className="group_sub_cont">
                      <GroupHead>{group.groupName}</GroupHead>
                      <GroupSubHead>{group.groupSub}</GroupSubHead>
                    </div>
                    <div className="group_sub_cont">
                      <GroupHead>Unit</GroupHead>
                      <GroupSubHead>{group.unitName}</GroupSubHead>
                    </div>
                    <AssignNowButton>Move Here</AssignNowButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </StyledModal>
    </Root>
  );
};

export default UniteProfile;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #e5f4fb;
  }

  .select_search {
    display: flex;
    gap: 0px;
    .check_select {
      width: 25%;
      gap: 10px;
      display: flex;
      align-items: center;
    }
    .search_div {
      width: 80%;
      gap: 30px;
    }
  }

  .check_main_div {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .checkout_div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .group_div {
      display: flex;
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      align-items: center;
      gap: 20px;

      .group_cont {
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: center;
        .group_sub_cont {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;

const Root = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  .search-wrapper {
    border: 1px solid #e5e1e1;
    height: 55px;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 20px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #718ebf;
        width: 35px;
        height: 35px;
      }
    }

    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        height: 50px;
        width: 100%;
        border: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
        background-color: transparent;
      }

      input[type="text"]::placeholder {
        color: #8ba3cb;
        background-color: transparent;
      }
    }
  }
  .save_btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .button_div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    button {
      min-width: 150px;
    }
  }

  .change_location_btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .delete_button {
    width: 30px;
    height: 30px;
    color: #fc0005;
    cursor: pointer;
  }

  .modal-content {
    background: #e5f4fb;
  }

  .search-bar-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .search-criteria-dropdown {
    background-color: #fff;
    height: 50px;
    color: #8ba3cb;
    border-radius: 20px;
  }
  #search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1;
  }

  #search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto; // Make the table scrollable on smaller screens
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

export const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #e3f2fd;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  font-size: 14px;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
`;
