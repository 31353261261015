import React, { useState } from "react";
import styled from "styled-components";
import {
  BlueBorderContent,
  GrayBorderButton,
  TabButton,
} from "../Global/GlobalButtons";
import GlobalSearch from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";
import useAppUtils, {
  useGroupRequestSent,
  useMemberRequestSent,
} from "../../utils/useAppUtils";
import {
  UpdateGroupStatusAction,
  UpdateMemberStatusAction,
} from "../../redux/users/action";
import { toast } from "react-toastify";
import { BorderSelect } from "../Global/GlobalPlaceholder";

const columns = [
  { header: "Select", accessor: "select" },
  { header: "SL No", accessor: "memberlist_id" },
  { header: "Date & Time", accessor: "create_date" },
  { header: "Username", accessor: "name" },
  { header: "User Role", accessor: "userid_role" },
  { header: " Previous Group", accessor: "lastgroup_name" },
  { header: "New Group", accessor: "group_name" },
  { header: "Requested By ", accessor: "requestBy_role" },
  { header: "Status", accessor: "status" },
];

const columnsTwo = [
  { header: "Select", accessor: "select" },
  { header: "SL No", accessor: "slNo" },
  { header: "Date & Time", accessor: "datetime" },
  { header: "Equipment List", accessor: "image" },
  { header: "Team Strength", accessor: "teamstrength" },
  { header: "Position", accessor: "position" },
  { header: "Requested By / Group", accessor: "requestedbyunit" },
  { header: "Requested For/ Unit", accessor: "requestedforgroup" },
  { header: "Status", accessor: "status" },
  { header: "Group ID", accessor: "groupid" },
  { header: "Unit ID", accessor: "unitid" },  
];

export const Request = () => {
  const { dispatch, navigate } = useAppUtils();
  const memberRequest = useMemberRequestSent();
  const dataget = useGroupRequestSent();
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [selectedButton, setSelectedButton] = useState(1);
  const [dataTwo, setDataTwo] = useState([]);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const handleSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query on input change
  };

  const handleTabClickForUpdate = (tab) => {
    setSelectedTab(tab);
    if (tab === "tab3") { // Clear data first
      if (dataget.length > 0) {
        const transformedData = dataget.map((item, index) => ({
          slNo: item.request_id, 
          datetime: new Date(item.create_date).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }),
          image: "CapIcon",
          teamstrength: "N/A",
          position: item.requestBy_role,
          requestedbyunit: item.group_name || "Unknown User",
          requestedforgroup: item.unit_name || "Unknown Group",
          status: (
            <BorderSelect
              onChange={(e) => handleStatusChange(e, index)}
              value={item.status}
            >
              <option value="Pending">Pending</option>
              <option value="Approved">Approve</option>
              <option value="Declined">Decline</option>
            </BorderSelect>
          ),
          groupid: item.groupid,
          unitid: item.unitid,
          selected: false,
        }));
        setDataTwo(transformedData);
      } else {
        console.error("dataget is empty or not properly populated");
      }
    }
  };

  const handleStatusChange = (e, index) => {
    console.log("dataTwo before change:", dataTwo);
    const updatedStatus = e.target.value;
    if (dataTwo && dataTwo[index]) {
      const updatedData = [...dataTwo];
      updatedData[index].status = updatedStatus;
      setDataTwo(updatedData);
      const id = updatedData[index].slNo;
      const status = updatedData[index].status;
      const groupid = updatedData[index].groupid;
      const unitid = updatedData[index].unitid;
      UpdateGroupStatus(id, status, groupid, unitid);
    } else {
      console.error("Item not found in dataTwo at index:", index);
    }
  };

  const UpdateGroupStatus = (id, status, groupid, unitid) => {
    dispatch(
      UpdateGroupStatusAction({ id, status, groupid, unitid }, (response) => {
        if (response?.status === 200) {
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  const handleTabClickForMem = (tab) => {
    setSelectedTab(tab);
    if (tab === "tab2") {
      setData([]); // Clear data first
      if (memberRequest.length > 0) {
        const transformedData = memberRequest.map((item, index) => ({
          memberlist_id: item.memberlist_id,
          create_date: new Date(item.create_date).toLocaleDateString("en-US"),
          name: item.name || "N/A",
          userid_role: item.userid_role,
          status:
            item.status === "Approved" ? (
              <span>Approved</span> // Non-editable display for Approved
            ) : (
              <BorderSelect
                onChange={(e) => handleStatusChangeMem(e, index)}
                value={item.status}
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approve</option>
                <option value="Declined">Decline</option>
              </BorderSelect>
            ),
        }));
        setData(transformedData);
      } else {
        console.error("dataget is empty or not properly populated");
      }
    }
  };

  const UpdateMemberStatus = (id, status) => {
    dispatch(
      UpdateMemberStatusAction({ id, status }, (response) => {
        if (response?.status === 200) {
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
   
  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };
  const handleStatusChangeMem = (e, index) => {
    if (index < 0 || index >= data.length) {
      console.error("Invalid index:", index);
      return;
    }

    const updatedStatus = e.target.value;
    const updatedData = [...data];

    if (updatedData[index]) {
      updatedData[index].status = updatedStatus; // Update the status
      setData(updatedData);

      const id = updatedData[index].memberlist_id;
      UpdateMemberStatus(id, updatedStatus);
    } else {
      console.error("Item at index", index, "is undefined.");
    }
  };

  const onSelectChange = (index) => {
    if (selectedTab === "tab1" || selectedTab === "tab2") {
      const newData = [...data];
      newData[index].selected = !newData[index].selected;
      setData(newData);
    } else if (selectedTab === "tab3" || selectedTab === "tab4") {
      const newDataTwo = [...dataTwo];
      newDataTwo[index].selected = !newDataTwo[index].selected;
      setDataTwo(newDataTwo);
    }
  };

  const renderButtonGroup = () => (
    <div className="button_div">
      <BlueBorderContent
        className={selectedButton === 1 ? "selected" : ""}
        onClick={() => handleButtonClick(1)}
      >
        Product name
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 2 ? "selected" : ""}
        onClick={() => handleButtonClick(2)}
      >
        Category
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 3 ? "selected" : ""}
        onClick={() => handleButtonClick(3)}
      >
        Profile
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 4 ? "selected" : ""}
        onClick={() => handleButtonClick(4)}
      >
        Requested By
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 5 ? "selected" : ""}
        onClick={() => handleButtonClick(5)}
      >
        Group/Unit
      </BlueBorderContent>
    </div>
  );
  const renderAddedButtonGroup = () => (
    <div className="button_div">
      <BlueBorderContent
        className={selectedButton === 6 ? "selected" : ""}
        onClick={() => handleButtonClick(6)}
      >
        Position
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 7 ? "selected" : ""}
        onClick={() => handleButtonClick(7)}
      >
        Equipment List
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 8 ? "selected" : ""}
        onClick={() => handleButtonClick(8)}
      >
        Date & Time
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 9 ? "selected" : ""}
        onClick={() => handleButtonClick(9)}
      >
        Requested By
      </BlueBorderContent>
      <BlueBorderContent
        className={selectedButton === 10 ? "selected" : ""}
        onClick={() => handleButtonClick(10)}
      >
        Group/Unit
      </BlueBorderContent>
    </div>
  );
  
  return (
    <Root>
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => setSelectedTab("tab1")}
        >
          Member Request Sent
        </TabButton>

        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClickForMem("tab2")}
        >
          Member Requested Received
        </TabButton>

        <TabButton
          className={selectedTab === "tab3" ? "selected" : ""}
          onClick={() => handleTabClickForUpdate("tab3")}
        >
          Group Requested Received
        </TabButton>

        <TabButton
          className={selectedTab === "tab4" ? "selected" : ""}
          onClick={() => setSelectedTab("tab4")}
        >
          Group Requested Sent
        </TabButton>
      </div>

      <div className="search_main_div">
        <GlobalSearch search="Search for something"  value={searchQuery}
        onChange={handleSearch}/>
      </div>

      <div className="table">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {selectedTab === "tab1" ? (
                <>
                  {renderButtonGroup()}
                  <div className="table_div">
                    <GlobalTable
                      columns={columns}
                      data={
                        selectedTab === "tab1" && memberRequest.length > 0
                          ? memberRequest.filter((item) =>
                            item.name.toLowerCase().includes(searchQuery.toLowerCase())
                          ).map((item) => ({
                              select: item.select,
                              memberlist_id: item.memberlist_id,
                              create_date: new Date(
                                item.create_date
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                              }),
                              name: item.name,
                              userid_role: item.userid_role,
                              image: item.image_url || "CapIcon", // Fallback to default image
                              lastgroup_name:
                                item.lastgroup_name || "Unknown Unit",
                              group_name: item.group_name || "Unknown Group",
                              requestBy_role: item.requestBy_role,
                              status: (
                                <GrayBorderButton>
                                  {item.status.charAt(0).toUpperCase() +
                                    item.status.slice(1)}
                                </GrayBorderButton>
                              ),
                              selected: false,
                            }))
                          : []  
                      }
                      onSelectChange={onSelectChange}
                    />
                  </div>
                </>
              ) : selectedTab === "tab2" ? (
                <>
                  {renderButtonGroup()}
                  <div className="table_div">
                    <GlobalTable
                      columns={columns}
                      data={
                        selectedTab === "tab2" && memberRequest.length > 0
                          ? memberRequest.filter((item) =>
                            item.name.toLowerCase().includes(searchQuery.toLowerCase())
                          ).map((item, index) => ({
                              select: item.select,
                              memberlist_id: item.memberlist_id,
                              create_date: new Date(
                                item.create_date
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                              }),
                              name: item.name,
                              userid_role: item.userid_role,
                              image: item.image_url || "CapIcon", // Fallback to default image
                              lastgroup_name:
                                item.lastgroup_name || "Unknown Unit",
                              group_name: item.group_name || "Unknown Group",
                              requestBy_role: item.requestBy_role,

                              status:
                                item.status === "Approved" ? (
                                  <span>Approved</span> // Non-editable display for Approved
                                ) : (
                                  <BorderSelect
                                    onChange={(e) =>
                                      handleStatusChangeMem(e, index)
                                    }
                                    value={item.status}
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approve</option>
                                    <option value="Declined">Decline</option>
                                  </BorderSelect>
                                ),
                              selected: false,
                            }))
                          : [] 
                      }
                      onSelectChange={onSelectChange}
                    />
                  </div>
                </>
              ) : selectedTab === "tab3" ? (
                <>
                  {renderAddedButtonGroup()}

                  {dataget?.length ? (
                    <div className="table_div">
                      <GlobalTable
                        columns={columnsTwo}
                        data={dataget.filter((item) =>
                          item.group_name
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).map((item, index) => ({
                          slNo: item.request_id,
                          datetime: new Date(
                            item.create_date
                          ).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }),
                          image: "CapIcon",
                          teamstrength: "N/A",
                          position: item.requestBy_role,
                          requestedbyunit: item.group_name || "Unknown User",
                          requestedforgroup: item.unit_name || "Unknown Group",
                          status: (
                            <BorderSelect
                              onChange={(e) => handleStatusChange(e, index)}
                              value={item.status}
                            >
                              <option value="Pending">Pending</option>
                              <option value="Approved">Approve</option>
                              <option value="Declined">Decline</option>
                            </BorderSelect>
                          ),
                          groupid: item.groupid,
                          unitid: item.unitid,
                          selected: false,
                        }))}
                        onSelectChange={onSelectChange}
                      />
                    </div>
                  ) : (
                    <p className="no-data">No data available for this tab.</p>
                  )}
                </>
              ) : selectedTab === "tab4" ? (
                <>
                  {renderAddedButtonGroup()}
                  <div className="table_div">
                    <GlobalTable
                      columns={columnsTwo}
                      data={
                        dataget.length > 0
                          ? dataget.filter((item) =>
                            item.group_name
                              ?.toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          ).map((item, index) => ({
                              slNo: index + 1, // Assuming the index + 1 is the SL No
                              datetime: new Date(
                                item.create_date
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                              }),
                              image: "CapIcon",
                              teamstrength: item.total_users_in_group || "N/A",
                              position: item.requestBy_role,
                              requestedbyunit:
                                item.group_name || "Unknown User",
                              requestedforgroup:
                                item.unit_name || "Unknown Group",
                              status: (
                                <GrayBorderButton>
                                  {item.status.charAt(0).toUpperCase() +
                                    item.status.slice(1)}
                                </GrayBorderButton>
                              ),
                              selected: false,
                              groupid: item.groupid,  
                              unitid: item.unitid, 
                            }))
                          : []  
                      }
                      onSelectChange={onSelectChange}
                    />
                  </div>
                </>
              ) : (
                <h1>No Data</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Request;

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;

  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .button_div {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .search_main_div {
    padding: 20px 50px;
  }

  .table_div {
    margin-top: 30px;
    .table {
      width: 100%;
      height: 100%;
      margin-top: 20px;
    }
  }
`;
