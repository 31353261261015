import React, { useEffect, useState } from "react";
import GlobalUserForm from "../Global/GlobalUserForm";
import styled from "styled-components";
import GlobalSearch from "../Global/GlobalSearch";
import { FormTextBlack, Textlabel } from "../Global/GlobalText";
import {
  BorderInput,
  BorderSelect,
  CheckboxInput,
  CheckboxLabel,
} from "../Global/GlobalPlaceholder";
import imgIcon from "../../Assets/CopyIcon.png";
import useAppUtils, {
  useGroupList,
  useProductList,
  useRoleList,
  useUnitList,
  useUserList,
} from "../../utils/useAppUtils";
import { StyleUserForm } from "./AddUserTab";
import {
  addPermission,
  addSelection,
  removePermission,
  removeSelection,
} from "./DummyFolder/action";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BlueBackgroundButton } from "../Global/GlobalButtons";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { API_BASE_URL } from "../../utils/https";
import axios from "axios";



const validationSchema = Yup.object({
  role: Yup.string().required("Role is required"),
  name: Yup.string().required("Name is required"),
  username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  mobile_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  groupid: Yup.string().required("Group is required"),
  unitid: Yup.string().required("Unit is required"),
  permission_ids: Yup.array()
    .min(1, "At least one permission is required.")
    .required("Permission is required"),
  product_ids: Yup.array()
    .min(1, "At least one product is required.")
    .required("Product ID is required."),
  assign_to: Yup.array().required("Allow multiple is required"),
  group_id: Yup.array()
    .min(1, "At least one group is required.")
    .required("Group ID is required."),
  unit_id: Yup.array()
    .min(1, "At least one unit is required.")
    .required("Unit ID is required."),
});
const permissionMap = {
  child1: {
    id: 2,
    name: "Transfer equipment (without the need for third-party approval)",
  },
  child2: { id: 3, name: "Choice of Place (Group or Unit)" },
  child7: { id: 4, name: "Who is the third party" },
  child3: { id: 7, name: "Select equipment type" },
  child4: { id: 8, name: "Choose between contains / does not contain" },
  child5: { id: 9, name: "What type of equipment" },
  // child25: { id: 57, name: "Give specific permissions" },
  child26: { id: 37, name: "Request equipment (without the need for third-party  approval)" },
  child27: { id: 33, name: "Choice of place: within the unit / within the selected group (you can check several boxes)" },
  child38: { id: 14, name: "Creating new groups in the unit" },
  child6: { id: 15, name: "Transfer equipment (with the need for third-party approval)" },
  child41: { id: 16, name: "Move groups within the unit" },
  child12: { id: 17, name: "Selection of equipment type" },
  child28: { id: 18, name: "Selection of equipment type" },
  child34: { id: 19, name: "Selection of equipment type" },
  child15: { id: 20, name: "Warehouse Management" },
  child16: { id: 21, name: "Change equipment name" },
  child17: { id: 22, name: "Change category to equipment" },
  child18: { id: 23, name: "Add equipment to the warehouse" },
  child22: { id: 24, name: "Delete a product" },
  child23: { id: 25, name: "With transfer history" },
  child24: { id: 26, name: "Without transfer history" },
  child46: { id: 27, name: "Access to a unit profile" },
  child48: { id: 28, name: "Send a request to another unit to transfer a group to it" },
  child49: { id: 29, name: "Invite new users to the unit" },
  child50: { id: 30, name: "Approve requests from users to join the unit" },
  child51: { id: 31, name: "Open a sub-warehouse" },
  child31: { id: 32, name: "Request equipment that requires third-party approval" },
  
};
export const InviteUserTab = () => {
  const units = useUnitList();
  const groups = useGroupList();
  const role = useRoleList();
    const users = useUserList();
  const products = useProductList();
  const { dispatch,navigate } = useAppUtils;
  const [checkedItems, setCheckedItems] = useState({
    selectAll: false,
    child1: false,
    child2: false,
    child2_1: false,
    child2_2: false,
    child3: false,
    child4: false,
    child5: false, //-------------------------------
    child6: false,
    child7: false,
    child8: false,
    child9: false,
    child10: false,
    child11: false,
    child12: false,
    child13: false,
    child14: false, //---------------------------------
    child15: false,
    child16: false,
    child17: false,
    child18: false,
    child19: false,
    child20: false,
    child21: false,
    child22: false,
    child23: false,
    child24: false,
    child25: false,
    child26: false,
    child27: false,
    child28: false,
    child29: false,
    child30: false,
    noLimitCheckbox: false,
    requestEquipmentParentCheckbox: false,
    requestThirdPartyParentCheckbox: false,
    child31: false,
    child32: false,
    child33: false,
    child34: false,
    child35: false,
    child36: false,
    child37: false,
    child38: false,
    child39: false,
    child40: false,
    child41: false,
    child42: false,
    child43: false,
    child44: false,
    child45: false,
    child46: false,
    child47: false,
    child48: false,
    child49: false,
    child50: false,
    child51: false,
  });


    const initialValues = {
      role: "",
      name: "",
      username: "",
      email: "",
      password: "",
      mobile_number: "",
      groupid: "",
      unitid: "",
      permission_ids: [],
      product_ids: [],
      assign_to: [],
      group_id: [],
      unit_id: [],
    };
  
    const handleAddUser = async (values, formik) => {
      const { setSubmitting } = formik;
      try {
        const axiosConfig = {
          // withCredentials: true,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        const response = await axios.post(
          `${API_BASE_URL}/user-create`,
          values,
          axiosConfig
        );
        console.log("resres", response?.data?.data);
        setSubmitting(false);
        if (response?.status === 200) {
          formik.resetForm();
          selectedItems.forEach((item) => dispatch(removeSelection(item)));
          toast.success(response.message);
          navigate("/users_list");
        } else {
          formik.resetForm();
          selectedItems.forEach((item) => dispatch(removeSelection(item)));
          toast.error("Process failed. Please try again.");
        }
      } catch (err) {
        console.log("err", err);
        toast.error("An error occurred during login");
      }
    };
  
  const selectedItems = useSelector(
    (state) => state.selection.selectedItems || []
  );

  const handleCheckboxChange = (e, formik) => {
    const { id, checked } = e.target;
    const permission = permissionMap[id];

    if (permission) {
      const currentPermissionIds = new Set(formik.values.permission_ids);
      formik.setFieldValue(
        "permission_ids",
        checked
          ? [...formik.values.permission_ids, permission.id] // Add ID if checked
          : formik.values.permission_ids.filter(
              (permId) => permId !== permission.id
            )
      );

      if (checked) {
        currentPermissionIds.add(permission.id);
        dispatch(addPermission(permission));
        console.log(`Added Permission:`, permission);
      } else {
        dispatch(removePermission(permission.id));
        console.log(`Removed Permission:`, permission.id);
      }
    }

    setCheckedItems((prev) => {
      let newState = { ...prev, [id]: checked };

      if (id === "child1" && checked) {
        ["child2_1", "child2_2"].forEach((childId) => {
          const childPermission = permissionMap[childId];
          if (!childPermission) {
            console.warn(`Permission not found for childId: ${childId}`);
            return;
          }

          if (childPermission) {
            newState[childId] = true;
            dispatch(addPermission(childPermission));
            formik.setFieldValue("permission_ids", [
              ...(formik.values.permission_ids || []),
              childPermission.id,
            ]);
          }
        });
      }

      if (id === "selectAll") {
        Object.keys(prev).forEach((key) => {
          newState[key] = checked;
        });
      }
      if (id === "child1" && checked) {
        newState = {
          ...newState,
          child6: false,
          child7: false,
          child8: false,
          child9: false,
          child10: false,
          child11: false,
          child12: false,
          child13: false,
          child14: false,
        };
      } else if (id === "child6" && checked) {
        newState = {
          ...newState,
          child1: false,
          child2: false,
          child2_1: false,
          child2_2: false,
          child3: false,
          child4: false,
          child5: false,
        };
      }

      if (id === "child1") {
        if (checked) {
          newState = {
            ...newState,
            child1: true,
            child2: true,
            child2_1: true,
            child2_2: true,
            child3: true,
            child4: true,
            child5: true,
          };
        } else {
          newState = {
            ...newState,
            child1: false,
            child2: false,
            child2_1: false,
            child2_2: false,
            child3: false,
            child4: false,
            child5: false,
          };
        }
      } else if (id === "child6") {
        if (checked) {
          newState = {
            ...newState,
            child6: true,
            child7: true,
            child8: true,
            child9: true,
            child10: true,
            child11: true,
            child12: true,
            child13: true,
            child14: true,
          };
        } else {
          newState = {
            ...newState,
            child6: false,
            child7: false,
            child8: false,
            child9: false,
            child10: false,
            child11: false,
            child12: false,
            child13: false,
            child14: false,
          };
        }
      } else if (id === "child26") {
        if (checked) {
          newState = {
            ...newState,
            child26: true,
            child27: true,
            child28: true,
            child29: true,
            child30: true,
          };
        } else {
          newState = {
            ...newState,
            child26: false,
            child27: false,
            child28: false,
            child29: false,
            child30: false,
          };
        }
      } else {
        newState[id] = checked;
      }

      setCheckedItems(newState);

      const permissionIds = Object.keys(newState)
        .filter((key) => newState[key])
        .map((key) => permissionMap[key]?.id)
        .filter((id) => id); // Keep only valid IDs
      formik.setFieldValue("permission_ids", permissionIds);
      return newState;
    });
  };

  const handleItemClick = (itemType, itemId, formik) => {
    if (!selectedItems) return;

    const isSelected = selectedItems.some(
      (item) => item.type === itemType && item.id === itemId
    );
    const payload = { type: itemType, id: itemId };

    if (isSelected) {
      dispatch(removeSelection(payload));
      if (itemType === "products") {
        formik.setFieldValue(
          "product_ids",
          formik.values.product_ids.filter((id) => id !== itemId)
        );
      } else if (itemType === "groups") {
        formik.setFieldValue(
          "group_id",
          formik.values.group_id.filter((id) => id !== itemId)
        );
      } else if (itemType === "units") {
        formik.setFieldValue(
          "unit_id",
          formik.values.unit_id.filter((id) => id !== itemId)
        );
      }
    } else {
      dispatch(addSelection(payload));
      if (itemType === "products") {
        formik.setFieldValue("product_ids", [
          ...formik.values.product_ids,
          itemId,
        ]);
      } else if (itemType === "groups") {
        formik.setFieldValue("group_id", [...formik.values.group_id, itemId]);
      } else if (itemType === "units") {
        formik.setFieldValue("unit_id", [...formik.values.unit_id, itemId]);
      }
    }
  };

  useEffect(() => {
    const logSelectedNames = () => {
      const selectedGroups = selectedItems
        .filter((item) => item.type === "groups")
        .map(
          (item) => groups.find((group) => group.id === item.id)?.group_name
        );

      const selectedUnits = selectedItems
        .filter((item) => item.type === "units")
        .map(
          (item) => units.find((unit) => unit.unit_id === item.id)?.unit_name
        );

      const selectedProducts = selectedItems
        .filter((item) => item.type === "products")
        .map(
          (item) =>
            products.find((product) => product.product_id === item.id)?.name
        );
      console.log("Selected Groups:", selectedGroups);
      console.log("Selected Units:", selectedUnits);
      console.log("Selected Products:", selectedProducts);
    };
    logSelectedNames();
  }, [selectedItems, groups, units, products]);

  return (
    <Root>
       <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, formik) => handleAddUser(values, formik)}
            >
              {(formik) => (
                <Form>
      <div className="profile_input">
        <div className="input_select">
          <div className="input_select_desc">
            <Textlabel htmlFor="group">Assign Group</Textlabel>
            <BorderSelect name="groupid">
              <option value="">Select Group</option>
              {groups?.map((group) => {
                return (
                  <option key={group.group_id} value={group.group_id}>
                    {group.group_name}
                  </option>
                );
              })}
            </BorderSelect>
          </div>

          <div className="input_select_unit">
            <Textlabel htmlFor="unit">Unit</Textlabel>
            <BorderSelect name="unitid" as={BorderSelect}>
              <option value="">Select Unit</option>
              {units?.map((unit) => (
                <option key={unit.unit_id} value={unit.unit_id}>
                  {unit.unit_name}
                </option>
              ))}
            </BorderSelect>
          </div>
        </div>
        <div className="input_select">
          <div className="input_select_sub_div">
            <Textlabel htmlFor="role">Role</Textlabel>
            <BorderSelect name="role" as={BorderSelect}>
              <option value="">Select Role</option>
              {role?.map((role) => (
                <option key={role.role} value={role.role}>
                  {role.role}
                </option>
              ))}
            </BorderSelect>
          </div>
        </div>
      </div>
      <div className="Share_Link">
        <div className="input_select">
          <Textlabel htmlFor="link">
            Share Link <img src={imgIcon} alt="copy icon" />{" "}
          </Textlabel>
          <BorderInput placeholder="https://www.logistix.com/xyz-xyaa-oyr" />
        </div>
      </div>

      <div className="search">
        <FormTextBlack>Select Permission</FormTextBlack>
        <GlobalSearch search="Search for something" />
      </div>
      <>
                   <div className="main_heading">
                     <div className="check_label">
                       <CheckboxInput
                         type="checkbox"
                         id="selectAll"
                         checked={checkedItems.selectAll}
                         onChange={(e) => handleCheckboxChange(e, formik)}
                       />
                       <CheckboxLabel htmlFor="selectAll" id="1">
                         Select All Permission 1
                       </CheckboxLabel>
                     </div>
                   </div>
                   <StyleUserForm>
                     <div className="first_head_div left_marg">
                       <div className="check_label">
                         <CheckboxInput
                           type="checkbox"
                           id="child1"
                           checked={checkedItems.child1}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel
                           htmlFor="child1"
                           value="Transfer equipment (without the need for third-party
                           approval)"
                           id="2"
                         >
                           Transfer equipment (without the need for third-party
                           approval)2
                         </CheckboxLabel>
                       </div>
                       {checkedItems.child1 && (
                         <>
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child2"
                               checked={checkedItems.child2 && checkedItems.child1}
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child2"
                               value="Choice of Place (Group or Unit)"
                               id="3"
                             >
                               Choice of Place (Group or Unit)3
                             </CheckboxLabel>
                           </div>
     
                           <div className="dual_checkbox d-flex ">
                             <div className="check_label left_spac">
                               <CheckboxInput
                                 type="checkbox"
                                 id="child2_1"
                                 checked={
                                   checkedItems.child2_1 && checkedItems.child1
                                 }
                                 onChange={(e) => handleCheckboxChange(e, formik)}
                               />
                               <CheckboxLabel htmlFor="child2_1" id="child2_1">
                                 Group child2_1
                               </CheckboxLabel>
                             </div>
     
                             <div className="check_label left_spac">
                               <CheckboxInput
                                 type="checkbox"
                                 id="child2_2"
                                 checked={
                                   checkedItems.child2_2 && checkedItems.child1
                                 }
                                 onChange={(e) => handleCheckboxChange(e, formik)}
                               />
                               <CheckboxLabel htmlFor="child2_2" id="child2_2">
                                 Unit child2_2
                               </CheckboxLabel>
                             </div>
                           </div>
                           <div className="dual_checkbox d-flex ">
                             <div
                               className="check_label  "
                               
                             >
                               {checkedItems.child2_1 && checkedItems.child1 && (
                                 <div className="check_label left_spac" style={{
                                   minWidth: "100px",
                                   width: "300px",
                                   height: "300px",
                                   overflowY: "auto",
                                   border: "1px solid lightgray",
                                   padding: "10px",
                                   borderRadius: "10px",
                                   marginBottom: "10px",
                                 }}>
                                   <table className="group-table" value="group_id">
                                     <thead>
                                       <tr>
                                         <th>Group Name</th>
                                       </tr>
                                     </thead>
                                     {groups?.map((group) => (
                                       <tbody key={group.group_id}>
                                         <tr>
                                           <td
                                             onClick={() =>
                                               handleItemClick(
                                                 "groups",
                                                 group.group_id,
                                                 formik
                                               )
                                             }
                                             style={{
                                               cursor: "pointer",
                                               fontWeight: selectedItems.some(
                                                 (item) =>
                                                   item.type === "groups" &&
                                                   item.id === group.group_id
                                               )
                                                 ? "bold"
                                                 : "normal",
                                             }}
                                           >
                                             <CheckboxInput
                                               type="checkbox"
                                               checked={selectedItems.some(
                                                 (item) =>
                                                   item.type === "groups" &&
                                                   item.id === group.group_id
                                               )}
                                               onChange={() =>
                                                 handleItemClick(
                                                   "groups",
                                                   group.group_id,
                                                   formik
                                                 )
                                               }
                                             />{" "}
                                             {group.group_name}
                                           </td>
                                         </tr>
                                       </tbody>
                                     ))}
                                   </table>
                                 </div>
                               )}
                             </div>
                             <div
                               className="check_label  "
                               
                             >
                               {checkedItems.child2_2 && checkedItems.child1 && (
                                 <div className="check_label left_spac" style={{
                                   minWidth: "100px",
                                   width: "300px",
                                   height: "300px",
                                   overflowY: "auto",
                                   border: "1px solid lightgray",
                                   padding: "10px",
                                   borderRadius: "10px",
                                   marginBottom: "10px",
                                 }}>
                                   <table className="group-table" value="unit_id">
                                     <thead>
                                       <tr>
                                         <th>Unit Name</th>
                                       </tr>
                                     </thead>
                                     {units?.map((unit) => (
                                       <tbody key={unit.unit_id}>
                                         <tr>
                                           <td
                                             onClick={() =>
                                               handleItemClick(
                                                 "units",
                                                 unit.unit_id,
                                                 formik
                                               )
                                             }
                                             style={{
                                               cursor: "pointer",
                                               fontWeight: selectedItems.some(
                                                 (item) =>
                                                   item.type === "units" &&
                                                   item.id === unit.unit_id
                                               )
                                                 ? "bold"
                                                 : "normal",
                                             }}
                                           >
                                             <CheckboxInput
                                               type="checkbox"
                                               checked={selectedItems.some(
                                                 (item) =>
                                                   item.type === "units" &&
                                                   item.id === unit.unit_id
                                               )}
                                               onChange={() =>
                                                 handleItemClick(
                                                   "units",
                                                   unit.unit_id,
                                                   formik
                                                 )
                                               }
                                             />{" "}
                                             {unit.unit_name}
                                           </td>
                                         </tr>
                                       </tbody>
                                     ))}
                                   </table>
                                 </div>
                               )}
                             </div>
                           </div>
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child3"
                               checked={checkedItems.child3 && checkedItems.child1}
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child3"
                               id="7"
                               value="Select equipment type"
                             >
                               Selection of equipment type: 7
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_hig_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child4"
                               checked={
                                 checkedItems.child4 &&
                                 checkedItems.child1 &&
                                 checkedItems.child3
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child4"
                               style={{ color: "#FF0000" }}
                               id="8"
                               value="Choose between contains / does not contain"
                             >
                               Choose between contains / does not contain 8
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_hig_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child5"
                               checked={
                                 checkedItems.child5 &&
                                 checkedItems.child1 &&
                                 checkedItems.child3
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child5"
                               id="9"
                               value="What type of equipment"
                             >
                               What type of equipment 9
                             </CheckboxLabel>
                           </div>
                         </>
                       )}
                     </div>
                     <div className="check_label  left_hig_spac ">
                       {checkedItems.child3 &&
                         checkedItems.child5 &&
                         checkedItems.child1 && (
                           <div
                             className="check_label left_spac"
                             style={{
                               minWidth: "100px",
                               width: "300px",
                               height: "300px",
                               overflowY: "auto",
                               border: "1px solid lightgray",
                               padding: "10px",
                               borderRadius: "10px",
                               marginBottom: "10px",
                             }}
                           >
                             <table className="group-table" value="product_ids">
                               <thead>
                                 <tr>
                                   <th>Equipment Name</th>
                                 </tr>
                               </thead>
                               {products?.map((product) => (
                                 <tbody key={product.product_id}>
                                   <tr>
                                     <td
                                       onClick={() =>
                                         handleItemClick(
                                           "products",
                                           product.product_id,
                                           formik
                                         )
                                       }
                                       style={{
                                         cursor: "pointer",
                                         fontWeight: selectedItems.some(
                                           (item) =>
                                             item.type === "products" &&
                                             item.id === product.product_id
                                         )
                                           ? "bold"
                                           : "normal",
                                       }}
                                     >
                                       <CheckboxInput
                                         type="checkbox"
                                         checked={selectedItems.some(
                                           (item) =>
                                             item.type === "products" &&
                                             item.id === product.product_id
                                         )}
                                         onChange={() =>
                                           handleItemClick(
                                             "products",
                                             product.product_id,
                                             formik
                                           )
                                         }
                                       />{" "}
                                       {product.name}
                                     </td>
                                   </tr>
                                 </tbody>
                               ))}
                             </table>
                           </div>
                         )}
                     </div>
                   </StyleUserForm>
                   {/* ------------------------------------------- */}
                   <StyleUserForm>
                     <div className="first_head_div left_marg">
                       <div className="trans_equipment">
                         <div className="check_label">
                           <CheckboxInput
                             type="checkbox"
                             id="child6"
                             checked={checkedItems.child6 || false}
                             onChange={(e) => handleCheckboxChange(e, formik)}
                           />
                           <CheckboxLabel
                             htmlFor="child6"
                             value=" Transfer equipment (with the need for third-party
                             approval)"
                             id="15"
                           >
                             Transfer equipment (with the need for third-party
                             approval) 15
                           </CheckboxLabel>
                         </div>
                       </div>
                       {checkedItems.child6 && (
                         <>
                           <div className="check_label left_marg">
                             <CheckboxInput
                               type="checkbox"
                               id="child7"
                               checked={
                                 (checkedItems.child6 && checkedItems.child7) ||
                                 false
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel htmlFor="child7" id="4" value="Who is the third party">
                               Who is the third party 17
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child8"
                               checked={
                                 checkedItems.child7 &&
                                 checkedItems.child6 &&
                                 checkedItems.child8
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel htmlFor="child8" id="34">
                               Allow multiple users as third parties ---( assign_to)
                               18id="34"
                             </CheckboxLabel>
                           </div>
                           {checkedItems.child7 &&
                             checkedItems.child6 &&
                             checkedItems.child8 && (
                               <div
                                 className="check_label left_spac"
                                 style={{
                                   minWidth: "100px",
                                   width: "300px",
                                   height: "300px",
                                   overflowY: "auto",
                                   border: "1px solid lightgray",
                                   padding: "10px",
                                   borderRadius: "10px",
                                 }}
                               >
                                 <table className="group-table">
                                   <thead>
                                     <tr>
                                       <th>Users Name</th>
                                     </tr>
                                   </thead>
                                   {users?.map((user) => (
                                     <tbody key={user.id}>
                                       <tr>
                                         <td
                                           onClick={() =>
                                             handleItemClick("users", user.id)
                                           }
                                           style={{
                                             cursor: "pointer",
                                             fontWeight: selectedItems.some(
                                               (item) =>
                                                 item.type === "users" &&
                                                 item.id === user.id
                                             )
                                               ? "bold"
                                               : "normal",
                                           }}
                                         >
                                           <CheckboxInput
                                             type="checkbox"
                                             checked={selectedItems.some(
                                               (item) =>
                                                 item.type === "users" &&
                                                 item.id === user.id
                                             )}
                                             onChange={() =>
                                               handleItemClick("users", user.id)
                                             }
                                           />{" "}
                                           {user.username}
                                         </td>
                                       </tr>
                                     </tbody>
                                   ))}
                                 </table>
                               </div>
                             )}
     
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child9"
                               checked={
                                 checkedItems.child9 &&
                                 checkedItems.child6 &&
                                 checkedItems.child7
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel htmlFor="child9" id="42">
                               You can choose whether you need approval from all
                               third parties / just one 19id="42
                             </CheckboxLabel>
                           </div>
     
                           <div className="dual_checkbox">
                             <div className="check_label left_spac">
                               <CheckboxInput
                                 type="checkbox"
                                 id="child10"
                                 checked={
                                   checkedItems.child10 &&
                                   checkedItems.child9 &&
                                   checkedItems.child7
                                 }
                                 onChange={(e) => handleCheckboxChange(e, formik)}
                               />
                               <CheckboxLabel htmlFor="child10" id="10">
                                 One Time 20id="10"
                               </CheckboxLabel>
                             </div>
                             <div className="check_label left_spac">
                               <CheckboxInput
                                 type="checkbox"
                                 id="child11"
                                 checked={
                                   checkedItems.child11 &&
                                   checkedItems.child9 &&
                                   checkedItems.child7
                                 }
                                 onChange={(e) => handleCheckboxChange(e, formik)}
                               />
                               <CheckboxLabel htmlFor="child11" id="11">
                                 Every time 21id="11"
                               </CheckboxLabel>
                             </div>
                           </div>
     
                           <div className="check_label left_marg">
                             <CheckboxInput
                               type="checkbox"
                               id="child12"
                               checked={checkedItems.child12 && checkedItems.child6}
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child12"
                               id="17"
                               value="Select equipment type"
                             >
                               Selection of equipment type 22
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child13"
                               checked={
                                 checkedItems.child13 &&
                                 checkedItems.child12 &&
                                 checkedItems.child6
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child13"
                               style={{ color: "#FF0000" }}
                               id="13"
                             >
                               Choose between contains / does not contain 23id="13"
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child14"
                               checked={
                                 checkedItems.child14 &&
                                 checkedItems.child12 &&
                                 checkedItems.child6
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child14"
                               id="54"
                               value="What type of equipment"
                             >
                               What type of equipment 24id="54"
                             </CheckboxLabel>
                           </div>
                           {checkedItems.child14 &&
                             checkedItems.child12 &&
                             checkedItems.child6 && (
                               <div className="check_label left_spac">
                                 <table className="group-table" value="product_ids">
                                   <thead>
                                     <tr>
                                       <th>Product Name</th>
                                     </tr>
                                   </thead>
                                   {products?.map((product) => (
                                     <tbody key={product.product_id}>
                                       <tr>
                                         <td
                                           onClick={() =>
                                             handleItemClick(
                                               "products",
                                               product.product_id,
                                               formik
                                             )
                                           }
                                           style={{
                                             cursor: "pointer",
                                             fontWeight: selectedItems.some(
                                               (item) =>
                                                 item.type === "products" &&
                                                 item.id === product.product_id
                                             )
                                               ? "bold"
                                               : "normal",
                                           }}
                                         >
                                           <CheckboxInput type="checkbox" />
                                           {""}
                                           {product.name}     
                                         </td>
                                       </tr>
                                     </tbody>
                                   ))}
                                 </table>
                               </div>
                             )}
                         </>
                       )}
                     </div>
                   </StyleUserForm>
                   {/* --------------------------------------------- */}
                   <StyleUserForm>
                     <div className="first_head_div left_marg">
                       <div className="trans_equipment">
                         <div className="check_label ">
                           <CheckboxInput type="checkbox" id="child15" />
                           <CheckboxLabel htmlFor="child15" id="20" value="Warehouse Management">
                             Warehouse Management 10
                           </CheckboxLabel>
                         </div>
                       </div>
                     </div>
                     <div className="first_head_div left_marg">
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child16"
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child16" id="21" value="Change equipment name">
                           Change equipment name 11
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child17"
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child17" id="22" value="Change category to equipment">
                           Change category to equipment 12
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child18"
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
     
                         <CheckboxLabel htmlFor="child18" id="23" value="Add equipment to the warehouse">
                           Add equipment to the warehouse 13
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child19" />
                         <CheckboxLabel htmlFor="child19" id="55">
                           Transfer equipment from the warehouse to the user 25id="55"
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child20" />
     
                         <CheckboxLabel htmlFor="child20" id="56">
                           Transfer equipment from warehouse to sub warehouse (in
                           unit)26id="56"
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child21" />
                         <CheckboxLabel htmlFor="child21" id="52">
                           Receive equipment from user to warehouse 27id="52"
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput type="checkbox" id="child22" />
                         <CheckboxLabel htmlFor="child22" id="24" value="Delete a product">
                           Delete a product 28
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_hig_spac">
                         <CheckboxInput type="checkbox" id="child23" />
                         <CheckboxLabel htmlFor="child23" id="25" value="With transfer history">
                           With transfer history 29
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_hig_spac">
                         <CheckboxInput type="checkbox" id="child24" />
                         <CheckboxLabel htmlFor="child24" id="26" value="Without transfer history">
                           Without transfer history 30
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_hig_spac">
                         <CheckboxInput type="checkbox" id="child25" />
                         <CheckboxLabel
                           htmlFor="child25"
                           style={{ color: "#FF0000" }}
                           id="57"
                         >
                           Give specific permissions 31id="57"
                         </CheckboxLabel>
                       </div>
                     </div>
                   </StyleUserForm>
                   {/* ------------------------------------------------ */}
                   <StyleUserForm>
                     <div className="first_head_div left_marg">
                       <div className="trans_equipment">
                         <div className="check_label">
                           <CheckboxInput
                             type="checkbox"
                             id="child26"
                             checked={checkedItems.child26 || false}
                             onChange={(e) => handleCheckboxChange(e, formik)}
                           />
                           <CheckboxLabel htmlFor="child26" id="37">
                             Request equipment (without the need for third-party
                             approval) 32id="37"
                           </CheckboxLabel>
                         </div>
                       </div>
                       {checkedItems.child26 && (
                         <>
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child27"
                               checked={checkedItems.child27 && checkedItems.child26}
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel htmlFor="child27" id="33">
                               Choice of place: within the unit / within the selected
                               group (you can check several boxes) 33
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_marg">
                             <CheckboxInput
                               type="checkbox"
                               id="child28"
                               checked={checkedItems.child28 && checkedItems.child26}
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel htmlFor="child28" id="18" value="Selection of equipment type">
                               Selection of equipment type: 34
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child29"
                               checked={checkedItems.child29 && checkedItems.child28}
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel
                               htmlFor="child29"
                               style={{ color: "#FF0000" }}
                               id="35"
                             >
                               Choose between contains / does not contain 35
                             </CheckboxLabel>
                           </div>
     
                           <div className="check_label left_spac">
                             <CheckboxInput
                               type="checkbox"
                               id="child30"
                               checked={
                                 checkedItems.child30 &&
                                 checkedItems.child28 &&
                                 checkedItems.child28
                               }
                               onChange={(e) => handleCheckboxChange(e, formik)}
                             />
                             <CheckboxLabel htmlFor="child30" id="36">
                               What type of equipment 36
                             </CheckboxLabel>
                           </div>
                           {checkedItems.child26 &&
                             checkedItems.child28 &&
                             checkedItems.child30 && (
                               <div className="check_label left_spac">
                                 <table className="group-table" value="product_ids">
                                   <thead>
                                     <tr>
                                       <th>Product Name</th>
                                     </tr>
                                   </thead>
                                   {products?.map((product) => (
                                     <tbody key={product.product_id}>
                                       <tr>
                                         <td
                                           onClick={() =>
                                             handleItemClick(
                                               "products",
                                               product.product_id,
                                               formik
                                             )
                                           }
                                           style={{
                                             cursor: "pointer",
                                             fontWeight: selectedItems.some(
                                               (item) =>
                                                 item.type === "products" &&
                                                 item.id === product.product_id
                                             )
                                               ? "bold"
                                               : "normal",
                                           }}
                                         >
                                           <CheckboxInput type="checkbox" />
                                           {""}
                                           {product.name}
                                         </td>
                                       </tr>
                                     </tbody>
                                   ))}
                                 </table>
                               </div>
                             )}
                         </>
                       )}
                     </div>
                   </StyleUserForm>
                   {/* ------------------------------------------------ */}
                   <StyleUserForm>
                     <div className="first_head_div left_marg">
                       <div className="trans_equipment">
                         <div className="check_label">
                           <CheckboxInput
                             type="checkbox"
                             id="child31"
                             checked={checkedItems.child31}
                             onChange={(e) => handleCheckboxChange(e, formik)}
                           />
                           <CheckboxLabel
                             htmlFor="child31"
                             id="32"
                             value="Request equipment that requires third-party approval"
                           >
                             Request equipment that requires third-party approval: 37
                           </CheckboxLabel>
                         </div>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child181"
                           checked={
                             checkedItems.child31 &&
                             checkedItems.child181
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child181" id="38">
                           Who is the third party{" "}
                           <span style={{ color: "#FF0000" }}>
                             (user search opens) 38
                           </span>
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput
                           type="checkbox"
                           id="child191"
                           checked={
                             checkedItems.child31 &&
                             checkedItems.child191
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child191" id="39">
                           Allow multiple users as third parties 39
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput
                           type="checkbox"
                           id="child32"
                           checked={
                             checkedItems.child31 &&
                             checkedItems.child32
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child32" id="40">
                           You can choose whether you need approval from all third
                           parties / just one 40
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child33"
                           checked={
                             checkedItems.child31 &&
                             checkedItems.child33
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child33" id="41">
                           Who can ask 41
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child34"
                           checked={
                             checkedItems.child31 &&
                             checkedItems.child34
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child34" id="19" value="Selection of equipment type">
                           Selection of equipment type 42
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput type="checkbox" id="child35"
                          checked={
                           checkedItems.child31 &&
                           checkedItems.child35
                         }
                         onChange={(e) => handleCheckboxChange(e, formik)}
     
                         />
                         <CheckboxLabel htmlFor="child35" id="43">
                           Choose between contains / does not contain 43
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput
                           type="checkbox"
                           id="child36"
                           checked={
                             checkedItems.child31 &&
                             checkedItems.child36
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child36" id="44">
                           What type of equipment 44
                         </CheckboxLabel>
                       </div>
                       {checkedItems.child31 &&
                         checkedItems.child34 &&
                         checkedItems.child36 && (
                           <div className="check_label left_spac">
                             <table className="group-table" value="product_ids">
                               <thead>
                                 <tr>
                                   <th>Product Name</th>
                                 </tr>
                               </thead>
                               {products?.map((product) => (
                                 <tbody key={product.product_id}>
                                   <tr>
                                     <td
                                       onClick={() =>
                                         handleItemClick(
                                           "products",
                                           product.product_id,
                                           formik
                                         )
                                       }
                                       style={{
                                         cursor: "pointer",
                                         fontWeight: selectedItems.some(
                                           (item) =>
                                             item.type === "products" &&
                                             item.id === product.product_id
                                         )
                                           ? "bold"
                                           : "normal",
                                       }}
                                     >
                                       <CheckboxInput type="checkbox" />
                                       {""}
                                       {product.name}
                                     </td>
                                   </tr>
                                 </tbody>
                               ))}
                             </table>
                           </div>
                         )}
                     </div>
                   </StyleUserForm>
                   {/* -------------------------------------------- */}
                   <StyleUserForm>
                     {" "}
                     <div className="first_head_div left_marg">
                       <div className="trans_equipment">
                         <div className="check_label">
                           <CheckboxInput type="checkbox" id="child37"
                           checked={
                             checkedItems.child37 
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
     
                           />
                           <CheckboxLabel htmlFor="child37" id="45">
                             To grant permissions 45
                           </CheckboxLabel>
                         </div>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child38"
                         checked={
                           checkedItems.child37 && 
                           checkedItems.child38 
                         }
                         onChange={(e) => handleCheckboxChange(e, formik)}
     
                         />
                         <CheckboxLabel
                           htmlFor="child38"
                           style={{ color: "#FF0000" }}
                           id="46"
                         >
                           Give permissions that he has to other users 46
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child39"
                         checked={
                           checkedItems.child37 && 
                           checkedItems.child39 
                         }
                         onChange={(e) => handleCheckboxChange(e, formik)}
     
                         />
                         <CheckboxLabel
                           htmlFor="child39"
                           style={{ color: "#FF0000" }}
                           id="47"
                         >
                           Give all permissions 47
                         </CheckboxLabel>
                       </div>
                     </div>
                     <div className="first_head_div left_marg">
                       <div className="trans_equipment">
                         <div className="check_label">
                           <CheckboxInput type="checkbox" id="child40" 
                           checked={
                             checkedItems.child40 
                           }
                           onChange={(e) => handleCheckboxChange(e, formik)}
     
                           />
                           <CheckboxLabel htmlFor="child40" id="48">
                             Admin board 48
                           </CheckboxLabel>
                         </div>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child41"
                           checked={checkedItems.child41}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
     
                         <CheckboxLabel htmlFor="child41" id="49">
                           Add groups 49
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput
                           type="checkbox"
                           id="child42"
                           checked={checkedItems.child41 && checkedItems.child42}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child42" id="14">
                           Creating new groups in the unit 14
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput type="checkbox" id="child43" 
                           checked={checkedItems.child41 && checkedItems.child43}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         
                         />
     
                         <CheckboxLabel
                           htmlFor="child43"
                           style={{ color: "#FF0000" }}
                           id="50"
                          
                         >
                           Request to annex existing groups from other units 50
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput
                           type="checkbox"
                           id="child44"
                           checked={checkedItems.child44}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child44" id="51">
                           Move groups 51
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput
                           type="checkbox"
                           id="child45"
                           checked={checkedItems.child44 && checkedItems.child45}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         />
     
                         <CheckboxLabel htmlFor="child45" id="16">
                           Move groups within the unit 16
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_hig_spac">
                         <CheckboxInput type="checkbox" id="child46" 
                           checked={checkedItems.child44 && checkedItems.child45 && checkedItems.child46}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         
                         />
                         <CheckboxLabel htmlFor="child46" id="27" value="Access to a unit profile">
                           Access to a unit profile 52
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_hig_spac ">
                         <CheckboxInput type="checkbox" id="child47" 
                           checked={checkedItems.child44 && checkedItems.child45 && checkedItems.child47}
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         
                         />
     
                         <CheckboxLabel htmlFor="child47" id="53">
                           Unit profile control 53
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_spac">
                         <CheckboxInput type="checkbox" id="child48" 
                           checked={checkedItems.child44 && checkedItems.child48 }
                           onChange={(e) => handleCheckboxChange(e, formik)}
                         
                         />
                         <CheckboxLabel htmlFor="child48" id="28" value="Send a request to another unit to transfer a group to it">
                           Send a request to another unit to transfer a group to it
                           54
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child49" 
                         checked={checkedItems.child49 }
                         onChange={(e) => handleCheckboxChange(e, formik)}
                         />
     
                         <CheckboxLabel htmlFor="child49" id="29" value="Invite new users to the unit">
                           Invite new users to the unit 55
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child50" 
                          checked={checkedItems.child50 }
                          onChange={(e) => handleCheckboxChange(e, formik)}
                         />
     
                         <CheckboxLabel htmlFor="child50" id="30" value="Approve requests from users to join the unit">
                           Approve requests from users to join the unit 56
                         </CheckboxLabel>
                       </div>
     
                       <div className="check_label left_marg">
                         <CheckboxInput type="checkbox" id="child51"
                          checked={checkedItems.child51 }
                          onChange={(e) => handleCheckboxChange(e, formik)}
                         />
                         <CheckboxLabel htmlFor="child51" id="31" value="Open a sub-warehouse">
                           Open a sub-warehouse 57
                         </CheckboxLabel>
                       </div>
                     </div>
                   </StyleUserForm>
                 </>
      <hr />
                  <div className="save_btn">
                    <BlueBackgroundButton
                      type="button" // Use "button" to prevent auto-submit
                      onClick={() => {
                        formik.validateForm().then((errors) => {
                          const hasPermissions =
                            formik.values.permission_ids.length > 0;
      
                          if (Object.keys(errors).length > 0 || !hasPermissions) {
                            formik.setTouched(
                              Object.keys(errors).reduce((acc, key) => {
                                acc[key] = true;
                                return acc;
                              }, {})
                            );
      
                            // Show error if no permissions are selected
                            if (!hasPermissions) {
                              toast.error("Please select at least one permission.");
                            }
                          } else {
                            formik.submitForm();
                          }
                        });
                      }}
                      className="submit_button"
                    >
                      Submit
                    </BlueBackgroundButton>
                  </div>
                </Form>
              )}
            </Formik>
    </Root>
  );
};
const Root = styled.section`
  .Share_Link .input_select {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      cursor: pointer;
    }
    input {
      margin: 10px;
      border: 1.5px solid #dfeaf2;
      background-color: #fff;
    }
    input::placeholder {
      font-size: 13px !important;
      font-weight: 400;
      line-height: 16px;
      color: #4b4949;
    }
  }
  .profile_input {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    flex-wrap: wrap;

    .input_select {
      display: flex;
      flex: 1;
      flex-direction: column;
      .input_select_sub_div,
      .input_select_name,
      .input_select_desc,
      .input_select_unit,
      .input_select_assign,
      .input_select_group {
        display: flex;
        flex-direction: column;
        padding: 10px;
        label {
          padding: 0px 10px 10px;
          font-size: 14px;
        }
        input,
        select {
          border: 1.5px solid #dfeaf2;
          background-color: #fff;
        }
        input::placeholder,
        select::placeholder {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 16px;
          color: #4b4949;
        }
      }
    }
  }
  .search {
    margin: 20px;
    display: flex;
    flex-direction: column;
    p {
      margin: 0px 0px 10px;
    }
  }
`;
