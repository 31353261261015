import React from "react";
import { AiFillHome } from "react-icons/ai";
import { BiSolidUserDetail } from "react-icons/bi";
import { BsTags } from "react-icons/bs";
import { FaEnvelopeOpenText, FaUser, FaUserPlus } from "react-icons/fa";
import { GiFamilyHouse } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { IoIosPaper } from "react-icons/io";
import { IoBagAddOutline } from "react-icons/io5";
import { MdGroups, MdManageAccounts, MdOutlineWarehouse } from "react-icons/md";
import { PiTreeStructureFill } from "react-icons/pi";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiFunctionAddLine,
  RiFunctionLine,
  RiHistoryFill,
  RiUserAddLine,
} from "react-icons/ri";

export const SidebarData = () => {
  return [
    {
      title: "Dashboard",
      path: "/",
      icon: <AiFillHome />,
      id: [2],
    },
    {
      title: "Warehouse Manage",
      icon: <GiFamilyHouse />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: [23 , 20 , 21 , 22 , 9 ,7],

      subNav: [
        {
          title: "Add Equipment",
          path: "/warehouse_manage/add_product",
          icon: <IoBagAddOutline />,
          id: [2, 20, 15, 23],
        },
        {
          title: "Add Category",
          path: "/warehouse_manage/add_category",
          icon: <RiFunctionAddLine />,
          id: [20],
        },
        {
          title: "Category",
          path: "/warehouse_manage/category",
          icon: <RiFunctionLine />,
          id: [20],
        },
        {
          title: "Equipment",
          path: "/warehouse_manage/product",
          icon: <BsTags />,
          id: [23 , 20 , 21 , 22 , 9 ,7],
        },

        {
          title: "Create Warehouse",
          path: "/warehouse_manage/create_warehouse",
          icon: <MdOutlineWarehouse />,
          id: [20],
        },
      ],
    },
    {
      title: "Group Create",
      icon: <MdGroups />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      subNav: [
        {
          title: "Group Create",
          path: "/group_create",
          icon: <MdGroups />,
          id: [14 , 49 , 48 , 51 , 28 , 16],
        },
        {
          title: "All Group",
          path: "/all_group",
          icon: <MdGroups />,
          id: [14],
        },
        {
          title: "Request",
          path: "/request",
          icon: <RiUserAddLine />,
          id: [50],
        },
        {
          title: "History",
          path: "/history",
          icon: <RiHistoryFill />,
          id: [25],
        },
      ],
    },
    {
      title: "Users",
      icon: <FaUserPlus />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: [29],

      subNav: [
        {
          title: "Add Users",
          path: "/add_users",
          icon: <FaUserPlus />,
          id: [67,29],
        },
        {
          title: "Users List",
          path: "/users_list",
          icon: <FaUser />,
          cName: "sub-nav",
          id: [29],
        },
        {
          title: "Invited List",
          path: "/invited_list",
          icon: <BiSolidUserDetail />,
          cName: "sub-nav",
          id: [29],
        },
      ],
    },

    {
      title: "Request Equipment",
      icon: <FaEnvelopeOpenText />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,

      subNav: [
        {
          title: "Request Equipment",
          path: "/request_equipment",
          icon: <FaEnvelopeOpenText />,
          id: [4, 34, 37, 36, 39]
        },
        {
          title: "Transfer Approval",
          path: "/transfer_approval",
          icon: <FaUserPlus />,
          // id: 54,
          id: [4 , 34, 9],
        },
        {
          title: "Reconcile Approval",
          path: "/reconcile_approval",
          icon: <FaUser />,
          cName: "sub-nav",
          // id: 29,
          id: [2],
        },

      ],
    },
    {
      title: "Unit",
      icon: <ImProfile />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,

      subNav: [
        {
          title: "Unit Profile",
          path: "/unite_profile",
          icon: <ImProfile />,
          id: [27],
        },
        {
          title: "Request Unit",
          path: "/req_unit",
          icon: <RiUserAddLine />,
          id: [50 , 27 , 30],
        },
      ],
    },
    {
      title: "Manage Role",
      icon: <MdManageAccounts />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: [2],

      subNav: [
        {
          title: "Add Staff",
          path: "/manage_role",
          icon: <MdManageAccounts />,
          id: [2],
        },
        {
          title: "Invited Staff",
          path: "/invited_users",
          icon: <IoIosPaper />,
          cName: "sub-nav",
          id: [2],
        },
      ],
    },
    {
      title: "Hierarchy",
      path: "/hierarchy",
      icon: <PiTreeStructureFill />,
      id: [2],
    },
    
  ];
};
