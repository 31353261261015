import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  BlackBorderButton,
  BlueBorderContent,
  TabButton,
} from "../Global/GlobalButtons";
import GlobalTable from "../Global/GlobalTable";
import GlobalSearch from "../Global/GlobalSearch";
import {
  useAddNewUserListRequestSent,
  useGroupRequestSent,
} from "../../utils/useAppUtils";
import { Pagination } from "../Global/Table2";

const userColumns = [
  { header: "Sr.No", accessor: "srNo" },
  { header: "Join Date & Time", accessor: "JoinDateTime" },
  { header: "User Position", accessor: "Position" },
  { header: "User Name", accessor: "userName" },
  { header: "User Requested for this Unit", accessor: "CurrentUnit" },
  { header: "User Last Unit Name", accessor: "PreviousUnit" },
  { header: "Status", accessor: "Statuss" },
  { header: "Left Date & Time", accessor: "LeftDateTime" },
];
const groupColumns = [
  { header: "Sr.No", accessor: "srNo" },
  { header: "Join Date & Time", accessor: "JoinDateTime" },
  { header: "Current Unit", accessor: "CurrentUnit" },
  { header: "Group Strength", accessor: "GroupStrength" },
  { header: "Left Date & Time", accessor: "LeftDateTime" },
  { header: "Last Unit", accessor: "LastUnit" },
  { header: "Status", accessor: "GroupManagerName" },
  { header: "Last Reporting Manager", accessor: "LastReportingManager" },
];

export const History = () => {
  const dataget = useAddNewUserListRequestSent();
  const datagetGroupHistory = useGroupRequestSent();
  const [userData, setUserData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [groupData, setGroupData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (page) => setCurrentPage(page);

  const paginatedData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (selectedTab === "tab2" && datagetGroupHistory) {
      setGroupData(
        datagetGroupHistory.map((item, index) => {
          const formattedDate = new Date(item.create_date)
            .toISOString()
            .split("T")[0];
          const lastunit = new Date(item.update_date)
            .toISOString()
            .split("T")[0];

          return {
            srNo: index + 1,
            JoinDateTime: formattedDate,
            GroupManagerName: item.status,
            CurrentUnit: (
              <BlackBorderButton>{item.unit_name}</BlackBorderButton>
            ),
            GroupStrength: item.total_users_in_group || 0,
            LeftDateTime: lastunit || "N/A",
            LastUnit: (
              <BlackBorderButton>{item.lastunit_name}</BlackBorderButton>
            ),
            LastReportingManager: item.requestBy_role,
          };
        })
      );
    }
  }, [selectedTab, datagetGroupHistory]);

  useEffect(() => {
    if (selectedTab === "tab1" && dataget) {
      console.log("datatatatatatta", dataget);
      setUserData(
        dataget.filter((item) =>
          item.unit_name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
        ).map((item, index) => {
          const formattedDate = new Date(item.create_date)
            .toISOString()
            .split("T")[0];

          return {
            srNo: index + 1,
            JoinDateTime: formattedDate,
            Position: item.user_role,
            userName: item.user_name,
            CurrentUnit: (
              <BlackBorderButton>{item.unit_name}</BlackBorderButton>
            ),
            PreviousUnit: (
              <BlueBorderContent>{item.lastunit_name}</BlueBorderContent>
            ),
            LeftDateTime: formattedDate || "N/A",
            Statuss: item.status || "no status",
          };
        })
      );
    }
  }, [selectedTab, dataget]);



  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(1); 
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredUserData = userData.filter(
    (item) =>
      item.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.Position.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item.CurrentUnit.props.children || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  const filteredGroupData = groupData.filter(
    (item) =>
      item.GroupManagerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item.CurrentUnit.props.children || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      (item.LastUnit.props.children || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  const displayedData =
  selectedTab === "tab1"
    ? paginatedData(filteredUserData)
    : paginatedData(filteredGroupData);

    const totalPages = Math.ceil(
      (selectedTab === "tab1"
        ? filteredUserData
        : filteredGroupData
      ).length / itemsPerPage
    );
  return (
    <Root>
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          User History
        </TabButton>

        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Group History
        </TabButton>
      </div>

      <div style={{ padding: "0px 20px" }}>
        <GlobalSearch search="Search for Something"  value={searchQuery} onSearch={handleSearch} />
      </div>

      <div className="table">
        {selectedTab === "tab1" ? (
          <GlobalTable columns={userColumns} data={displayedData} />
        ) : (
          <GlobalTable columns={groupColumns} data={displayedData} />
        )}
      </div>
      <div className="pagination">
  {Array.from({ length: totalPages }, (_, index) => (
    <button
      key={index + 1}
      className={index + 1 === currentPage ? "active" : ""}
      onClick={() => handlePageChange(index + 1)}
    >
      {index + 1}
    </button>
  ))}
</div>

    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  .pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background: #fff;
  cursor: pointer;
}

.pagination button.active {
  background: #007bff;
  color: #fff;
  font-weight: bold;
}


  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px 20px 0px;
  }

  .table {
    margin: 10px 0px;
    height: 100%;
    overflow-x: auto;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
`;
