import React from "react";
import styled from "styled-components";
import { IoSearchSharp } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";

const GlobalSearch = ({ value, onChange,search }) => {
  return (
    <Root>
      <div className="search_div">
        <div className="icon_div">
          <IoSearchSharp />
        </div>

        <div className="icon_content">
          <input type="text" placeholder={search}  value={value}
    onChange={onChange}/>
        </div>
      </div>
    </Root>
  );
};

export default GlobalSearch;

const Root = styled.section`
  .search_div {
    border: 1px solid #e5e1e1;
    background-color: #ffffff;
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 20px;
    border-radius: 35px;
    .icon_div {
      width: 3%;
      svg {
        color: #718ebf;
        width: 25px;
        height: 25px;
      }
    }
    .icon_content {
      width: 95%;
      cursor: pointer;
      input[type="text"] {
        height: 45px;
        width: 100%;
        border: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
      }
      input[type="text"]::placeholder {
        color: #8ba3cb;
      }
    }
  }
  @media (max-width: 768px) {
    .search_div {
      height: 40px;
      gap: 10px;
      padding: 0 15px;
      border-radius: 20px;
    }
    .search_div .icon_div svg {   
      width: 20px;
      height: 20px;
    }
    .search_div .icon_content input[type="text"] {
      height: 24px;
      font-size: 14px;
    }
  }
`;

export const PermissionSearch = ({ search }) => {
  return (
    <PermRoot>
      <div className="search_div">
        <div className="icon_div">
          <IoSearchSharp />
        </div>
        <div className="icon_content">
          <input type="text" placeholder={search} />
        </div>
      </div>
    </PermRoot>
  );
};

const PermRoot = styled.section`
  .search_div {
    border: 1px solid #e5e1e1;
    background-color: #efefef;
    height: 35px;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 20px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #718ebf;
        width: 25px;
        height: 25px;
      }
    }
    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        width: 100%;
        border: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
        background-color: #efefef;
      }
      input[type="text"]::placeholder {
        color: #8ba3cb;
      }
    }
  }
`;
export const GlobalSearchMini = ({ value, onChange, searching }) => {
  return (
    <RootMini>
      <div className="search_div">
        <div className="icon_div">
          <CiSearch />
        </div>
        <div className="icon_content">
          <input type="text"
            placeholder={searching}
            value={value}
            onChange={onChange} />
        </div>
        <div></div>
      </div>
    </RootMini>
  );
};
const RootMini = styled.section`
  .search_div {
    border: 1px transparent;
    background-color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px 10px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #0000007a;
        width: 20px;
        height: 20px;
      }
    }
    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        height: 20px;
        width: 100%;
        border: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
      }
      input[type="text"]::placeholder {
        color: #0000007a;
        font-size: 14px;
      }
    }
  }
`;
