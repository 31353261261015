import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAppUtils from "../../utils/useAppUtils";
import axios from "axios";
import TreeChart from "./OrgChartTree";
import { Pagination } from "../Global/Table2";

const Hierarchy = () => {
  const { navigate } = useAppUtils();
  const [data, setData] = useState([]); // State to store the fetched data
  const [filteredData, setFilteredData] = useState([]); // State to store filtered data
  const [filter, setFilter] = useState(""); // State to store selected filter (inside/outside)
  const token = localStorage.getItem("token"); // Retrieve the token from localStorage
  const [activeButton, setActiveButton] = useState("Show All"); // Track the active filter button
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [itemsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:8000/api/ware/productlist",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        );

        if (response.data.status) {
          setData(response.data.data); // Store the fetched data
          setFilteredData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching warehouse data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleFilterChange = (filterType) => {
    setFilter(filterType);
    setActiveButton(filterType);
    applyFilters(filterType, searchQuery);
    setCurrentPage(1);

    if (filterType === "Inside-Warehouse") {
      const filtered = data.filter(
        (item) => item.location && item.location.toLowerCase() === "inside"
      );
      setFilteredData(filtered);
    } else if (filterType === "Outside-Warehouse") {
      const filtered = data.filter(
        (item) => item.location && item.location.toLowerCase() === "outside"
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    applyFilters(filter, query);
    setCurrentPage(1);
  };

  const applyFilters = (filterType, query) => {
    let filtered = data;

    if (filterType === "Inside-Warehouse") {
      filtered = filtered.filter(
        (item) => item.location && item.location.toLowerCase() === "inside"
      );
    } else if (filterType === "Outside-Warehouse") {
      filtered = filtered.filter(
        (item) => item.location && item.location.toLowerCase() === "outside"
      );
    }

    if (query) {
      filtered = filtered.filter(
        (item) => item.name && item.name.toLowerCase().includes(query)
      );
    }

    setFilteredData(filtered);
  };
  const Button = styled.button`
    min-width: 150px;
    padding: 6px 10px;
    background-color: ${(props) =>
      props.primary ? "red" : props.active ? "#4CAF50" : "#333"};
    color: white;
    border: none;
    border-radius: 4px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  `;
  const getLocationButtonColor = (location) => {
    if (location.toLowerCase() === "inside") {
      return "#4CAF50"; // Green for Inside
    } else if (location.toLowerCase() === "outside") {
      return "#F44336"; // Red for Outside
    }
    return "#333"; // Default
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Container>
      <Title>Warehouse Simulation</Title>
      <VisualizationContainer>
        <TreeChart />
      </VisualizationContainer>
      <Button onClick={() => navigate("/manage_role")}>Create Order</Button>
      <hr />
      <Button>Equipment signature</Button>
      <Button onClick={() => navigate("/warehouse_manage/product")}>
        Product reconciliation
      </Button>
      <Button onClick={() => navigate("/warehouse_manage/add_product")}>
        Adding new equipment
      </Button>

      <SearchBar
        type="text"
        placeholder="Search by product name..."
        value={searchQuery}
        onChange={handleSearchChange}
      />

      <Button
        onClick={() => handleFilterChange("Inside-Warehouse")}
        active={activeButton === "Inside-Warehouse"}
      >
        Inside-Warehouse
      </Button>
      <Button
        onClick={() => handleFilterChange("Outside-Warehouse")}
        active={activeButton === "Outside-Warehouse"}
      >
        Outside-Warehouse
      </Button>

      {/* Reset button to show all data */}
      <Button
        onClick={() => handleFilterChange("")}
        active={activeButton === "Show All"}
      >
        Show All
      </Button>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>Product Name</Th>
              <Th>Category Name</Th>
              <Th>Location</Th>
              <Th>Create Date</Th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <Td colSpan="5">No data available</Td>
              </tr>
            ) : (
              currentItems.map((item) => (
                <tr key={item.product_id}>
                  <Td>{item.product_id}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.category_name}</Td>
                  <Td>
                    <Button
                      style={{
                        backgroundColor: getLocationButtonColor(item.location),
                      }}
                    >
                      {item.location}
                    </Button>
                  </Td>{" "}
                  <Td>{new Date(item.create_date).toLocaleDateString()}</Td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalItems={filteredData.length}
        itemsPerPage={itemsPerPage}
        onPageChange={paginate}
      />
    </Container>
  );
};

export default Hierarchy;

const Container = styled.div`
  padding: 20px;
  position: relative;
  background-color: #fff;
`;

const Title = styled.h2`
  background: yellow;
  padding: 10px;
  text-align: center;
  width: 100%;
`;

const VisualizationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  border: 2px solid red;
  background: linear-gradient(to bottom, #dbf4fc, #8ef78e);
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid gray;
  border-radius: 4px;
`;

const TableContainer = styled.div`
  border: 2px solid red;
  margin: 10px 5px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  justify-content: center;
`;

const Th = styled.th`
  background: #f5f5f5;
  padding: 10px;
  align-items: center;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 5px;
  font-size: 14px;
  justify-content: center;
  border: 1px solid #ddd;
  text-align: center;
`;
