import React, { useState } from "react";
import { BlueBackgroundButton } from "../Global/GlobalButtons";
import styled from "styled-components";
import { GlobalSearchMini } from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";
import { CiImageOn } from "react-icons/ci";
import useAppUtils, { useCategoryList } from "../../utils/useAppUtils";

export const Category = () => {
  const {navigate } = useAppUtils();
  const [searchQuery, setSearchQuery] = useState(""); 
  const [data, setData] = useState([
      
  ]);

  const categories = useCategoryList(); 

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "", accessor: "imageicon" },
    { header: "Title", accessor: "title" },
    { header: "Products", accessor: "products" },
    { header: "Product Conditions", accessor: "productQuantity" },
  ];

  const handleSelectChange = (rowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].selected = !newData[rowIndex].selected;
      return newData;
    });
  };


  const filteredCategories = categories
  ? categories.filter((category) =>
      category.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  : [];
 

  const formattedData = filteredCategories?.map((category, index) => ({
    select: (
      <input
        type="checkbox"
        checked={data[index]?.selected || false}
        onChange={() => handleSelectChange(index)}
      />
    ),
    imageicon: category.image ? (
      <img
        src={`https://your-image-domain.com/${category.image}`}
        alt={category.name}
        style={{ width: "40px", height: "40px", borderRadius: "8px" }}
      />
    ) : (
      <CiImageOn />
    ),
    title: category.name,
    products: category.sku,
    productQuantity: category.description,
  }));
  const handleRowClick = (row) => {
    console.log("Row clicked:", row);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <Root className="home">
      <div style={{ textAlign: "end" }}>
        <BlueBackgroundButton onClick={()=>{navigate("/warehouse_manage/add_category")}}>Create Category</BlueBackgroundButton>
      </div>
      <div className="table_seprate">
        <GlobalSearchMini searching="Search by name...."  value={searchQuery}
          onChange={handleSearchChange}/>
        <GlobalTable columns={columns} data={formattedData}  onRowClick={handleRowClick} />
      </div>
    </Root>
  );
};
const Root = styled.section`
background-color: #fff;
border-radius: 50px;
padding: 20px;
  .table_seprate {
    margin: 20px 0px;
    padding: 0px 10px 10px;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 20px;
    svg{
      width: 26px;
      height: 26px;
    }
  }
`;
