import React from 'react'
import { Div } from '../group/AllGroup'

const ReconcileApproval = () => {
  return (
    <Div>
      <h1>No Data</h1>
    </Div>
  )
}

export default ReconcileApproval
