import React, { useState } from "react";
import { LoginButton, LoginContainer, Root } from "./WarehouseLogin";
import useAppUtils from "../../utils/useAppUtils";

const ForgetPass = () => {
  const [step, setStep] = useState(1); // 1: Email step, 2: OTP step
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
const {navigate} = useAppUtils();
  const handleEmailSubmit = (e) => {
    e.preventDefault();

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setError("");

    // Simulate an API call to send OTP to the email
    console.log(`Sending OTP to ${email}`);
    setStep(2);
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();

    if (otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP");
      return;
    }

    setError("");

    // Simulate OTP verification API
    console.log(`Verifying OTP: ${otp} for email: ${email}`);
    alert("OTP Verified! You can now reset your password.");
  };

  return (
    <Root>
      {step === 1 && (
        <LoginContainer>
          <h2>Forget Password</h2>
          <form onSubmit={handleEmailSubmit}>
            <div style={{ marginBottom: "16px" }}>
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: "100%", padding: "8px", marginTop: "8px" }}
                placeholder="Enter your email"
              />
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <LoginButton
              type="submit"
              style={{ padding: "10px 20px", marginTop: "16px" }}
            >
              Send OTP
            </LoginButton>
          </form>
        </LoginContainer>
      )}

      {step === 2 && (
        <LoginContainer>
        <form onSubmit={handleOtpSubmit}>
          <div style={{ marginBottom: "16px" }}>
            <label htmlFor="otp">Enter OTP:</label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              style={{ width: "100%", padding: "8px", marginTop: "8px" }}
              placeholder="Enter the OTP sent to your email"
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <LoginButton
            type="submit"
            onClick={()=>{navigate("/login")}}
            style={{ padding: "10px 20px", marginTop: "16px" }}
          >
            Verify OTP
          </LoginButton>
        </form>
        </LoginContainer>
      )}
    </Root>
  );
};

export default ForgetPass;
