import React, { useState } from "react";
import styled from "styled-components";
import { useUserEquipmentList } from "../../utils/useAppUtils";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Button } from "react-bootstrap";

const Container = styled.div`
  padding: 20px;
  background-color: #f4f8fc;
  min-height: 100vh;
  font-family: Arial, sans-serif;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #e3f2fd;
  color: #333;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;



const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? "#0056b3" : "#007bff")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;


export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 18px;
  padding: 40px;
  background-color: #f4f8fc;
  border: 1px dashed #ddd;
  border-radius: 10px;
  margin-top: 50px;
`;

export const NoDataIcon = styled(AiOutlineFileSearch)`
  font-size: 50px;
  color: #ccc;
  margin-bottom: 20px;
`;

export const NoDataText = styled.p`
  font-size: 22px;
  color: #666;
  margin: 10px 0;
`;

export const SuggestionText = styled.p`
  font-size: 16px;
  color: #aaa;
  text-align: center;
  max-width: 400px;
`;

const UserEquipmentlist = () => {
  const quipmentlist = useUserEquipmentList(); 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = quipmentlist.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(quipmentlist.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Container>
      {quipmentlist.length === 0 ? (
        <NoDataContainer>
          <NoDataIcon />
          <NoDataText>No Equipment Data Available</NoDataText>
          <SuggestionText>
            There is no equipment data available right now. Try adding equipment or adjusting filters to view equipment information.
          </SuggestionText>
        </NoDataContainer>
      ) : (
        <>
          <FilterSection>
            <span>Filter By:</span>
            <button>Name</button>
          </FilterSection>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Select</TableHeader>
                <TableHeader>SL No</TableHeader>
                <TableHeader>Date & Time</TableHeader>
                <TableHeader>Equipment Name</TableHeader>
                <TableHeader>Equipment ID</TableHeader>
                <TableHeader>Category Name</TableHeader>
                <TableHeader>Equipment Assign</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <TableRow key={item.assign_product}>
                  <TableCell>
                    <input type="checkbox" />
                  </TableCell>
                  <TableCell>{String(indexOfFirstItem + index + 1).padStart(2, "0")}</TableCell>
                  <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.product_id}</TableCell>
                  <TableCell>{item.category_name}</TableCell>
                  <TableCell>
                    <Button>Assign Now</Button>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            <span>Page {currentPage} of {totalPages}</span>
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </PaginationButton>
          </Pagination>
        </>
      )}
    </Container>
  );
};

export default UserEquipmentlist;
