// actions.js
export const ADD_PERMISSION = 'ADD_PERMISSION';
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const ADD_SELECTION = "ADD_SELECTION";
export const REMOVE_SELECTION = "REMOVE_SELECTION";
export const SET_ALL_CHECKBOXES = 'SET_ALL_CHECKBOXES';

export const addSelection = (payload) => ({
  type: ADD_SELECTION,
  payload,
});

export const removeSelection = (payload) => ({
  type: REMOVE_SELECTION,
});

export const setAllCheckboxes = (isChecked) => ({
  type: SET_ALL_CHECKBOXES,
  payload: isChecked,
});

export const addPermission = (permission) => ({
  type: ADD_PERMISSION,
  payload: permission, // { id, name }
});

export const removePermission = (id) => ({
  type: REMOVE_PERMISSION,
  payload: id, // Remove by id
});
