import { SET_ALL_CHECKBOXES } from "./action";

const initialState = {
  permissions: [] ,
  selectedItems: [],
  checkedItems: {
    selectAll: false,
    child1: false,
    child2: false,
    child2_1: false,
    child2_2: false,
    child3: false,
    child4: false,
    child5: false,
    thirdPartyParentCheckbox: false,
    child6: false,
    child7: false,
    child8: false,
    child9: false,
    child10: false,
    child11: false,
    child12: false,
    child13: false,
    child14: false,
    noLimitCheckbox: false,
    requestEquipmentParentCheckbox: false,
    child15: false,
    child16: false,
    child17: false,
    child18: false,
    child19: false,
    child25: false,
    child26: false,
    child27: false,
    child37: false,
    child38: false,
    child40: false,
    child41: false,
  },
};

export const selectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CHECKBOXES:
      const updatedState = Object.fromEntries(
        Object.keys(state.checkedItems).map((key) => [key, action.payload])
      );
      return { ...state, checkedItems: updatedState };

    case "ADD_SELECTION": {
      const isItemAlreadySelected = state.selectedItems.some(
        (item) =>
          item.id === action.payload.id && item.type === action.payload.type
      );

      // Prevent adding duplicate items
      if (isItemAlreadySelected) return state;

      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload],
      };
    }

    case "REMOVE_SELECTION":
      return {
        ...state,
        selectedItems: []
      };
      case "ADD_PERMISSION":
        return {
            ...state,
            permissions: state.permissions.some((perm) => perm.id === action.payload.id)
                ? state.permissions // Avoid duplicates
                : [...state.permissions, action.payload], // Add new permission
        };
    
    case "REMOVE_PERMISSION":
        return {
            ...state,
            permissions: state.permissions.filter(
                (permission) => permission.id !== action.payload
            ),
        };
    
    default:
      // Ensure the reducer always returns a valid state object
      return state || initialState;
  }
};
