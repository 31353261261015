import React, { useState } from "react";
import {
  ProfileImage,
  TableCell,
  TableDiv,
  TableRow,
} from "../warehouse/Product";
import { BlueBorderButton } from "../Global/GlobalButtons";
import { RootTable } from "../Global/GlobalTable";
import userImage from "../../Assets/profile.png";
import { useRequestEquipment } from "../../utils/useAppUtils";
import {
  NoDataContainer,
  NoDataText,
  SuggestionText,
} from "../group/UserEquipmentlist";
import { NoDataIcon } from "../group/GroupUserList";
import { Pagination } from "../Global/Table2";

const RequestEquipment = () => {
  const requestEquipment = useRequestEquipment();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const paginatedData = requestEquipment.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <div className="contact">
      <TableDiv>
        <div className="dash_table">
          <RootTable>
            {paginatedData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>SL No</th>
                    <th>Date & Time</th>
                    <th>Image</th>
                    <th> Name</th>
                    <th>Product ID</th>
                    <th>Category</th>
                    <th>Profile</th>
                    <th>Requested By / Unit</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <input type="checkbox" />
                      </TableCell>
                      <TableCell>
                        {formatDate(item.create_date || "N/A")}
                      </TableCell>

                      <TableCell>
                        <ProfileImage
                          src={item.image || userImage}
                          alt={item.username}
                        />
                      </TableCell>
                      <TableCell>{item.username}</TableCell>
                      <TableCell>{item.productId || "N/A"}</TableCell>
                      <TableCell>{item.categoryname}</TableCell>
                      <TableCell>{item.username}</TableCell>
                      <TableCell>{item.requestbyRole || "N/A"}</TableCell>
                      <TableCell>
                        <BlueBorderButton variant="primary">
                          {item.status}
                        </BlueBorderButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoDataContainer>
                <NoDataIcon />
                <NoDataText>No Equipment Data Available</NoDataText>
                <SuggestionText>
                  There is no equipment data available right now. Try adding
                  equipment or adjusting filters to view equipment information.
                </SuggestionText>
              </NoDataContainer>
            )}
          </RootTable>
          <Pagination
            currentPage={currentPage}
            totalItems={requestEquipment.length}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
          />
        </div>
      </TableDiv>
    </div>
  );
};

export default RequestEquipment;
