import React from "react";
import { toast } from "react-toastify";
import {
  newStaffLoginAction,
  userCheckAction,
  userDataAction,
} from "../../redux/users/action";
import useAppUtils from "../../utils/useAppUtils";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
  email: Yup.string().required("email is required").email("Invalid email format"),

});

const StaffLogin = () => {
  const { dispatch, navigate } = useAppUtils(); // Use custom hook

  const handleLogin = (values, { setSubmitting }) => {
    const { email } = values;

    dispatch(
      newStaffLoginAction({ email }, (response, resetForm) => {
        setSubmitting(false);
        localStorage.setItem("loginTimestamp", Date.now().toString());
        const lastVisitedPage = localStorage.getItem("lastVisitedPage"); // Default to dashboard if no page is saved
        navigate(lastVisitedPage);
        if (response?.status === 200) {
          const user = response?.data?.data;
          console.log("to set role", user);
          localStorage.setItem("token", user.token);
          localStorage.setItem("role", user.role);
          // localStorage.setItem("is_verified", user.is_verified);
          dispatch(userDataAction(user));
          dispatch(userCheckAction(true));
          navigate("/user_profile");
          setTimeout(() => {
            window.location.reload();
          }, 700);
        } else {
          resetForm();
          toast.error("Login failed. Please try again.");
          setSubmitting(true);

        }
      })
    );
  };

  return (
    <Root>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({ isSubmitting }) => (
          <LoginContainer>
            <LoginForm>
              <Title>Staff Login</Title>
              <Field
                type="text"
                name="email"
                placeholder="xyz@gmail.com"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="email" />

              <LoginButton type="submit" >
                Login
              </LoginButton>
            </LoginForm>
          </LoginContainer>
        )}
      </Formik>
    </Root>
  );
};
export default StaffLogin;
const Root = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
`;
const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  &:focus {
    border-color: #4caf50;
    outline: none;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
  }
`;
const LoginButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #1814f3;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1814f3;
  }
  &:active {
    background-color: #388e3c;
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

