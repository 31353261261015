import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  AllUserProfileByIdGetAction,
  EquipmentUserListGet,
  getCategoryAction,
  getGroupAction,
  getProductAction,
  getProductViewAction,
  getRoleAction,
  getUnitAction,
  getUserAction,
  getUserPermissionViewAction,
  getUserProductFetchAction,
  getUserSelectedAction,
  getWarehouseAction,
  groupRequestSentAction,
  GroupUserListGet,
  MemberRequestSentAction,
  NewAddUserListAction,
  requestEquipmentFetchAction,
  SecondNewAddUserListAction,
  TransferApprovalSagaGetAction,
  UnitUserListGet,
  WaitinUserUnitFetchAction,
} from "../redux/users/action";

// Custom hook that returns dispatch and navigate
const useAppUtils = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return { dispatch, navigate };
};

export default useAppUtils;

export const useUnitList = () => {
  const [units, setUnits] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUnitList = () => {
      dispatch(
        getUnitAction(units, (response) => {
          if (response?.status === 200) {
            setUnits(response?.data?.data);
          }
        })
      );
    };

    fetchUnitList();
  }, [dispatch, units,]);

  return units;
};

export const useProductList = () => {
  const [productList, setproductList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductList = () => {
      dispatch(
        getProductAction(productList, (response) => {
          if (response?.status === 200) {
            setproductList(response?.data?.data);
          }
        })
      );
    };

    fetchProductList();
  }, [dispatch]);

  return productList;
};

export const useGroupList = () => {
  const [groups, setGroups] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGroupList = () => {
      dispatch(
        getGroupAction(groups, (response) => {
          if (response?.status === 200) {
            setGroups(response?.data?.data);
          }
        })
      );
    };

    fetchGroupList();
  }, [dispatch]);

  return groups;
};
export const useTransferApproval = () => {
  const [transferApproval, setTransferApproval] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTransferApproval  = () => {
      dispatch(
        TransferApprovalSagaGetAction(transferApproval, (response) => {
          if (response?.status === 200) {
            setTransferApproval(response?.data?.data);
          }
        })
      );
    };

    fetchTransferApproval();
  }, [dispatch]);

  return transferApproval;
};

export const useUserList = () => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserList = () => {
      dispatch(
        getUserAction(users, (response) => {
          if (response?.status === 200) {
            setUsers(response?.data?.data);
          }
        })
      );
    };

    fetchUserList();
  }, [dispatch]);

  return users;
};

export const useUserSelectedList = () => {
  const [usersSelect, setUsersSelect] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserSelectedList = () => {
      dispatch(
        getUserSelectedAction(usersSelect, (response) => {
          if (response?.status === 200) {
            setUsersSelect(response?.data?.data);
          }
        })
      );
    };

    fetchUserSelectedList();
  }, [dispatch, usersSelect]);

  return usersSelect;
};

export const useCategoryList = () => {
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCategoryList = () => {
      dispatch(
        getCategoryAction(categories, (response) => {
          if (response?.status === 200) {
            setCategories(response?.data?.data);
          }
        })
      );
    };

    fetchCategoryList();
  }, [categories, dispatch]);

  return categories;
};

export const useWarehouseList = () => {
  const [warehouse, setWarehouse] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchWarehouseList = () => {
      dispatch(
        getWarehouseAction(warehouse, (response) => {
          if (response?.status === 200) {
            setWarehouse(response?.data?.data);
          }
        })
      );
    };

    fetchWarehouseList();
  }, [dispatch, warehouse]);

  return warehouse;
};

export const useRoleList = () => {
  const [role, setRole] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRoleList = () => {
      dispatch(
        getRoleAction(role, (response) => {
          if (response?.status === 200) {
            setRole(response?.data?.data);
          }
        })
      );
    };

    fetchRoleList();
  }, [dispatch, role]);

  return role;
};

export const useProductView = () => {
  const [product, setProduct] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductView = () => {
      dispatch(
        getProductViewAction(product, (response) => {
          if (response?.status === 200) {
            setProduct(response?.data?.data);
          }
        })
      );
    };

    fetchProductView();
  }, [dispatch]);

  return product;
};

export const useUserProductFetch = () => {
  const [userProductFetch, setUserProductFetch] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const userFetchProductView = () => {
      dispatch(
        getUserProductFetchAction(userProductFetch, (response) => {
          if (response?.status === 200) {
            setUserProductFetch(response?.data?.data);
          }
        })
      );
    };

    userFetchProductView();
  }, [dispatch, userProductFetch]);

  return userProductFetch;
};

export const usePermission = () => {
  const [permission, setPermission] = useState([]);
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPermission = () => {
      dispatch(
        getUserPermissionViewAction(permission, (response) => {
          if (response?.status === 200 && Array.isArray(response?.data?.data)) {
            setPermission(response.data.data);
          } else {
            setPermission([]);
          }
          // setLoading(false);

          console.log("Fetched Permissions:", response?.data?.data);
        })
      );
    };
    fetchPermission();
  }, [permission, dispatch]);

  return { permission };
};

export const useUnitUserList = () => {
  const [unitss, setUnitss] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUnitListusers = () => {
      dispatch(
        UnitUserListGet(unitss, (response) => {
          if (response?.status === 200) {
            setUnitss(response?.data?.data);
          }
        })
      );
    };

    fetchUnitListusers();
  }, [dispatch]);

  return unitss;
};

export const useUserEquipmentList = () => {
  const [equipmentid, setequipmentid] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchEquipmentListusers = () => {
      dispatch(
        EquipmentUserListGet(equipmentid, (response) => {
          if (response?.status === 200) {
            setequipmentid(response?.data?.data);
          }
        })
      );
    };

    fetchEquipmentListusers();
  }, [dispatch]);

  return equipmentid;
};

export const useRequestEquipment = () => {
  const [requestEquipment, setRequestEquipment] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequestEquipment = () => {
      dispatch(
        requestEquipmentFetchAction(requestEquipment, (response) => {
          if (response?.status === 200) {
            setRequestEquipment(response?.data?.data);
          }
        })
      );
    };

    fetchRequestEquipment();
  }, [dispatch]);

  return requestEquipment;
};

export const useAllUserProfileByIdGetAction = () => {
 const [userData, setUserData] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAllUserProfileById = () => {
      dispatch(
        AllUserProfileByIdGetAction(userData, (response) => {
          if (response?.status === 200) {
            setUserData(response?.data?.data);
          }
        })
      );
    };
    const clearUserData = () => {
      setUserData(null); // Clear the state
    };
    clearUserData();
    fetchAllUserProfileById();
  }, [dispatch]);

  return userData;
};
export const useGroupUserLists = () => {
  const [groupsbyid, setgroupsbyid] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUnitListusers = () => {
      dispatch(
        GroupUserListGet(groupsbyid, (response) => {
          if (response?.status === 200) {
            setgroupsbyid(response?.data?.data);
          }
        })
      );
    };

    fetchUnitListusers();
  }, [dispatch]);

  return groupsbyid;
};

export const useGroupRequestSent = () => {
  const [groupsRequest, setGroupRequest] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const GroupRequestdata = () => {
      dispatch(
        groupRequestSentAction(groupsRequest, (response) => {
          if (response?.status === 200) {
            setGroupRequest(response?.data?.data);
          }
        })
      );
    };

    GroupRequestdata();
  }, [dispatch, groupsRequest]);

  return groupsRequest;
};

export const useMemberRequestSent = () => {
  const [memberRequest, setMemberRequest] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const MemberRequestdata = () => {
      dispatch(
        MemberRequestSentAction(memberRequest, (response) => {
          if (response?.status === 200) {
            setMemberRequest(response?.data?.data);
          }
        })
      );
    };

    MemberRequestdata();
  }, [dispatch, memberRequest]);

  return memberRequest;
};
export const useAddNewUserListRequestSent = () => {
  const [addnewlist, setAddnewlist] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const GroupRequestdatanewlist = () => {
      dispatch(
        NewAddUserListAction(addnewlist, (response) => {
          if (response?.status === 200) {
            setAddnewlist(response?.data?.data);
          }
        })
      );
    };
    GroupRequestdatanewlist();
  }, [dispatch,addnewlist]);

  return addnewlist;
};
export const useSecondAddNewUserListRequestSent = () => {
  const [secondaddnewlist, setAddnewlistsecond] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const Addnewusersecond = () => {
      dispatch(
        SecondNewAddUserListAction(secondaddnewlist, (response) => {
          if (response?.status === 200) {
            setAddnewlistsecond(response?.data?.data);
          }
        })
      );
    };

    Addnewusersecond();
  }, [dispatch,secondaddnewlist]);

  return secondaddnewlist;
};
export const useWaitingListUnitUserFetch = () => {
  const [waitingintial, setwaitingintital] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const WaitingUserUnit = () => {
      dispatch(
        WaitinUserUnitFetchAction(waitingintial, (response) => {
          if (response?.status === 200) {
            setwaitingintital(response?.data?.data);
          }
        })
      );
    };

    WaitingUserUnit();
  }, [dispatch,waitingintial]);

  return waitingintial;
};
