import React, { useState } from "react";
import styled from "styled-components";
import {
  useGroupList,
  useGroupUserLists,
  useRoleList,
  useUserSelectedList,
} from "../../utils/useAppUtils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  assignUserToGroupAction,
  moveUserToGroupAction,
  setSelectedUserId,
} from "../../redux/users/action";
import userImage from "../../Assets/profile.png";
import { AssignNowButton, BlackBorderButton } from "../Global/GlobalButtons";
import {
  Container,
  ProfileImage,
  RoleSelector,
  TableWrapper,
} from "../warehouse/Product";
import { Modal } from "react-bootstrap";
import GlobalSearch from "../Global/GlobalSearch";
import { Pagination } from "../Global/Table2";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { NoDataWrapper } from "../Global/GlobalTable";

const GroupUserList = () => {
  const memenerlsitfetch = useGroupUserLists();
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);
  const [selectedRole, setSelectedRole] = useState("user");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showAssign, setShowAssign] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const itemsPerPageUser = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const usersSelect = useUserSelectedList();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groups = useGroupList();
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = memenerlsitfetch.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const selectedGroupId = useSelector((state) => state?.users?.selectedGroupId);

  const totalPages = Math.ceil(memenerlsitfetch.length / itemsPerPage);

  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const filteredPaginatedUsersSelect = getPaginatedData(
    usersSelect.filter(
      (item) => selectedRole === "" || item.role === selectedRole
    ),
    currentPageUser,
    itemsPerPageUser
  );
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleNavigateToQuipemtn = (id) => {
    dispatch(setSelectedUserId(id));
    navigate(`/userlist-equipment/${id}`);
  };

  const handleDelete = (id) => {
    console.log(`Delete user with ID: ${id}`);
  };
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };
  const handleClose = () => {
    setShowAssign(false);
    setShowGroup(false);
    setIsCheckboxSelected(false);
    setSelectedUserDetails([]);
  };
  const handleSelectChangeUser = (index, isChecked) => {
    const user = currentItems[index];

    if (isChecked) {
      setSelectedUserDetails({
        id: user.id,
        name: user.name,
        role: user.role,
      });
      setIsCheckboxSelected({
        [user.id]: true, // Mark only the current checkbox as selected
      });
    } else {
      setSelectedUserDetails(null);
      setIsCheckboxSelected({});
    }
  };

  const handleSelectChangeGroup = (rowIndex, isChecked) => {
    if (groups && rowIndex >= 0 && rowIndex < groups.length) {
      const group = groups[rowIndex];
      setSelectedUserDetails((prev) => ({
        ...prev,
        group_id: isChecked ? group.group_id : null,
      }));
    }
  };

  const handleSelectChange = (rowIndex, itemType, isChecked) => {
    if (itemType === "user") {
      if (
        filteredPaginatedUsersSelect &&
        rowIndex >= 0 &&
        rowIndex < filteredPaginatedUsersSelect.length
      ) {
        const user = filteredPaginatedUsersSelect[rowIndex];

        setSelectedUsers((prevSelectedUsers) => {
          if (isChecked) {
            return [...prevSelectedUsers, user.user_id];
          } else {
            return prevSelectedUsers.filter(
              (selectedUserId) => selectedUserId !== user.user_id
            );
          }
        });
      }
    }
  };

  const handleAssignClick = () => {
    if (!selectedGroupId) {
      toast.error("No group selected for assignment.");
      return;
    }
    if (selectedUsers.length === 0) {
      toast.error("No users selected for assignment.");
      return;
    }
    console.log("Selected Users' User IDs:", selectedUsers);

    dispatch(
      assignUserToGroupAction({
        userids: selectedUsers,
        move_groupid: selectedGroupId,
        userid_role: selectedRole,
      })
    );
    setIsCheckboxSelected(false);
    setSelectedUsers([]);
    setSelectedUserDetails([]);
    setShowAssign(false);
  };

  const handleAssignNow = (groupId) => {
    if (!selectedUserDetails?.id || !selectedUserDetails?.group_id) {
      toast.error("Please select both a user and a group for assignment.");
      return;
    }
    console.log("Assigning user:", selectedUserDetails);
    console.log("Group ID:", groupId);
    const userDetails = {
      userid: selectedUserDetails.id,
      group_id: selectedGroupId,
      role: selectedUserDetails.role,
    };

    console.log("Dispatching moveUserToGroupAction with details:", userDetails);

    dispatch(moveUserToGroupAction(userDetails));
    setIsCheckboxSelected(false);
    handleClose();
    setSelectedUsers([]);
    setSelectedUserDetails([]);
  };

  const handleShowAssign = () => {
    setShowAssign(true);
  };

  const handleMoveNowClick = () => {
    if (!isCheckboxSelected || !selectedUserDetails) {
      toast.error("Please select a user before proceeding with 'Move Now'.");
      return;
    }

    setShowGroup(true);
    console.log("Selected user details:", selectedUserDetails);
  };
  const role = useRoleList();
  const totalPagesInModal = Math.ceil(
    usersSelect.filter(
      (item) => selectedRole === "" || item.role === selectedRole
    ).length / itemsPerPageUser
  );
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPagesInModal) {
      setCurrentPageUser(page);
    }
  };
  return (
    <Containers>
      <FilterSection>
        <FilterLabel>Filter By:</FilterLabel>

        <Button onClick={handleShowAssign}>Add User</Button>
      </FilterSection>
      {memenerlsitfetch.length > 0 ? (
        <>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Select</TableHeader>
                <TableHeader>Sr No</TableHeader>
                <TableHeader>Member Name</TableHeader>
                <TableHeader>Position</TableHeader>
                <TableHeader>Group Name</TableHeader>
                <TableHeader>Equipment List</TableHeader>
                <TableHeader>Re-Location</TableHeader>
                <TableHeader>Remove</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={!!isCheckboxSelected[item.id]}
                      onChange={(e) =>
                        handleSelectChangeUser(index, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {String(indexOfFirstItem + index + 1).padStart(2, "0")}
                  </TableCell>
                  <TableCell
                    onClick={() => handleNavigateToQuipemtn(item.id)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {item.name || "No Name"}
                  </TableCell>
                  <TableCell>{item.role}</TableCell>
                  <TableCell>{item.group_names}</TableCell>
                  <TableCell>{item.total_products_assigned}</TableCell>
                  <TableCell>
                    <BlackBorderButton
                      onClick={() => handleMoveNowClick(index)}
                    >
                      Move Now
                    </BlackBorderButton>
                  </TableCell>
                  <TableCell>
                    <DeleteIcon onClick={() => handleDelete(item.id)}>
                      🗑️
                    </DeleteIcon>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <Paginations>
            <PaginationButton
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </PaginationButton>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <PaginationButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </PaginationButton>
          </Paginations>
        </>
      ) : (
        <NoDataContainer>
          <NoDataIcon>👤</NoDataIcon>
          <NoDataText>No Users Found in This Group</NoDataText>
          <SuggestionText>
            Please add users or check your filters.
          </SuggestionText>
        </NoDataContainer>
      )}
      <>
        <Modal show={showGroup} onHide={handleClose} size="xl" centered>
          <Modal.Header closeButton>
            <div>
              <GlobalSearch search="Search by Group" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {groups.length > 0 ? (
                <TableWrapper>
                  <Table>
                    <thead>
                      <TableRow>
                        <TableHeader></TableHeader>
                        <TableHeader>Profile Image</TableHeader>
                        <TableHeader>Group Name</TableHeader>
                        <TableHeader>Role</TableHeader>
                        <TableHeader>ID</TableHeader>
                        <TableHeader>Created By</TableHeader>
                        <TableHeader>Unit</TableHeader>
                        <TableHeader>User List</TableHeader>
                        <TableHeader>Action</TableHeader>
                      </TableRow>
                    </thead>
                    <tbody>
                      {groups.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ minWidth: "60px" }}>
                            <input
                              type="checkbox"
                              checked={
                                selectedUserDetails?.group_id === item.group_id
                              }
                              onChange={(event) =>
                                handleSelectChangeGroup(
                                  index,
                                  event.target.checked
                                )
                              }
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: "60px" }}>
                            <ProfileImage
                              src={item.image || userImage}
                              alt={item.group_name}
                            />
                          </TableCell>
                          <TableCell>{item.group_name}</TableCell>
                          <TableCell>{item.role || "-"}</TableCell>
                          <TableCell>{item.group_id || "-"}</TableCell>
                          <TableCell>{item.creator_name || "-"}</TableCell>
                          <TableCell>{item.unit_name || "-"}</TableCell>
                          <TableCell>{item.user_count || "-"}</TableCell>
                          <TableCell>
                            <AssignNowButton
                              onClick={() => handleAssignNow(item.group_id)}
                            >
                              {" "}
                              Assign now
                            </AssignNowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              ) : (
                <NoDataWrapper>
                  <h3>No Groups Available</h3>
                  <p>Please add or fetch groups to display here.</p>
                </NoDataWrapper>
              )}

              <Pagination
                currentPage={currentPageUser}
                totalItems={
                  usersSelect.filter((item) => item.role === selectedRole)
                    .length
                }
                itemsPerPage={itemsPerPageUser}
                onPageChange={(page) => setCurrentPageUser(page)}
              />
            </Container>
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal show={showAssign} onHide={handleClose} size="xl" centered>
          <Modal.Header closeButton>
            <div>
              <GlobalSearch search="Search by Name,Id,Group,Unit" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <RoleSelector>
                {" "}
                <b>Select</b>
                {role.length
                  ? role.map((roleItem) => (
                      <BlackBorderButton
                        key={roleItem.role}
                        className={
                          selectedRole === roleItem.role ? "active" : ""
                        }
                        onClick={() => handleRoleSelect(roleItem.role)}
                      >
                        {roleItem.role || "unknown"}{" "}
                      </BlackBorderButton>
                    ))
                  : "No roles available"}
              </RoleSelector>
              {filteredPaginatedUsersSelect.length > 0 ? (
                <TableWrapper>
                  <Table>
                    <thead>
                      <TableRow>
                        <TableHeader></TableHeader>
                        <TableHeader>Profile Image</TableHeader>
                        <TableHeader>Username</TableHeader>
                        <TableHeader>Profile</TableHeader>
                        <TableHeader>ID</TableHeader>
                        <TableHeader>Group</TableHeader>
                        <TableHeader>Unit</TableHeader>
                        <TableHeader>Action</TableHeader>
                      </TableRow>
                    </thead>
                    <tbody>
                      {filteredPaginatedUsersSelect
                        .filter((item) => item.role === selectedRole)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ minWidth: "60px" }}>
                              <input
                                type="checkbox"
                                checked={selectedUsers.includes(item.user_id)}
                                onChange={(event) =>
                                  handleSelectChange(
                                    index,
                                    "user",
                                    event.target.checked
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: "60px" }}>
                              <ProfileImage
                                src={item.image || userImage}
                                alt={item.username}
                              />
                            </TableCell>
                            <TableCell>{item.username}</TableCell>
                            <TableCell>{item.role || "N/A"}</TableCell>
                            <TableCell>{item.user_id}</TableCell>
                            <TableCell>{item.group_name}</TableCell>
                            <TableCell>{item.unit_name || "N/A"}</TableCell>
                            <TableCell>
                              <AssignNowButton
                                onClick={() => {
                                  handleAssignClick();
                                  handleClose();
                                }}
                              >
                                Assign now
                              </AssignNowButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              ) : (
                <NoDataWrapper>
                  <NoDataIcon />
                  <h3>No Data Available</h3>
                  <p>Please add or fetch data to display here.</p>
                </NoDataWrapper>
              )}
              <Pagination
                currentPage={currentPageUser}
                totalItems={
                  usersSelect.filter(
                    (item) => selectedRole === "" || item.role === selectedRole
                  ).length
                }
                itemsPerPage={itemsPerPageUser}
                onPageChange={handlePageChange}
              />
            </Container>
          </Modal.Body>
        </Modal>
      </>
    </Containers>
  );
};

export default GroupUserList;
const Containers = styled.div`
  padding: 20px;
  background-color: #f4f8fc;
  min-height: 100vh;
  font-family: Arial, sans-serif;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FilterLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #e3f2fd;
  color: #333;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #0056b3;
  }
`;

const DeleteIcon = styled.div`
  color: #ff4d4f;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: #ff1a1a;
  }
`;

const Paginations = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? "#0056b3" : "#007bff")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #f4f8fc;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
`;

export const NoDataIcon = styled.div`
  font-size: 40px;
  color: #007bff;
  margin-bottom: 10px;
`;

const NoDataText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin: 0;
`;

const SuggestionText = styled.p`
  font-size: 14px;
  color: #777;
  margin: 5px 0;
`;
