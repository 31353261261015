import React, { useEffect } from "react";
import Layout from "./mainLayout/Layout";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./components/Pages/Dashboard";
import { WarehouseManage } from "./components/warehouse/WarehouseManage";
import { AddProduct } from "./components/warehouse/AddProducts";
import { AddCategory } from "./components/warehouse/AddCategory";
import { Category } from "./components/warehouse/Category";
import { Product } from "./components/warehouse/Product";
import { SubWarehouse } from "./components/warehouse/SubWarehouse";
import { AllGroup } from "./components/group/AllGroup";
import { History } from "./components/group/History";
import { Request } from "./components/group/Request";
import AddUsers from "./components/users/AddUsers";
import RequestEquipment from "./components/Pages/RequestEquipment";
import  Hierarchy  from "./components/Pages/Hierarchy";
import UniteProfile from "./components/Pages/UniteProfile";
import { ManageRole } from "./components/manageRoles/ManageRole";
import { InvitedUsers } from "./components/manageRoles/InvitedUsers";
import UsersList from "./components/users/UsersList";
import InvitedList from "./components/users/InvitedList";
import "bootstrap/dist/css/bootstrap.min.css";
import { GroupCreate } from "./components/group/groupCreate/GroupCreate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./components/common/AdminLogin";
import RegistrationPage from "./components/common/RegistrationPage";
import { ViewProduct } from "./components/warehouse/ViewProducts";
import WarehouseLogin from "./components/common/WarehouseLogin";
import { useSelector } from "react-redux";
import UserLogin from "./components/common/UserLogin";
import Listofusers from "./components/group/Listofusers";
import UserEquipmentlist from "./components/group/UserEquipmentlist";
import GroupUserList from "./components/group/GroupUserList";
import ChoosePortal from "./components/common/ChoosePortal";
import NewUserLogin from "./components/common/NewUserLogin";
import NewUserRegister from "./components/common/NewUserRegister";
import RequestUnit from "./components/Pages/RequestUnit";
import WaitingUserUnit from "./components/common/WaitingUserUnit";
import UserProfile from "./components/Pages/UserProfile";
import Loader from "./components/common/Loader";
import AllUserProfile from "./components/users/AllUserProfile";
import useAppUtils from "./utils/useAppUtils";
import StaffLogin from "./components/common/StaffLogin";
import TransferApproval from "./components/Pages/TransferApproval";
import ReconcileApproval from "./components/Pages/ReconcileApproval";
import WelcomePage from "./components/common/WelcomePage";
// import Card from "./components/users/Card";
import UserPermission from "./components/users/UserPermission";
import ForgetPass from "./components/common/ForgetPass";

export default function App() {
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const token = localStorage.getItem("token");
  const verify = localStorage.getItem("is_verified");
  const location = useLocation();
  const { navigate } = useAppUtils();

  useEffect(() => {
    // Save the current path in localStorage
    localStorage.setItem("lastVisitedPath", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // On app load, check for the last visited path
    const lastVisitedPath = localStorage.getItem("lastVisitedPath");
    const token = localStorage.getItem("token");

    if (token && lastVisitedPath) {
      // If user is logged in and a last visited path exists, navigate to it
      navigate(lastVisitedPath);
    }
  }, [navigate]);
  return (
    <div>
      <Layout>
        <ToastContainer
          // className="toast-container"
          // toastClassName="toast-message"
        />
        <Loader />
        <Routes>
          {userCheck && token ? (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user_permission" element={<UserPermission />} />
              <Route path="/warehouse_manage" element={<WarehouseManage />} />
              <Route
                path="/warehouse_manage/add_product"
                element={<AddProduct />}
              />
              <Route
                path="/warehouse_manage/add_category"
                element={<AddCategory />}
              />
              <Route path="/warehouse_manage/category" element={<Category />} />
              <Route path="/warehouse_manage/product" element={<Product />} />
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/warehouse_manage/viewproduct"
                element={<ViewProduct />}
              />
              <Route
                path="/warehouse_manage/viewproduct/:id"
                element={<ViewProduct />}
              />
              <Route
                path="/warehouse_manage/create_warehouse"
                element={<SubWarehouse />}
              />
              <Route path="/group_userlists" element={<GroupUserList />} />
              <Route path="/group_byid/:id" element={<GroupUserList />} />
              <Route path="/group_create" element={<GroupCreate />} />
              <Route path="/all_group" element={<AllGroup />} />
              <Route path="/request" element={<Request />} />
              <Route path="history" element={<History />} />
              <Route path="/add_users" element={<AddUsers />} />
              <Route path="/users_list" element={<UsersList />} />
              <Route path="/invited_list" element={<InvitedList />} />
              <Route path="/manage_role" element={<ManageRole />} />
              <Route path="/user_profile" element={<UserProfile />} />
              <Route path="/all_user_profile" element={<AllUserProfile />} />
              <Route path="/all_user_profile/:id" element={<AllUserProfile />} />
              <Route
                path="/invited_users"
                element={<InvitedUsers />}
              />
              <Route path="/choose_portal" element={<ChoosePortal />} />
              <Route path="/waiting-unitapproove" element={<WaitingUserUnit />} />
              <Route path="/unite_profile" element={<UniteProfile />} />
              <Route path="/req_unit" element={<RequestUnit />} />
              <Route path="/request_equipment" element={<RequestEquipment />} />
              <Route path="/transfer_approval" element={<TransferApproval />} />
              <Route path="/reconcile_approval" element={<ReconcileApproval />} />
              <Route path="/hierarchy" element={<Hierarchy />} />
              <Route path="/unit_userlist" element={<Listofusers />} />
              <Route path="/unit_userlist/:id" element={<Listofusers />} />
              {/* <Route path="/card" element={<Card />} /> */}
              <Route
                path="/userlist-equipment/:id"
                element={<UserEquipmentlist />}
              />
            </>
          ) : verify === 1 && token ?
            (<>
              <Route path="/" element={<WaitingUserUnit />} />
              <Route path="/waiting-unitapproove" element={<WaitingUserUnit />} />
            </>
            )
            : (
              <>
                <Route path="/user_profile" element={<UserProfile />} />
                <Route path="/newlogin" element={<NewUserLogin />} />
                <Route path="/adminlogin" element={<AdminLogin />} />
                <Route path="/userlogin" element={<UserLogin />} />
                <Route path="/" element={<WelcomePage />} />
                <Route path="/login" element={<WarehouseLogin />} />
                <Route path="/register" element={<RegistrationPage />} />{" "}
                <Route path="/newregister" element={<NewUserRegister />} />
              </>
            )}
          <>
            <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/forgot-password" element={<ForgetPass />} />
            <Route path="/newlogin" element={<NewUserLogin />} />
            <Route path="/stafflogin" element={<StaffLogin />} />
            <Route path="/newregister" element={<NewUserRegister />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route path="/" element={<WelcomePage />} />
            <Route path="/userlogin" element={<UserLogin />} />
            <Route path="/login" element={<WarehouseLogin />} />
            <Route path="/register" element={<RegistrationPage />} />{" "}
          </>
        </Routes>
      </Layout>
    </div>
  );
}
