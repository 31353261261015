import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BlueBackgroundButton, TabButton } from "../../Global/GlobalButtons";
import { GrEdit } from "react-icons/gr";
import { BorderInput, BorderSelect } from "../../Global/GlobalPlaceholder";
import { ErrorMsg, Textlabel } from "../../Global/GlobalText";
import userImage from "../../../Assets/ProfileIcon.png";
import { AiOutlineSwap } from "react-icons/ai";
import {
  createGroupAction,
  createMoveGrouptoUnitAction,
  createRoleUserAction,
  createUnitAction,
} from "../../../redux/users/action";
import { toast } from "react-toastify";
import useAppUtils, {
  useGroupList,
  useUnitList,
  useUserList,
} from "../../../utils/useAppUtils";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLocation } from "react-router-dom";
const validationSchema = Yup.object({
  group_name: Yup.string().required("Name is required"),
  unitId: Yup.string().required("Unit is required"),
});
const validationSchemas = Yup.object({
  unit_name: Yup.string().required("Name is required"),
});

const validatioRoleSchema = Yup.object({
  role: Yup.string().required("Role is required"),
  userid: Yup.string().required("User is required"),
});

const validationupdateMoveGroup = Yup.object({
  groupid: Yup.string().required("Group is required"),
  unitId: Yup.string().required("Unit is required"),
});
export const GroupCreate = () => {
  const location = useLocation(); 
  const [selectedTab, setSelectedTab] = useState("tab1");
  const units = useUnitList();
  const listusers = useUserList();
  const { dispatch, navigate } = useAppUtils();
  const grouplist = useGroupList();
  console.log("grouplist", grouplist);
  const unitlistfetch = useUnitList();
  useEffect(() => {
    if (location.state?.selectedTab) {
      setSelectedTab(location.state.selectedTab);
    }
  }, [location.state]);
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const initialValues = {
    group_name: "",
    unitId: "",
  };
  const initialValue = {
    unit_name: "",
  };
  const initialValuesRole = {
    role: "",
    userid: "",
  };

  const initialValuesMoveGroup = {
    groupid: "",
    unitId: "",
  };
  const handleCreateGroup = (values, { setSubmitting, resetForm }) => {
    dispatch(
      createGroupAction(values, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  const handleCreateUnit = (value, { setSubmitting, resetForm }) => {
    dispatch(
      createUnitAction(value, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  const handleCreateRoleUser = (values, { setSubmitting, resetForm }) => {
    const payload = {
      role: values.role,
      userid: values.userid,
    };

    dispatch(
      createRoleUserAction(payload, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  const handleCreateMoveGroupToUnit = (
    values,
    { setSubmitting, resetForm }
  ) => {
    const payload = {
      groupid: values.groupid,
      unitId: values.unitId,
    };
    dispatch(
      createMoveGrouptoUnitAction(payload, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  return (
    <Root>
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          Create Group
        </TabButton>

        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Create Role
        </TabButton>

        <TabButton
          className={selectedTab === "tab3" ? "selected" : ""}
          onClick={() => handleTabClick("tab3")}
        >
          Move Group
        </TabButton>
        <TabButton
          className={selectedTab === "tab4" ? "selected" : ""}
          onClick={() => handleTabClick("tab4")}
        >
          Create Unit
        </TabButton>
      </div>

      <div className="table">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {selectedTab === "tab1" ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleCreateGroup}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <CreateGroup>
                        <div className="profile_input">
                          <div className="profile_edit">
                            <img src={userImage} alt="profile edit" />
                            <button className="edit_icon">
                              <GrEdit />
                            </button>
                          </div>

                          <div className="input_select">
                            <div className="input_select_group">
                              <Textlabel htmlFor="group name">
                                Group Name
                              </Textlabel>
                              <Field
                                name="group_name"
                                as={BorderInput}
                                placeholder="Group Name"
                              />
                              <ErrorMessage
                                name="group_name"
                                component="div"
                                className="error"
                                as={ErrorMsg}
                              />
                            </div>

                            <div className="input_select_sub_div">
                              <Textlabel htmlFor="unit">Unit</Textlabel>
                              <Field name="unitId" as={BorderSelect}>
                                <option value="">Select Unit</option>
                                {unitlistfetch?.map((unit) => (
                                  <option
                                    key={unit.unit_id}
                                    value={unit.unit_id}
                                  >
                                    {unit.unit_name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="unitid"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                      </CreateGroup>
                      <div className="save_btn">
                        <BlueBackgroundButton
                          type="submit"
                          disabled={isSubmitting}
                          id="14"
                        >
                          Save
                        </BlueBackgroundButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : selectedTab === "tab2" ? (
                <Formik
                  initialValues={initialValuesRole}
                  validationSchema={validatioRoleSchema}
                  onSubmit={handleCreateRoleUser}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <CreateGroup>
                        <div className="profile_input">
                           

                          <div className="input_select">
                            <div className="input_select_group">
                              <Textlabel htmlFor="role">Create Role</Textlabel>
                              <Field
                                name="role"
                                as={BorderInput}
                                placeholder="Create Role"
                              />
                              <ErrorMessage
                                name="role"
                                component="div"
                                className="error"
                                as={ErrorMsg}
                              />
                            </div>

                            <div className="input_select_sub_div">
                              <Textlabel htmlFor="userid">User Name</Textlabel>
                              <Field name="userid" as={BorderSelect}>
                                <option value="">Select User</option>
                                {listusers?.map((user) => (
                                  <option key={user.id} value={user.id}>
                                    {user.name} ({user.username}){" "}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="userid"
                                component="div"
                                className="error"
                                as={ErrorMsg}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="save_btn">
                          <BlueBackgroundButton
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Save
                          </BlueBackgroundButton>
                        </div>
                      </CreateGroup>
                    </Form>
                  )}
                </Formik>
              ) : selectedTab === "tab3" ? (
                <Formik
                  initialValues={initialValuesMoveGroup}
                  validationSchema={validationupdateMoveGroup}
                  onSubmit={handleCreateMoveGroupToUnit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <StyledBorderSelectContainer>
                      <StyledDiv>
                          <Textlabel htmlFor="groupid">Group Name</Textlabel>
                          <Field name="groupid" as={StyledBorderSelect}>
                            <option value="">Select Group</option>
                            {Array.isArray(grouplist) &&
                            grouplist.length > 0 ? (
                              grouplist.map((group) => (
 
                                <option
                                  key={group.group_id}
                                  value={group.group_id}
                                >
                    {group.group_name}
                                </option>
                              ))
                            ) : (
                              <option value="">No groups available</option>
                            )}
                          </Field>
                          <ErrorMessage
                            name="groupid"
                            component="div"
                            className="error"
                            as={ErrorMsg}
                          />
                      </StyledDiv>
                        <StyledSwapIcon>
                          <AiOutlineSwap />
                        </StyledSwapIcon>
                        <StyledDiv >
                      
                          <Textlabel htmlFor="unitId">Unit</Textlabel>
                          <Field name="unitId" as={StyledBorderSelect}>
                            <option value="">Select Unit</option>
                            {unitlistfetch?.map((unit) => (
                              <option key={unit.unit_id} value={unit.unit_id}>
                                {unit.unit_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="unitId"
                            component="div"
                            className="error"
                            as={ErrorMsg}
                          />
                      </StyledDiv>
                      </StyledBorderSelectContainer>

                      <div className="save_btn">
                        <BlueBackgroundButton
                          type="submit"
                          disabled={isSubmitting}
                          id="16"
                        >
                          Save
                        </BlueBackgroundButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : selectedTab === "tab4" ? (
                <CreateGroup>
                  <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchemas}
                    onSubmit={handleCreateUnit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="profile_input">
                          <div className="profile_edit">
                            <img src={userImage} alt="profile edit" />
                            <button className="edit_icon">
                              <GrEdit />
                            </button>
                          </div>

                          <div className="input_select">
                            <div className="input_select_group">
                              <Textlabel htmlFor="unit name">
                                Unit Name
                              </Textlabel>
                              <Field
                                name="unit_name"
                                as={BorderInput}
                                placeholder="Unit Name"
                              />
                              <ErrorMessage
                                name="unit_name"
                                component="div"
                                as={ErrorMsg}
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="save_btn">
                          <BlueBackgroundButton
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Save
                          </BlueBackgroundButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CreateGroup>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleCreateGroup}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <CreateGroup>
                        <div className="profile_input">
                          <div className="profile_edit">
                            <img src={userImage} alt="profile edit" />
                            <button className="edit_icon">
                              <GrEdit />
                            </button>
                          </div>

                          <div className="input_select">
                            <div className="input_select_group">
                              <Textlabel htmlFor="group name">
                                Group Name
                              </Textlabel>
                              <Field
                                name="group_name"
                                as={BorderInput}
                                placeholder="Group Name"
                              />
                              <ErrorMessage
                                name="group_name"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="input_select_sub_div">
                              <Textlabel htmlFor="unit">Unit</Textlabel>
                              <Field name="unitId" as={BorderSelect}>
                                <option value="">Select Unit</option>
                                {units?.map((unit) => (
                                  <option key={unit.id} value={unit.id}>
                                    {unit.unit_name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="unitid"
                                component="div"
                                className="error"
                                as={ErrorMsg}
                              />
                            </div>
                          </div>
                        </div>
                      </CreateGroup>
                      <div className="save_btn">
                        <BlueBackgroundButton
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </BlueBackgroundButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  .save_btn {
    display: flex;
    justify-content: flex-end;
  }
  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .table {
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
`;
export const CreateGroup = styled.section`
  .profile_input {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    flex-wrap: wrap;
    .profile_edit {
      display: flex;
      position: relative;
      align-items: baseline;
      img {
        width: 130px;
        height: 140px;
        position: relative;
      }
      .edit_icon {
        background-color: transparent;
        border: none;
      }
      svg {
        cursor: pointer;
        background-color: #1814f3;
        color: #fff;
        width: 35px;
        height: 35px;
        padding: 7px;
        position: absolute;
        top: 80px;
        left: 72px;
        border-radius: 50%;
      }
    }
    .input_select {
      display: flex;
      flex: 1;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-top: 20px;
      }
      .input_select_sub_div,
      .input_select_group {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        label {
          padding: 0px 10px 10px;
          font-size: 14px;
        }
        input,
        select {
          border: 1.5px solid #dfeaf2;
          background-color: #fff;
        }
        input::placeholder,
        select::placeholder {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 16px;
          color: #4b4949;
        }
      }
    }
  }
`;
const StyledBorderSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  gap:20px;
  margin-bottom: 1rem;  
  width: 100%;  

  @media (max-width: 768px) {
    flex-wrap: wrap; 
  }
`;

const StyledBorderSelect = styled.select`
  margin-top: 5px;
  width: 100%;  
  padding: 0.5rem 1rem; 
  border-radius: 12px;
  border: 1px solid #dfeaf2;
  font-size: 1rem;  
  background-color: #fff; 
  cursor: pointer;  
  &:focus {
    outline: none; 
    border-color: #4caf50;  
  }
  &.error {
    border-color: #f44336;  
  }
`;

const StyledSwapIcon = styled.i`
  font-size: 1.2rem;  
  margin: 0 1rem; 
  cursor: default;  
`;
 
const StyledDiv = styled.div`
 width: 100%;
`;
 