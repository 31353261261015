import React, { useState } from "react";
import styled from "styled-components";
import {
  BlackBorderButton,
  BlueBackgroundButton,
} from "../Global/GlobalButtons";
import {
  FormTextBlack,
  TableHeader,
  UploadContent,
} from "../Global/GlobalText";
import { BorderInput, BorderTextarea } from "../Global/GlobalPlaceholder";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import GlobalSearch from "../Global/GlobalSearch";
import { ProductDiv } from "./WarehouseManage";
import * as Yup from "yup";
import useAppUtils, { useUserProductFetch } from "../../utils/useAppUtils";
import { CreateCategoryAction } from "../../redux/users/action";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Pagination } from "../Global/Table2";
import CapIcon from "../../Assets/CapIcon.png";
import {
  Container,
  ProfileImage,
  Table,
  TableCell,
  TableRow,
  TableWrapper,
} from "./Product";
import { UPLOAD_IMAGES } from "../../utils/https";
import { NoDataWrapper } from "../Global/GlobalTable";
import { NoDataIcon } from "../group/GroupUserList";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  sku: Yup.number()
    .typeError("SKU must be a number")
    .required("SKU is required"),
  image: Yup.mixed()
    .required("Image is required")
    .test(
      "fileFormat",
      "Only image files (JPEG, PNG,JPG, GIF) are accepted",
      (value) => {
        return (
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
            value.type
          )
        );
      }
    )
    .test(
      "fileSize",
      "File size must be less than or equal to 1 MB",
      (value) => value && value.size <= 1048576
    ),
});

const initialValues = {
  name: "",
  description: "",
  image: null,
  sku: "",
};
export const AddCategory = () => {
  const { dispatch, navigate } = useAppUtils();
  const userProductFetch = useUserProductFetch();
  const [currentPageProduct, setCurrentPageProduct] = useState(1);
  const itemsPerPageProduct = 5;
  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };
  const paginatedProductFetch = getPaginatedData(
    userProductFetch,
    currentPageProduct,
    itemsPerPageProduct
  );
  const handleCreateCategory = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    // Append form values to the FormData object
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("sku", values.sku);
    formData.append("image", values.image);
    dispatch(
      CreateCategoryAction(formData, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          resetForm();
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  return (
    <Root>
      <div className="product_main_div">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreateCategory}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="sku_wrapper">
                <div className="sku">
                  <div className="icon_cap_head">
                    <HiOutlineArrowNarrowLeft />
                    <FormTextBlack>Create Category</FormTextBlack>
                  </div>
                  <Field
                    as={BorderInput}
                    placeholder="Category Name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div className="sku">
                  <div className="icon_cap_head">
                    <>
                      <HiOutlineArrowNarrowLeft />
                      <FormTextBlack>Create SKU</FormTextBlack>
                    </>
                  </div>
                  <>
                    <Field
                      as={BorderInput}
                      placeholder="SKU"
                      name="sku"
                      type="number"
                      value={values.sku}
                      onChange={(e) => setFieldValue("sku", e.target.value)}
                    />
                    <ErrorMessage
                      name="sku"
                      component="div"
                      className="error"
                    />
                  </>
                </div>
              </div>
              <div className="armycap_div">
                <div className="category_div">
                  <FormTextBlack>Category Description</FormTextBlack>
                  <Field
                    as={BorderTextarea}
                    placeholder="Add Category Description Here"
                    name="description"
                    type="text"
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="upload_image">
                  <FormTextBlack>Upload Image</FormTextBlack>
                  <div className="upload_div">
                    <div className="upload_btn">
                      <BlackBorderButton>Upload New</BlackBorderButton>
                      <input
                        type="file"
                        accept="image/*"
                        className="file_input"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0]);
                        }}
                      />
                    </div>
                    <UploadContent>
                      {values.image
                        ? values.image.name
                        : "Add Your Images Here"}
                    </UploadContent>
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div>
                <BlueBackgroundButton type="submit" disabled={isSubmitting}>
                  Save
                </BlueBackgroundButton>
              </div>
            </Form>
          )}
        </Formik>
        <ProductDiv className="product_div">
          <div className="product_btn">
            <FormTextBlack>Product List</FormTextBlack>
            <BlackBorderButton
              onClick={() => {
                navigate("/warehouse_manage/add_product");
              }}
            >
              Add Now
            </BlackBorderButton>
          </div>
          <div className="search_product_div">
            <GlobalSearch search="Search Product" />
          </div>
        </ProductDiv>
      </div>
      <Container>
        {paginatedProductFetch.length > 0 ? (
          <TableWrapper>
            <Table>
              <thead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <TableHeader>Profile Image</TableHeader>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableHeader>Name</TableHeader>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableHeader>ID</TableHeader>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableHeader>Category</TableHeader>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableHeader>Date</TableHeader>{" "}
                  </TableCell>
                </TableRow>
              </thead>
              <tbody>
                {paginatedProductFetch.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ minWidth: "60px" }}>
                      <ProfileImage
                        src={`${UPLOAD_IMAGES}/${item.image || CapIcon}`}
                        alt={item.name}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>

                    <TableCell>{item.product_id}</TableCell>
                    <TableCell>{item.category_name}</TableCell>
                    <TableCell>
                      {new Date(item.create_date).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        ) : (
          <NoDataWrapper>
            <NoDataIcon />
            <h3>No Data Available</h3>
            <p>Please add or fetch data to display here.</p>
          </NoDataWrapper>
        )}
        <Pagination
          currentPage={currentPageProduct}
          totalItems={userProductFetch.length}
          itemsPerPage={itemsPerPageProduct}
          onPageChange={(page) => setCurrentPageProduct(page)}
        />
      </Container>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;
  .sku_wrapper {
    display: flex;
    justify-content: space-between;
  }
  .sku {
    display: flex;
    flex-direction: column;

    .icon_cap_head {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  .product_main_div {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .armycap_div {
      width: 60%;

      .category_div {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .upload_image {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 0px;

        @media (max-width: 567px) {
          width: 100%;
        }

        .upload_div {
          background-color: #f0f0f0;
          padding: 30px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          border-radius: 25px;
          .upload_btn {
            display: flex;
            justify-content: center;
            position: relative;
            .file_input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media (max-width: 567px) {
    .product_main_div {
      flex-direction: column-reverse;
      .armycap_div {
        width: 100%;
      }
    }
  }
`;
