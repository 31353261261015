import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import avatar from "../../Assets/284950b1-c899-44d4-927f-a2859cac3dc8.jpeg";
import { Textlabel } from "../Global/GlobalText";
import { BorderInput } from "../Global/GlobalPlaceholder";

const UserProfile = () => {
  const userDetails = useSelector((state) => state?.users?.user);
  const categories = useSelector((state) => state?.users);
console.log("checking details",categories)
  return (
    <Container>
      <Avatar src={avatar} alt={userDetails.username} />
      <div className="input_select">
        <div className="input_select_name">
          <Textlabel htmlFor="name">Your Name</Textlabel>
          <BorderInput name="name" value={userDetails.username} />
        </div>

        {/* <div className="input_select_group">
          <Textlabel htmlFor="email">E-mail</Textlabel>
          <BorderInput name="email" value={userDetails.email} />
        </div>

        <div className="input_select_desc">
          <Textlabel htmlFor="group">Assign Group</Textlabel>
          <BorderInput name="groupid" value={userDetails.email} />
        </div>

        <div className="input_select_unit">
          <Textlabel htmlFor="unit">Unit</Textlabel>
          <BorderInput name="groupid" value={userDetails.email} />
        </div>
      </div>

      <div className="input_select">
        <div className="input_select_assign">
          <Textlabel htmlFor="user name">User Name</Textlabel>
          <BorderInput name="groupid" value={categories.name} />
        </div>

        <div className="input_select_group">
          <Textlabel htmlFor="number">Mobile Number</Textlabel>
          <BorderInput name="groupid" value={userDetails.email} />
        </div> */}

        <div className="input_select_name">
          <Textlabel htmlFor="role">Role</Textlabel>
          <BorderInput name="role" value={userDetails.role} />
        </div>
      </div>
    </Container>
  );
};

export default UserProfile;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px double lightgray;
  width: 60vw;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Avatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;
