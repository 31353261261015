import React from "react";
import styled from "styled-components";
import { UPLOAD_IMAGES } from "../../utils/https";

const GlobalTable = ({ columns, data, onSelectChange, onRowClick }) => {
  return (
    <RootTable>
      {data.length > 0 ? (
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.accessor === "select" ? (
                      <input
                        type="checkbox"
                        checked={row.selected}
                        onChange={() => onSelectChange(rowIndex)}
                      />
                    ) : column.accessor === "name" ? (
                      <span
                        onClick={() => onRowClick(row)}
                        style={{ cursor: "pointer" }}
                      >
                        {row[column.accessor]}
                      </span>
                    ) : column.accessor === "image" ? (
                      <img
                        src={`${UPLOAD_IMAGES}/${row[column.accessor]}`}
                        alt={row[column.accessor]}
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : column.render ? (
                      column.render(rowIndex)
                    ) : (
                      row[column.accessor]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (<NoDataWrapper>
        <h3>No Data Available</h3>
        <p>Please add or fetch data to display here.</p>
      </NoDataWrapper>)}
    </RootTable>
  );
};

export default GlobalTable;

export const RootTable = styled.section`
  background-color: #ffff;
  border-radius: 20px;
  table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    border: 1px solid ligtgray;
  }
  th {
    color: #718ebf;
  }
  td {
    color: #232323;
  }
  tr {
    border-bottom: 1px solid #e6eff5;
  }
  th,
  td {
    text-align: left;
    padding: 10px 5px;
    text-align: center;
    font-size: 12px;
  }
  img {
    width: 50px;
    height: 35px;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    border-radius: 10px;
    th,
    td {
      padding: 3px 6px;
      min-width: 115px;
    }
  }
`;
export const NoDataWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  color: #718ebf;

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin: 5px 0;
    font-size: 14px;
  }
`;