import React from "react";
import "./waitingunit.css";
import useAppUtils, {
  useWaitingListUnitUserFetch,
} from "../../utils/useAppUtils";
import NoPermission, { ContainerPer, Three } from "./NoPermission";
import { ThreeDots } from "react-loader-spinner";
import {
  setNewUserVerify,
  userCheckAction,
  userDataAction,
} from "../../redux/users/action";
import { FiLogOut } from "react-icons/fi";

function WaitingUserUnit() {
  const waitingintial = useWaitingListUnitUserFetch();
  const { dispatch, navigate } = useAppUtils();
  const handleLogout = () => {
    localStorage.removeItem("lastVisitedPage");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("loginTimestamp");
    navigate("/");
    dispatch(userCheckAction(false));
    dispatch(setNewUserVerify(true));
    dispatch(userDataAction(""));
  };
  return (
    <div className="table-container">
      <h2>Waiting For Unit Approval</h2>
      <table className="data-table">
        <thead>
          <tr>
            <th>No</th>
            <th>Request By</th>
            <th>Unit ID</th>
            <th>Status</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {waitingintial.length > 0 ? (
            waitingintial.map((item, index) => (
              <>
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.requestBy_role}</td>
                  <td>{item.unit_id}</td>
                  <td>{item.status}</td>
                  <td>{new Date(item.create_date).toLocaleString()}</td>
                </tr>
                {item.status === "approved" && (
                  <tr>
                    <td colSpan="5">
                      <NoPermission />
                    </td>
                  </tr>
                )}
                {item.status === "pending" && (
                  <tr>
                    <td colSpan="5">
                      <ContainerPer>
                        <h3>Please Wait...</h3>
                        <Three>
                          <ThreeDots color="#00BFFF" height={50} width={50} />
                        </Three>
                        Please wait until your request is approved.
                      </ContainerPer>
                    </td>
                  </tr>
                )}
              </>
            ))
          ) : (
            <tr>
              <td colSpan="5">No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div
        className="icon_content"
        onClick={() => {
          handleLogout();
        }}
        style={{ cursor: "pointer" }}
      >
        <FiLogOut />
        Logout
      </div>
    </div>
  );
}

export default WaitingUserUnit;
