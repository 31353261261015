import {
  addUser,
  adminLogin,
  adminRegister,
  categoryList,
  createCategory,
  createGroup,
  createProduct,
  createUnit,
  createWarehouse,
  groupList,
  HIDE_LOADER,
  productPut,
  productView,
  SHOW_LOADER,
  unitList,
  userList,
  warehouseList,
  productList,
  LOGOUT_USER,
  warehouseLogin,
  userPermissionView,
  userLogin,
  userProductFetch,
  userSelectedList,
  assignProduct,
  roleView,
  assignProductById,
  requestEquipmentFetch,
  reconcileProduct,
  assignUserToGroup,
  moveUserToGroup,
  moveUserToUnit,
  assignUserToUnit,
  updateMemberStatus,
  updateGroupStatus,
  NewUserRegister,
  RequestToJoinUnitForNewUserPost,
  MoveUserToOtherUnitListPost,
  SET_NEW_USER_VERIFY,
  AllUserProfileByIdGet,
  newStaffLogin,
  TransferApprovalSagaGetType,
  updateTransferStatus,
} from "../type";
import {
  SET_SELECTED_GROUPS_IDS,
  SET_SELECTED_PRODUCT_ID,
  SET_SELECTED_UNIT_ID,
  SET_SELECTED_USER_ID,
} from "./action";

const initialState = {
  userData: null,
  user: {},
  userCheck: false,
  role: "",
  appDetails: {},
  isLoading: false,
  products: [], // Separate state for product lists
  productDetails: {}, // Separate state for a specific product view
  categories: [], // State for categories
  warehouses: [], // State for warehouses
  groups: [], // State for groups
  transferApproval: [], // State for transferApproval
  units: [], 
  users: [],
  productView: [],
  userProductFetch: [],
  isLoggedIn: false,
  selectedProductId: null,
  userproducts: [],
  usersSelect: [],
  selectedUnitId: null,
  assign: [],
  selectedUserId: null,
  selectedUserProfileId: null,
  roleView: [],
  productsById: null,
  reqEqu: [],
  reconcile: [],
  assignUserToGroup:[],
  moveUserToGroup:[],
  assignUserToUnit:[],
  moveUserToUnit:[],
  newUserVerify: true,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_USER_DATA":
      return { ...state, userData: null };
    case SET_NEW_USER_VERIFY:
      return {
        ...state,
        newUserVerify: action.payload,  
      };
    case SET_SELECTED_PRODUCT_ID:
      return {
        ...state,
        selectedProductId: action.payload,
      };
    case LOGOUT_USER:
      return { ...initialState };
    case "USER_DATA":
      return { ...state, user: action.payload };
    case "LOADING_DATA":
      return { ...state, isLoading: action.payload };
    case "USER_CHECK":
      return { ...state, userCheck: action.payload };
    case "APP_DETAILS":
      return { ...state, appDetails: action.payload };
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
    case SET_SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: action.payload,
      };
    case "SET_SELECTED_USER_PROFILE_ID":
      return {
        ...state,
        selectedUserProfileId: action.payload,
      };
    case adminLogin.ADMIN_LOGIN:
      return { ...state, user: action.data };
    case newStaffLogin.NEW_STAFF_LOGIN:
      return { ...state, user: action.data };
    case userLogin.USER_LOGIN:
      return { ...state, user: action.data };
    case warehouseLogin.WAREHOUSE_LOGIN:
      return { ...state, user: action.data };
    case adminRegister.ADMIN_REGISTER:
      return { ...state, user: action.data };
    case addUser.ADD_USER:
      return { ...state, user: action.data };
    case reconcileProduct.RECONCILE_PRODUCT:
      return { ...state, reconcile: action.data };

    case createGroup.CREATE_GROUP:
      return { ...state, groups: [...state.groups, action.data] };

    case createUnit.CREATE_Unit:
      return { ...state, units: [...state.units, action.data] };
    case assignProduct.ASSIGN_PRODUCT:
      return { ...state, assign: action.data };
    case requestEquipmentFetch.REQUEST_EQUIPMENT_FETCH:
      return { ...state, reqEqu: action.data };
    case createWarehouse.CREATE_WAREHOUSE:
      return { ...state, warehouses: [...state.warehouses, action.data] };

    case createCategory.CREATE_CATEGORY:
      return { ...state, categories: [...state.categories, action.data] };
    case createProduct.CREATE_PRODUCT:
      return { ...state, products: [...state.products, action.data] };

    case unitList.UNIT_LIST:
      return { ...state, units: action.data };

    case groupList.GROUP_LIST:
      return { ...state, groups: action.data };
    case TransferApprovalSagaGetType.TRANSFER_APPROVAL_SAGA_GET_TYPE:
      return { ...state, transferApproval: action.data };

    case userList.USER_LIST:
      return { ...state, users: action.data };
    case userSelectedList.USER_SELECTED_LIST:
      return { ...state, usersSelect: action.data };
    case categoryList.CATEGORY_LIST:
      return { ...state, categories: action.data };

    case warehouseList.WAREHOUSE_LIST:
      return { ...state, warehouses: action.data };
    case roleView.ROLE_VIEW:
      return { ...state, roleView: action.data };

    case productPut.PRODUCT_PUT:
      return { ...state, productDetails: action.data }; // Update product details after put

    case productView.PRODUCT_VIEW:
      return { ...state, productDetails: action.data };
    case userProductFetch.USER_PRODUCT_FETCH:
      return { ...state, userProductFetch: action.data };
    case userPermissionView.USER_PERMISSION_VIEW:
      return { ...state, userPermissionView: action.data };

    case productList.PRODUCT_LIST:
      return { ...state, products: action.data };
    case assignUserToGroup.ASSIGN_USER_TO_GROUP:
      return { ...state, assignUserToGroup: action.data };
    case moveUserToGroup.MOVE_USER_TO_GROUP:
      return { ...state, moveUserToGroup: action.data };
    case assignUserToUnit.ASSIGN_USER_TO_UNIT:
      return { ...state, assignUserToUnit: action.data };
    case moveUserToUnit.MOVE_USER_TO_UNIT:
      return { ...state, moveUserToUnit: action.data };
    case updateMemberStatus.UPDATE_MEMBER_STATUS:
      return { ...state, updateMemberStatus: action.data };
    case updateGroupStatus.UPDATE_GROUP_STATUS:
      return { ...state, updateGroupStatus: action.data };
    case updateTransferStatus.UPDATE_TRANSFER_STATUS:
      return { ...state, updateTransferStatus: action.data };
    case assignProductById.ASSIGN_PRODUCT_BY_ID:
      return { ...state, productsById: action.data };
    case SET_SELECTED_UNIT_ID:
      return {
        ...state,
        selectedUnitId: action.payload,
      };
    case SET_SELECTED_GROUPS_IDS:
      return {
        ...state,
        selectedGroupId: action.payload,
      };
      case NewUserRegister.NEW_USER_REGISTER:
        return { ...state, user: action.data };
      case RequestToJoinUnitForNewUserPost.REQUEST_TO_JION_UNIT_FOR_NEW_USER_POST:
        return { ...state, RequestToJoinUnitForNewUserPost: action.data };
      case MoveUserToOtherUnitListPost.MOVE_USER_TO_OTHER_UNIT_LIST_POST:
        return { ...state, MoveUserToOtherUnitListPost: action.data };
      case AllUserProfileByIdGet.ALL_USER_PROFILE_BY_ID_GET:
        return { ...state, AllUserProfileByIdGet: action.data };
    default:
      return state;
  }
};

export default userReducer;
