import React, { useEffect, useState } from "react";
import "./test.css";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL_WARE } from "../../utils/https";

const TreeChart = () => {
  const [chartData, setChartData] = useState([]);
  const [showAll, setShowAll] = useState(false); 
  
  const fetchWarehouseChart = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL_WARE}/warehousechart`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.status === 200) {
        setChartData(res?.data?.data?.data);
        toast.success("Data fetched successfully!");
      } else {
        toast.warn(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      toast.error("No data found");
    }
  };
  useEffect(() => {
    fetchWarehouseChart(); 
  }, []);

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState); // Toggle the showAll state
  };
  return (
    <div className="container">
      <h1 className="level-1 rectangle">Warehouse: {chartData?.warehousename}</h1>
      <h1 className="level-1 rectangle">Unit: {chartData?.unit_name}</h1>
      <ol className="level-2-wrapper">
        {(showAll ? chartData?.group_list : chartData?.group_list?.slice(0, 4))?.map(
          (group, groupIndex) => (
            <li key={groupIndex}>
              <h2 className="level-2 rectangle">{group?.group_name}</h2>
              <ol className="level-3-wrapper">
                {(showAll ? group?.soldiers : group?.soldiers?.slice(0, 10))?.map(
                  (soldier, soldierIndex) => (
                    <li key={soldierIndex}>
                      <h3 className="level-3 rectangle">{soldier?.username}</h3>
                    </li>
                  )
                )}
              </ol>
            </li>
          )
        )}
      </ol>
      <button className="show-more-btn" onClick={toggleShowAll}>
        {showAll ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default TreeChart;
