import React from "react";

const WelcomePage = () => {
  return (
    <div style={{ background: "white", padding: "20px", height: "80vh",textAlign:"center" }}>
      <h1>Welcome to Logstix</h1>
      <hr/>
      <h2>SIMPLE ENGAGING ORGANIZED</h2>
    </div>
  );
};

export default WelcomePage;
