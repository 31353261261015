import React from "react";
import { useSelector } from "react-redux"; // To access the Redux store
import styled from "styled-components";

const Loader = () => {
  const isLoading = useSelector((state) => state.users.isLoading); // Get loading state

  return (
    isLoading && (
      <Root className="loader">
        <div className="spinner"></div>
      </Root>   
    )
  );
};

export default Loader;
const Root = styled.section`
    width: 100vw;
    height: 100vh;
    position: absolute;
   scroll-behavior: block;

    /* .loader { */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: brightness(0.5) blur(10px); /* Apply brightness and blur effects */
  overflow: hidden; 
/* } */


.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

`