import React, { useState } from "react";
// import GlobalUserForm from "../Global/GlobalUserForm";
import useAppUtils, {
  useGroupList,
  useProductList,
  useUnitList,
  useUserList,
} from "../../utils/useAppUtils";
import { BorderInput, BorderSelect } from "../Global/GlobalPlaceholder";
import { ErrorMsg, Textlabel } from "./../Global/GlobalText";
import styled from "styled-components";
import { toast } from "react-toastify";
import { BlueBackgroundButton } from "../Global/GlobalButtons";
import axios from "axios";
import { API_BASE_URL } from "../../utils/https";

export const CreateSubWarehouse = () => {
  const [formValues, setFormValues] = useState({
    username: "",
    description: "",
    userid: "",
    groupid: "",
    unitid: "",
    permission_ids: [],
    uw_product_ids: [],
    wu_product_ids: [],
  });
  const [permissions, setPermissions] = useState({
    64: false,
  });
  const [errors, setErrors] = useState({});
  const { navigate } = useAppUtils();
  const units = useUnitList();
  const groups = useGroupList();
  const users = useUserList();
  const products = useProductList();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};
    if (!formValues.username)
      formErrors.username = "Warehouse Name is required";
    if (!formValues.description)
      formErrors.description = "Description is required";
    if (!formValues.userid) formErrors.userid = "User is required";
    if (!formValues.groupid) formErrors.groupid = "Group is required";
    if (!formValues.unitid) formErrors.unitid = "Unit is required";
    if (formValues.permission_ids.length === 0)
      formErrors.permission_ids = "At least one permission is required";

    console.log("formValues", formValues.permission_ids);
    setErrors(formErrors);

    const selectedIds = Object.keys(permissions).filter(
      (key) => permissions[key]
    );
    const selectedProducts = selectedIds
      .filter((key) => key.includes("_"))
      .map((key) => {
        const numberPart = key.split("_")[1];
        return numberPart && !isNaN(numberPart) ? numberPart : null; // Ensure it's a valid number
      })
      .filter(Boolean);
    console.log("vakusdse", selectedProducts);
    const values = {
      ...formValues,
      permission_ids: selectedIds.map((id) => parseInt(id, 10)), // Ensure the permission keys are integers
      uw_product_ids: selectedProducts,
      wu_product_ids: selectedProducts,
    };
   
    try {
      const axiosConfig = {
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await axios.post(
        `${API_BASE_URL}/warehouse-create`,
        values,
        axiosConfig
      );
      if (response?.status === 200) {
        toast.success("Warehouse created successfully!");
        navigate("/dashboard");
      } else {
        toast.error("Process failed. Please try again.");
      }
    } catch (err) {
      toast.error("An error occurred. Please try again.");
    }
  };

 

  // --------------------------------------permission logic--------------------------------------------
  const togglePermission = (key, value) => {
    const updatedPermissions = { ...permissions, [key]: !permissions[key] };
    setPermissions(updatedPermissions);
    if (value === "uw_product_ids") {
      setFormValues((prev) => ({
        ...prev,
        uw_product_ids: prev.uw_product_ids.includes(key)
          ? prev.uw_product_ids.filter((id) => id !== key)
          : [...prev.uw_product_ids, key],
      }));
    } else if (value === "wu_product_ids") {
      setFormValues((prev) => ({
        ...prev,
        wu_product_ids: prev.wu_product_ids.includes(key)
          ? prev.wu_product_ids.filter((id) => id !== key)
          : [...prev.wu_product_ids, key],
      }));
    }
  };
  const handleParentChange = (key, children) => {
    const newValue = !permissions[key];
    togglePermission(key, newValue);
    toggleChildPermissions(key, children);
  };
  const renderEquipmentList = (parentKey) => (
    <div>
      {products.map((product) => (
        <div key={product.product_id}>
          <input
            type="checkbox"
            checked={permissions[`${parentKey}_${product.product_id}`] || false}
            onChange={() =>
              setPermissions((prev) => ({
                ...prev,
                [`${parentKey}_${product.product_id}`]:
                  !prev[`${parentKey}_${product.product_id}`],
              }))
            }
          />
          <label>{product.name}</label>
        </div>
      ))}
    </div>
  );
  
  const toggleChildPermissions = (key, children) => {
    const updatedPermissions = { ...permissions };
    children.forEach((child) => {
      updatedPermissions[child] = updatedPermissions[key];
    });
    setPermissions(updatedPermissions);
  };

 

  return (
    <StyleProfile>
      <form onSubmit={handleSubmit}>
        <div className="formtext">
          <div className="input_select">
            <div className="input_select_name">
              <Textlabel htmlFor="name">Warehouse Name</Textlabel>

              <input
                as={BorderInput}
                type="text"
                name="username"
                value={formValues.username}
                onChange={handleChange}
                placeholder="XYZwareHouse"
              />
              <ErrorMsg>
                {errors.username && <div>{errors.username}</div>}
              </ErrorMsg>
            </div>
            <div className="input_select_desc">
              <Textlabel htmlFor="description">
                Description about warehouse
              </Textlabel>

              <input
                as={BorderInput}
                type="text"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                placeholder="Description about warehouse"
              />
              <ErrorMsg>
                {errors.description && <div>{errors.description}</div>}
              </ErrorMsg>
            </div>
            <div className="input_select_unit">
              <Textlabel htmlFor="Member from Unit Name">
                Member from Unit Name
              </Textlabel>
              <BorderSelect
                name="unitid"
                value={formValues.unitid}
                onChange={handleChange}
              >
                <option value="">Select Unit</option>
                {units?.map((unit) => (
                  <option key={unit.unit_id} value={unit.unit_id}>
                    {unit.unit_name}
                  </option>
                ))}
              </BorderSelect>
              <ErrorMsg>{errors.unitid && <div>{errors.unitid}</div>}</ErrorMsg>
            </div>
          </div>
          <div className="input_select">
            <div
              className="input_select_assign"
            >
              <Textlabel htmlFor="Assign To">Assign To</Textlabel>

              <BorderSelect
                name="groupid"
                value={formValues.groupid}
                onChange={handleChange}
              >
                <option value="">Select Group</option>
                {groups?.map((group) => {
                  return (
                    <option key={group.group_id} value={group.group_id}>
                      {group.group_name}
                    </option>
                  );
                })}
              </BorderSelect>
              <ErrorMsg>
                {errors.groupid && <div>{errors.groupid}</div>}
              </ErrorMsg>
            </div>
            <div className="input_select_group">
              <Textlabel htmlFor="group name">Member From Group Name</Textlabel>
              <BorderSelect
                name="userid"
                value={formValues.userid}
                onChange={handleChange}
              >
                <option value="">Select Member</option>
                {users?.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  );
                })}
              </BorderSelect>
              <ErrorMsg>{errors.userid && <div>{errors.userid}</div>}</ErrorMsg>
            </div>
          </div>
        </div>
        {/* -------------------------------permission---------------------------------------- */}
        <div>
          <Container>
            <Title>Warehouse Permissions</Title>
            {/* eeeeeeeeeeveeeeeerrrrrrrrrrrrrryyyyytthiingggggggg */}

            <Section>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="100"
                  checked={permissions[100] || false}
                  onChange={() => handleParentChange(100,[21,22,23])}
                />
                <Label>Permission for Everything</Label>
              </CheckboxContainer>
              <SubCheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    type="checkbox"
                    id="21"
                    checked={permissions[21] || false}
                    onChange={() => togglePermission(21, !permissions[21])}
                  />
                  <Label id="21">Change Product Name</Label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    type="checkbox"
                    id="22"
                    checked={permissions[22] || false}
                    onChange={() => togglePermission([22], !permissions[22])}
                  />
                  <Label id="22">Change Category to a product Name</Label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    type="checkbox"
                    id="23"
                    checked={permissions[23] || false}
                    onChange={() => togglePermission(23, !permissions[23])}
                  />
                  <Label id="23">Add Equipment</Label>
                </CheckboxContainer>
              </SubCheckboxContainer>
            </Section>
            {/* ----------------transfer to user-------------- */}
            <Section>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="500"
                  checked={permissions[500] || false}
                  onChange={() => togglePermission(500)}
                />
                <Label>Transfer Equipment from the Warehouse to the User</Label>
              </CheckboxContainer>
              {permissions[500] && (
                <SubCheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="57"
                      checked={permissions[57] || false}
                      onChange={() => togglePermission(57)}
                    />
                    <Label id="57">Any Equipment</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="700"
                      checked={permissions[700] || false}
                      onChange={() => togglePermission(700)}
                    />
                    <Label>Choose between contain/does not contain</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="60"
                      checked={permissions[60] || false}
                      onChange={() => togglePermission(60, "wu_product_ids")}
                      value="wu_product_ids"
                    />
                    <Label id="60">What Type of Equipment</Label>
                  </CheckboxContainer>
                  {permissions[60] && renderEquipmentList(600)}
                </SubCheckboxContainer>
              )}
            </Section>

            {/* ----------------transfer to sub warehouse-------------- */}
            <Section>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="900"
                  checked={permissions[900] || false}
                  onChange={() => togglePermission(900)}
                />
                <Label>
                  Transfer Equipment from Warehouse to Sub Warehouse
                </Label>
              </CheckboxContainer>
              {permissions[900] && (
                <SubCheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="61"
                      checked={permissions[61] || false}
                      onChange={() => togglePermission(61)}
                    />
                    <Label id="61">Any Equipment</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="11"
                      checked={permissions[11] || false}
                      onChange={() => togglePermission(11)}
                    />
                    <Label>Choose between contain/does not contain</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="62"
                      checked={permissions[62] || false}
                      onChange={() => togglePermission(62)}
                    />
                    <Label id="62">What Type of Equipment</Label>
                  </CheckboxContainer>
                  {permissions[62] &&
                    renderEquipmentList(620)}
                </SubCheckboxContainer>
              )}
            </Section>

            {/* ----------------recieve to warehouse-------------- */}
            <Section>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="13000"
                  checked={permissions[130] || false}
                  onChange={() => togglePermission(130)}
                />
                <Label>Receive Equipment from User to Warehouse</Label>
              </CheckboxContainer>
              {permissions[130] && (
                <SubCheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="63"
                      checked={permissions[63] || false}
                      onChange={() => togglePermission(63)}
                    />
                    <Label id="63">Any Equipment</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="15000"
                      checked={permissions[150] || false}
                      onChange={() => togglePermission(150)}
                    />
                    <Label>Choose between contain/does not contain</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="64"
                      checked={permissions[64] || false}
                      onChange={() => togglePermission(64, "uw_product_ids")}
                      value="uw_product_ids"
                    />
                    <Label id="64">What Type of Equipment</Label>
                  </CheckboxContainer>
                  <EquipmentList>
                    {permissions[64] && renderEquipmentList(640)}
                  </EquipmentList>
                </SubCheckboxContainer>
              )}
            </Section>
            {/* ----------------delete product-------------- */}

            <Section>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="170"
                  checked={permissions[170] || false}
                  onChange={() => togglePermission(170)}
                />
                <Label>Delete a Product</Label>
              </CheckboxContainer>
              {permissions[170] && (
                <SubCheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="65"
                      checked={permissions[65] || false}
                      onChange={() => togglePermission(65)}
                    />
                    <Label id="65">With Transer History</Label>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      type="checkbox"
                      id="66"
                      checked={permissions[66] || false}
                      onChange={() => togglePermission(66)}
                    />
                    <Label id="66">Without Transer Historyn</Label>
                  </CheckboxContainer>
                </SubCheckboxContainer>
              )}
            </Section>

            {/* ------------------last section-------------------- */}
            <Section>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="67"
                  checked={permissions[67] || false}
                  onChange={() => togglePermission(67)}
                />
                <Label id="67">Add users to the Warehouse</Label>
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="68"
                  checked={permissions[68] || false}
                  onChange={() => togglePermission(68)}
                />
                <Label id="68">Remove users from the Warehouse</Label>
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="69"
                  checked={permissions[69] || false}
                  onChange={() => {
                    togglePermission(69);
                  }}
                />
                <Label id="69">
                  change permissions for the users in the Warehouse
                </Label>
              </CheckboxContainer>
            </Section>
          </Container>
        </div>
        <hr />
        <div className="save_btn">
          <BlueBackgroundButton type="submit">save</BlueBackgroundButton>
        </div>
      </form>
    </StyleProfile>
  );
};


export const StyleProfile = styled.section`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 40px;
  flex-wrap: wrap;
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .formtext {
      display: flex;
      justify-content: space-evenly;
    }
  }
  .profile_edit {
    display: flex;
    position: relative;
    align-items: baseline;
    img {
      width: 120px;
      height: 120px;
    }
    .edit_icon {
      background-color: transparent;
      border: none;
    }
    svg {
      cursor: pointer;
      background-color: #1814f3;
      color: #fff;
      width: 35px;
      height: 35px;
      padding: 7px;
      position: absolute;
      top: 30%;
      left: 72px;
      border-radius: 50%;
    }
  }
  .input_select {
    display: flex;
    flex-direction: column;
    flex: 1;
    .input_select_sub_div,
    .input_select_name,
    .input_select_desc,
    .input_select_unit,
    .input_select_assign,
    .input_select_group {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      label {
        padding: 0px 10px 10px;
        font-size: 14px;
      }
      input,
      select {
        border: 1.5px solid #dfeaf2;
        background-color: #fff;
      }
      input::placeholder,
      select::placeholder {
        font-size: 13px !important;
        font-weight: 400;
        line-height: 16px;
        color: #4b4949;
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const Section = styled.div`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SubCheckboxContainer = styled.div`
  padding-left: 20px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Label = styled.label`
  font-size: 16px;
`;

const EquipmentList = styled.div`
  margin-top: 10px;
  padding-left: 20px;
`;

