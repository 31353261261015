import React, { useEffect, useState } from "react";
import {
  BlueBackgroundButton,
  BlueBorderButton,
} from "../Global/GlobalButtons";
import {
  FormTextBlack,
  Heading,
  HistoryContent,
  HistorySubContent,
} from "../Global/GlobalText";
import { Modal, Button } from "react-bootstrap";
import {
  BorderInput,
  BorderSelect,
  BorderTextarea,
} from "../Global/GlobalPlaceholder";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import imagelogo from "../../Assets/PhotoIcon.png";
import circleimage from "../../Assets/circleimage.png";
import note from "../../Assets/note.png";
import useAppUtils, {
  useCategoryList,
  useProductView,
  useUserProductFetch,
  useUserSelectedList,
} from "../../utils/useAppUtils";
import { ProductRoot } from "./AddProducts";
import { UPLOAD_IMAGES } from "../../utils/https";
import { toast } from "react-toastify";
import {
  getProductViewAction,
  putProductAction,
  setSelectedProductId,
} from "../../redux/users/action";
import { useForm, Controller } from "react-hook-form"; // Import from react-hook-form
import { TableDiv } from "./Product";
import GlobalTable from "../Global/GlobalTable";
import { Pagination } from "../Global/Table2";
import { useLocation } from "react-router-dom";

export const ViewProduct = () => {
  const [showInput, setShowInput] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const product = useProductView();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { dispatch, navigate } = useAppUtils();
  const [selectedProductIds, setSelectedProductIds] = useState({});
  const userProductFetch = useUserProductFetch();
  const [currentPageProduct, setCurrentPageProduct] = useState(1);
  const itemsPerPageProduct = 5;
  const categories = useCategoryList();
  const [showAssign, setShowAssign] = useState(false);
  const usersSelect = useUserSelectedList();
  const [selectedRole, setSelectedRole] = useState("Soldier");
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const itemsPerPageUser = 5;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const product_id = queryParams.get("product_id");
  console.log("Product ID from query:", product_id);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: product?.name || "",
      categoryid: product?.categoryid || "",
    },
  });

  useEffect(() => {
    if (product) {
      setValue("name", product.name);
      setValue("categoryid", product.categoryid);
    }
  }, [product, setValue]);

  const handleImageClick = () => {
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const handleModalShow = (modalType) => {
    setActiveModal(modalType);
  };

  const handleModalClose = () => {
    setActiveModal(null);
  };
  const columns = [
    { header: "Select", accessor: "select" },
    { header: "SL No ", accessor: "slno" },
    { header: "Date & Time", accessor: "create_date" },
    { header: "Image", accessor: "image" },
    { header: "Equipment Name", accessor: "name" },
    { header: "Quantity", accessor: "quantity" },
    { header: "Equipment Id", accessor: "product_id" },
    { header: "Location", accessor: "location" },
    { header: "Assigned To", accessor: "username" },
    { header: "Category ", accessor: "category_name" },
    { header: "Equipment Reconciliation", accessor: "equipmentReconciliation" },
    { header: "Equipment Assign", accessor: "equipmentAssign" },
  ];
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("categoryid", values.categoryid);

    dispatch(
      putProductAction(formData, (response) => {
        if (response?.status === 200) {
          setEditMode(false);
          toast.success("Product updated successfully!");
          navigate("/warehouse_manage/product");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  const handleShowAssign = () => {
    if (selectedProductIds.length === 0) {
      toast.warn("Please select a product first."); // Show warning if no product selected
      return;
    }
    setShowAssign(true); // Show modal if products are selected
  };

  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };
  // const paginatedUsersSelect = getPaginatedData(usersSelect);
  const paginatedProductFetch = getPaginatedData(
    userProductFetch,
    currentPageProduct,
    itemsPerPageProduct
  );
  const filteredPaginatedUsersSelect = getPaginatedData(
    usersSelect.filter((item) => item.role === selectedRole),
    currentPageUser,
    itemsPerPageUser
  );

  const handleSelectChange = (rowIndex, itemType) => {
    if (itemType === "product") {
      const productId = userProductFetch[rowIndex].product_id;
      setSelectedProductIds((prev) => {
        const newSelection = prev.includes(productId)
          ? prev.filter((id) => id !== productId)
          : [...prev, productId]; 
        return newSelection;
      });
    } else if (itemType === "user") {
      if (
        filteredPaginatedUsersSelect &&
        rowIndex < filteredPaginatedUsersSelect.length
      ) {
        const user = filteredPaginatedUsersSelect[rowIndex];
        setSelectedUsers([{ user_id: user.user_id, username: user.username }]);
      } else {
        console.error(
          "Invalid user index or paginated users data not available"
        );
      }
    }
  };
  const handleNavigateToProduct = (product_id, fetchedId) => {
    dispatch(setSelectedProductId(product_id || fetchedId));
    const callback = (response) => {
      const fetchedId = response?.data?.data?.product_id;
      navigate(`/warehouse_manage/viewproduct/${fetchedId || product_id}`);
    };
    dispatch(getProductViewAction({}, callback, product_id || fetchedId));
  };
  return (
    <ProductRoot>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="save_btn">
          {!editMode ? (
            <BlueBackgroundButton
              type="button"
              onClick={() => setEditMode(true)}
            >
              Update
            </BlueBackgroundButton>
          ) : (
            <BlueBackgroundButton type="submit" disabled={false}>
              Save
            </BlueBackgroundButton>
          )}
        </div>

        <div className="product_main_div">
          <div className="armycap_div">
            <div className="cap_div">
              <div className="icon_cap_head">
                <HiOutlineArrowNarrowLeft />
                <FormTextBlack>Product Name</FormTextBlack>
              </div>

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <BorderInput
                    {...field}
                    placeholder="Product Name"
                    type="text"
                    readOnly={!editMode}
                  />
                )}
              />
              {errors.name && (
                <div className="error">{errors.name.message}</div>
              )}

              <div className="category_div">
                <FormTextBlack>Category Description</FormTextBlack>
                <BorderTextarea placeholder={product.description} readOnly />
              </div>

              <div className="upload_image">
                <FormTextBlack>Uploaded Image</FormTextBlack>
                <div className="upload_div">
                  {product.image && (
                    <img
                      src={`${UPLOAD_IMAGES}${product.image}`}
                      alt="Preview"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="armycap_div"
            style={{ textAlign: "end", margin: "auto" }}
          >
            <div
              className="cap_div"
              style={{ width: "200px", justifyContent: "center" }}
            >
              <BlueBackgroundButton
                type="button"
                onClick={() => handleModalShow("move")}
              >
                Move a product
              </BlueBackgroundButton>
              <BlueBackgroundButton
                type="button"
                onClick={() => handleModalShow("request")}
              >
                Request a product
              </BlueBackgroundButton>
              <BlueBackgroundButton
                type="button"
                onClick={() => handleModalShow("assign")}
              >
                Assign Product
              </BlueBackgroundButton>
              <BlueBackgroundButton
                type="button"
                onClick={() => handleModalShow("reconcile")}
              >
                Reconcile Product
              </BlueBackgroundButton>
            </div>
          </div>
        </div>

        <div className="category_main_div">
          <div className="cat_sub_div">
            <FormTextBlack>Category</FormTextBlack>
            <Controller
              name="categoryid"
              control={control}
              render={({ field }) => (
                <BorderSelect {...field} disabled={!editMode}>
                  <option value={product.category_name}>
                    {product.category_name}
                  </option>
                  {categories?.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </BorderSelect>
              )}
            />
            {errors.categoryid && (
              <div className="error">{errors.categoryid.message}</div>
            )}
          </div>

          <div className="cat_sub_div">
            <FormTextBlack>Product No.</FormTextBlack>
            <BorderInput
              placeholder={product.army_cap}
              type="number"
              readOnly
            />
          </div>

          <div className="cat_sub_div">
            <FormTextBlack>SKU(Automatic)</FormTextBlack>
            <BorderInput placeholder={product.sku} type="number" readOnly />
          </div>

          <div className="cat_sub_div">
            <FormTextBlack>Warehouse</FormTextBlack>
            <BorderSelect disabled>
              <option>{product?.warehouse_name}</option>
            </BorderSelect>
          </div>
        </div>

        <div className="comments_div">
          <FormTextBlack>Comments</FormTextBlack>
          <BorderTextarea placeholder={product?.comments} readOnly />
        </div>

        <div className="product_history">
          <FormTextBlack>Product History</FormTextBlack>
          <div className="history_div">
            <div className="line_div">
              <img src={circleimage} alt="circleimage" />
            </div>
            <div className="cont_div">
              <img src={imagelogo} alt="imagelogo" />
              <div className="img_cont_div">
                <HistoryContent>Cap Assign to ABC Soldier</HistoryContent>
                <HistorySubContent>
                  Assign By ABC (warehouse no 8)
                </HistorySubContent>
              </div>
            </div>
            <div className="date_time_div">
              <HistoryContent>12-8-24</HistoryContent>
              <HistoryContent>10:00 AM</HistoryContent>
            </div>
            <div className="note_div">
              <img src={note} alt="note_img" onClick={handleImageClick} />
              {showInput && <BorderInput />}
            </div>
          </div>
        </div>
      </form>
      <div className="product_history">
        <FormTextBlack>Product History</FormTextBlack>
        <div className="history_div">
          <div className="line_div">
            <img src={circleimage} alt="circleimage" />
          </div>
          <div className="cont_div">
            <img src={imagelogo} alt="imagelogo" />
            <div className="img_cont_div">
              <HistoryContent>Cap Assign to ABC Soldier</HistoryContent>
              <HistorySubContent>
                Assign By ABC (warehouse no 8)
              </HistorySubContent>
            </div>
          </div>

          <div className="date_time_div">
            <HistoryContent>12-8-24</HistoryContent>
            <HistoryContent>10:00 AM</HistoryContent>
          </div>

          <div className="note_div">
            <img src={note} alt="note_img" onClick={handleImageClick} />
            {showInput && <BorderInput />}
          </div>
        </div>
      </div>

      <Modal show={activeModal === "move"} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Move Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for moving a product.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={activeModal === "request"}
        onHide={handleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for requesting a product.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={activeModal === "assign"} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Assign Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <TableDiv>
            <div className="dash_table">
              <GlobalTable
                columns={columns}
                data={
                  paginatedProductFetch
                    ? paginatedProductFetch?.map((row, index) => ({
                        ...row,
                        slno: index + 1, // Adding SL No
                        create_date: new Date(row.create_date).toLocaleString, //Format the date here
                        // images: (
                        //   <img
                        //   src={row?.image ? `${UPLOAD_IMAGES}/${row.image}` : CapIcon}
                        //     alt="Product "
                        //     style={{
                        //       width: "50px",
                        //       height: "50px",
                        //       objectFit: "cover",
                        //     }}
                        //   />
                        // ),
                        location: (
                          <button className="location_btn">Inside</button>
                        ),
                        quantity: <p>0</p>,
                        equipmentAssign: (
                          <BlueBorderButton
                            variant="primary"
                            onClick={handleShowAssign}
                            id="2 || 15"
                          >
                            Request Now{row.equipmentAssign}
                          </BlueBorderButton>
                        ),
                      }))
                    : []
                }
                onSelectChange={(rowIndex) =>
                  handleSelectChange(rowIndex, "product")
                }
                onRowClick={(row) => handleNavigateToProduct(row.product_id)}
              />
              <Pagination
                currentPage={currentPageProduct}
                totalItems={userProductFetch.length}
                itemsPerPage={itemsPerPageProduct}
                onPageChange={(page) => setCurrentPageProduct(page)}
              />
            </div>
          </TableDiv>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={activeModal === "reconcile"}
        onHide={handleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reconcile Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for reconciling a product.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary">Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </ProductRoot>
  );
};
