import React, { useState } from "react";
import styled from "styled-components";
import GlobalTable from "../Global/GlobalTable";
import { BlackBorderButton } from "../Global/GlobalButtons";
import { Pagination } from "../Global/Table2";


const columns = [
  { header: "Select", accessor: "select" },
  { header: "User Name", accessor: "userName" },
  { header: "Group Name", accessor: "groupName" },
  { header: "Unit Name", accessor: "unitName" },
  { header: "Request Sent ", accessor: "request" },
];
const InvitedList = () => {
  const [data, setData] = useState([
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Manager",
      request: "accepted",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Soldier",
      request: "Pending",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Soldier",
      request: "accepted",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Area In Charge",
      request: "Pending",
    },
    {
      selected: false,
      userName: "Test2",
      groupName: "Test2",
      unitName: "Sub Manager",
      request: "accepted",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  
  const totalItems = data.length;
  const paginatedUnits = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <StyleInvitedList>
      <div className="dash_table">
      <GlobalTable
          columns={columns}
          data={paginatedUnits.map((row, index) => ({
            ...row,
            request: (
              <BlackBorderButton
                onClick={() => alert(`Request is ${row.request}`)}
              >
                {row.request === "accepted" ? "Accepted" : "Pending"}
              </BlackBorderButton>
            ),
          }))}
        />
         <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                  />
      </div>
    </StyleInvitedList>
  );
};

export default InvitedList;
const StyleInvitedList = styled.section`
background-color: #fff;
padding: 20px;
border-radius: 20px;
`;
