import React, { useState } from "react";
import styled from "styled-components";
import { BorderSelect } from "../Global/GlobalPlaceholder";
import useAppUtils, { useTransferApproval } from "../../utils/useAppUtils";
import { UpdateTransferStatusAction } from "../../redux/users/action";
import { toast } from "react-toastify";
import GlobalTable from "../Global/GlobalTable";
import { Pagination } from "../Global/Table2";

const TransferApproval = () => {
  const dataget = useTransferApproval();
  const [dataTwo, setDataTwo] = useState([]);
  const { dispatch, navigate } = useAppUtils();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const columnsTwo = [
    { header: "Select", accessor: "select" },
    { header: "S.No", accessor: "slNo" },
    { header: "Date & Time", accessor: "datetime" },
    { header: "Equipment name", accessor: "productname" },
    { header: "Assigned By", accessor: "assigned_by_username" },
    { header: "Assigned To", accessor: "assigned_to_username" },
    { header: "Status", accessor: "status" },
  ];
  const handleStatusChange = (e, index) => {
    console.log("dataTwo before change:", dataTwo);
    const updatedStatus = e.target.value;
    if (dataTwo && dataTwo[index]) {
      const updatedData = [...dataTwo];
      updatedData[index].status = updatedStatus;
      setDataTwo(updatedData);
      const id = updatedData[index].slNo;
      const status = updatedData[index].status;
      UpdateGroupStatus(id, status);
    } else {
      console.error("Item not found in dataTwo at index:", index);
    }
  };
  const UpdateGroupStatus = (id, status) => {
    dispatch(
      UpdateTransferStatusAction({ id, status }, (response) => {
        if (response?.status === 200) {
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };
  const onSelectChange = (index) => {
    const newDataTwo = [...dataTwo];
    newDataTwo[index].selected = !newDataTwo[index].selected;
    setDataTwo(newDataTwo);
  };
  const uniqueData = dataget.reduce((acc, currentValue) => {
    // Check if the current thirdparty_id already exists in the accumulator
    const isDuplicate = acc.some(
      (item) => item.thirdparty_id === currentValue.thirdparty_id
    );

    // If it's not a duplicate, add it to the accumulator
    if (!isDuplicate) {
      acc.push(currentValue);
    }

    return acc;
  }, []);
  const paginatedData = dataget.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <Root>
      <div className="table">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <>
                {paginatedData?.length ? (
                  <div className="table_div">
                    <GlobalTable
                      columns={columnsTwo}
                      data={paginatedData.map((item, index) => ({
                        slNo: item.thirdparty_id,
                        datetime: new Date(item.create_date).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        ),
                        productname: item.productname || "N/A",
                        position: item.requestBy_role,
                        assigned_by_username:
                          item.assigned_by_username || "Unknown User",
                        assigned_to_username:
                          item.assigned_to_username || "Unknown Group",
                        status: (
                          <BorderSelect
                            onChange={(e) => handleStatusChange(e, index)}
                            value={item.status}
                          >
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approve</option>
                            <option value="Declined">Decline</option>
                          </BorderSelect>
                        ),

                        selected: false,
                      }))}
                      onSelectChange={onSelectChange}
                    />
                  </div>
                ) : (
                  <p className="no-data">No data available for this tab.</p>
                )}
              </>
              <Pagination
                currentPage={currentPage}
                totalItems={dataget.length}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default TransferApproval;
const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;

  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .button_div {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .search_main_div {
    padding: 20px 50px;
  }

  .table_div {
    margin-top: 30px;
    .table {
      width: 100%;
      height: 100%;
      margin-top: 20px;
    }
  }
`;
